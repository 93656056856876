import React from 'react'

import Media from '../../components/Media'
import Desktop from './Desktop'
import Mobile from './Mobile'

import { TagProps } from './types'

type BlogTagTopicsProps = {
  tags: TagProps[]
}

const BlogTagTopics = ({ tags }: BlogTagTopicsProps) => {
  const title = 'Topics covered:'

  return (
    <Media
      desktop={() => <Desktop tags={tags} title={title} />}
      tablet={() => <Desktop tags={tags} title={title} />}
      mobile={() => <Mobile tags={tags} title={title} />}
    />
  )
}

export default BlogTagTopics
