export default function errorOnTimeout<T>(
  p: Promise<T | void>
): Promise<T | void> {
  return Promise.race([p, getTimeout()])
}

function getTimeout(): Promise<void> {
  const timeoutMs = 600

  return new Promise((_, reject) => {
    let id = setTimeout(() => {
      clearTimeout(id)
      reject(`Analytics timed out after ${timeoutMs} ms`)
    }, timeoutMs)
  })
}
