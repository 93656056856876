import React, { CSSProperties } from 'react'
import { MenuListProps, components } from 'react-select'
import { IOption } from '../../../types'
import { useVirtualizer } from '@tanstack/react-virtual'

export const menuListCSS = ({
  maxHeight,
}: MenuListProps<IOption, false>): CSSProperties => ({
  maxHeight,
  WebkitOverflowScrolling: 'touch',
  paddingTop: 0,
  paddingBottom: 15,
  position: 'absolute',
  height: '100%',
  width: '100%',
  overflowY: 'auto',
})

export const MenuList = (props: MenuListProps<IOption, false>) => {
  const { children } = props

  const options = children as JSX.Element[]

  return options.length ? <ResultList {...props} /> : <EmptyList {...props} />
}

function EmptyList(props: MenuListProps<IOption, false>) {
  const { children, innerRef } = props

  return (
    <div style={menuListCSS(props)} ref={innerRef}>
      {children}
    </div>
  )
}

function ResultList(props: MenuListProps<IOption, false>) {
  const options = props.children as JSX.Element[]

  const parentRef = React.useRef<null | HTMLDivElement>(null)

  const rowVirtualizer = useVirtualizer({
    count: options.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 36, // Largest possible size of an element
  })

  const virtualItems = rowVirtualizer.getVirtualItems()
  const totalSize = rowVirtualizer.getTotalSize()

  return (
    <>
      <components.MenuList {...props} innerRef={parentRef}>
        <div
          style={{
            height: `${totalSize}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
            }}
          >
            {virtualItems.map((virtualRow) => (
              <div key={virtualRow.key} ref={rowVirtualizer.measureElement}>
                {options[virtualRow.index]}
              </div>
            ))}
          </div>
        </div>
      </components.MenuList>
    </>
  )
}

export default MenuList
