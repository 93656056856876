import React, { FC, useState } from "react"
import { isEmpty } from "lodash-es";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { CheckboxGroupProps } from "./types";
import Checkbox from "../Checkbox";
import { OptionType } from "../RadioGroup/types";
import withErrorBoundary from "../../util/components/ErrorBoundary/withErrorBoundary";

const CheckboxGroup: FC<CheckboxGroupProps> = ({ id, options, value = [], direction = "vertical", align = "start", onChange, checkboxProps }) => {


  const handleChange = (isChecked: boolean, opt: OptionType) => {
    let finalizedOpts = [...value];
    if (isChecked) finalizedOpts.push(opt);
    else finalizedOpts = finalizedOpts.filter(o => o.value !== opt.value);
    onChange && onChange([...finalizedOpts]);
  }


  return (
    <div data-test-id={'checkbox-group-wrap'} className={clsx(styles.checkboxGroupWrap, styles[direction], styles[align])}>
      {options.map((option, index) => (
        <Checkbox
          key={id + index}
          {...checkboxProps}
          {...option}
          onChange={(e) => handleChange(e.target.checked, option)}
          checked={!isEmpty((value).find(a => a.value === option.value))}
        />
      ))}
    </div>
  )
}

export default withErrorBoundary(CheckboxGroup, {component: 'CheckboxGroup'});