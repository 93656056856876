import React, { ReactNode } from 'react'
import Selection from './Selection'
import Description from './Description'

import styles from './styles.module.scss'

export type SelectionOption = {
  link: string
  title: string
  text: string
}

export type SelectionModuleProps = {
  heading: ReactNode
  description: string
  buttonText: string
  options: SelectionOption[]
}

const SelectionModule = ({
  heading,
  description,
  buttonText,
  options,
}: SelectionModuleProps) => {
  return (
    <div className={styles.wrap}>
      {heading}
      <Description>{description}</Description>
      <Selection button={buttonText} lists={options} />
    </div>
  )
}

export default SelectionModule
