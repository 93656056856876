import React from 'react'
import SidebarSection from '../SidebarSection'
import styles from './styles.module.scss'
import { Link } from './index'
import LinkWrapper from '../LinkWrapper'

type ExploreOnInsureonProps = {
  title: string
  links: Link[]
  testId?: string
}

const ExploreOnInsureon = ({
  title,
  links,
  testId = 'ExploreOnInsureon',
}: ExploreOnInsureonProps) => {
  const linkEls = links.map(({ icon, linkText, linkHref }: Link) => (
    <div className={styles.exploreInsureon} key={linkText}>
      {React.createElement(icon.type, {
        ...icon.props,
        className: styles.image,
        imageParams: { h: 16, w: 16 },
      })}
      <LinkWrapper text={linkText} href={linkHref} className={styles.link} />
    </div>
  ))

  return (
    <SidebarSection testId={testId} title={title}>
      <div className={styles.exploreInsureonContainer}>{linkEls}</div>
    </SidebarSection>
  )
}

export default React.memo(ExploreOnInsureon)
