import { toLower } from "lodash-es";
import { dataMap } from "..";
import { PolicyRecommendationCTAProps } from "../types";

export const usePolicyRecommendationCTA = (props: PolicyRecommendationCTAProps) => {
  const { isVisible } = props
  const { visbleOnPage } = dataMap(props);
  const visiblePageTypes = visbleOnPage.map(i => toLower(i?.displayName));

  return ({
    isVisible: isVisible || visiblePageTypes.includes(toLower(props.sitecoreContext.route.templateName))
  })
}