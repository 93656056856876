import {
  RouteData,
  SitecoreContextValue,
} from '@sitecore-jss/sitecore-jss-react'
import { ThemeEnum } from '../../context/Theme'

export enum NavigationType {
  ResponsiveNav = 'ResponsiveNav',
  DesktopNav = 'DesktopNav',
  BlogNav = 'BlogNav',
}

export const NavViewportSize = {
  xs: 480,
  xsMax: 599,
  sm: 767,
  smMax: 1024,
}

export type Link = {
  href: string
  text: string
  title?: string
} | null

export type ImageLink = {
  src: string
  href: string
  title?: string
  alt?: string
} | null

type ImageResponse = {
  src: string
  alt: string
  width?: string | number
  height?: string | number
}

export type LogoResponse = {
  img: ImageResponse
  mobileImg: ImageResponse
  href: string
  title?: string
} | null

export type NavigationItem = Link & {
  icon: string
  iconAlt: string
  isSummaryLink: boolean
  mobileOnly: boolean
  links: NavigationItem[]
}

export type NavigationMenu = {
  logo?: LogoResponse
  startQuote: Link
  login: Link
  links: NavigationItem[]
} | null

export type Navigation = {
  navigationType: NavigationType
  menu: NavigationMenu
}

export type NavigationSettings = {
  pageEditing: boolean
  navigationType?: NavigationType
  logo: {
    img: null | JSX.Element
    mobileImg: null | JSX.Element
    href: string
    title?: string
  }
  menu: NavigationMenu
  theme?: string
  login: boolean
  isIE11: boolean
  images?: object
  quoteBtn?: boolean
  // for q221
  pageTheme: ThemeEnum
  isPolicyFinderPage?: boolean
}

export type LegacyNavFields = RouteData & {
  fields: { navResources: { fields: any } }
  templateName: string
}

export type NavigationSitecoreContextType = SitecoreContextValue & {
  pageEditing: boolean
  navigation: Navigation
  route: LegacyNavFields
}
