import React from 'react'
import NavButtons from './NavButtons'
import styles from './styles.module.scss'

import { CarouselProvider, Slider, Slide } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { ICarouselRouterItems } from '../types'

const VISIBLE_SLIDES = 5
const SLIDE_WIDTH = 115
const SLIDE_HEIGHT = 76

export default function DesktopCarousel({
  routerItems,
}: ICarouselRouterItems) {
  return (
    <CarouselProvider
      naturalSlideWidth={SLIDE_WIDTH}
      naturalSlideHeight={SLIDE_HEIGHT}
      totalSlides={getTotalSlides(routerItems.length)}
      visibleSlides={VISIBLE_SLIDES}
      step={VISIBLE_SLIDES}
      isIntrinsicHeight={true}
      className={styles.carousel}
    >
      <NavButtons />
      <Slider className={styles.slider}>
        {routerItems.map((routingItem, i) => (
          <Slide index={i} key={i} className={styles.slide}>
            {routingItem}
          </Slide>
        ))}
      </Slider>
    </CarouselProvider>
  )
}

function getTotalSlides(numberOfSlides: number) {
  if (numberOfSlides % 5 === 0)
  {
    return numberOfSlides
  }
  
  return numberOfSlides + (VISIBLE_SLIDES - (numberOfSlides % VISIBLE_SLIDES))
}
