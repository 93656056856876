import { RichText, Text } from '@sitecore-jss/sitecore-jss-react'
import { AuthorProfileProps } from '.'
import Media from '../../../components/Media'
import { FC } from '../../../types/common'
import styles from './styles.module.scss'
import { BlogAuthorTitles } from '../../../constants/blog-author'
import { isEmpty } from 'lodash-es'
import clsx from 'clsx'

const BioInfoSection: FC<AuthorProfileProps> = ({ author }) => {
  const {
    fields: {
      education,
      experience,
      summary,
      name,
      summarySectionHeading,
      experienceSectionHeading,
      educationSectionHeading,
    },
  } = author

  return (
    <div className={styles.authorBioInfoSection}>
      <div className={styles.authorBioContentSection}>
        <Media
          tablet={() => <h2 className={styles.authorName}>{name?.value}</h2>}
          mobile={() => <></>}
        />
        {!isEmpty(summary?.value) && (
          <>
            <h3 className={styles.authorBioSectionTitle}>
              <Text
                field={{
                  ...summarySectionHeading,
                  value:
                    summarySectionHeading?.value || BlogAuthorTitles.SUMMARY,
                }}
              />
            </h3>
            <div>
              <RichText field={summary} />
            </div>
          </>
        )}
      </div>
      {!isEmpty(experience?.value) && (
        <div className={styles.authorBioContentSection}>
          <h3 className={styles.authorBioSectionTitle}>
            <Text
              field={{
                ...experienceSectionHeading,
                value:
                  experienceSectionHeading?.value ||
                  BlogAuthorTitles.EXPERIENCE,
              }}
            />
          </h3>
          <div>
            <RichText field={experience} />
          </div>
        </div>
      )}
      {!isEmpty(education?.value) && (
        <div
          className={clsx(styles.authorBioContentSection, 'educationSection')}
        >
          <h3 className={styles.authorBioSectionTitle}>
            <Text
              field={{
                ...educationSectionHeading,
                value:
                  educationSectionHeading?.value || BlogAuthorTitles.EDUCATION,
              }}
            />
          </h3>
          <div>
            <RichText field={education} />
          </div>
        </div>
      )}
    </div>
  )
}

export default BioInfoSection
