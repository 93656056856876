import React from 'react'

import styles from './styles.module.scss'
import { RouteFields, TextField } from '../../types/SitecoreAdapter'
import {
  ComponentConsumerProps,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'
import clsx from 'clsx'
import LinkWrapper, { LinkWrapperWithChildItems } from '../LinkWrapper'
import Media from '../Media'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type DisplayType = {
  variant?: string
  isBgDark?: boolean
}

type StyleOverrides = {
  styleOverrides?: {
    ctaAttrNumber?: string
  }
}

export type LeadRoutingNumberProps = ComponentConsumerProps & {
  sitecoreContext: {
    route: {
      fields: {
        leadRoutingAttributes: {
          fields: {
            leadRoutingNumber: TextField
          }
        }
      }
    }
  }
  onClick?: () => void
  className?: string
  testId?: string
} & DisplayType &
  StyleOverrides

function getAttributionText(variant: string) {
  switch (variant) {
    case 'appStart':
      return (
        <Media
          mobile={() => (
            <span>
              Have a question? <br /> Give us a call:{' '}
            </span>
          )}
          tablet={() => <span>Have a question? Give us a call: </span>}
        />
      )
    case 'search':
      return (
        <span>
          Can't find what you're looking for? <br /> Give us a call:{' '}
        </span>
      )
    default:
      return ''
  }
}

const LeadRoutingNumber = ({
  sitecoreContext: {
    route: {
      fields: {
        leadRoutingAttributes: {
          fields: { leadRoutingNumber },
        },
      },
    },
  },
  className,
  variant,
  isBgDark,
  onClick,
  styleOverrides,
  testId = 'LeadRoutingNumberAttribution',
}: LeadRoutingNumberProps) => {
  if (!leadRoutingNumber || leadRoutingNumber.value === '') {
    return null
  }

  const cleanNumberString = leadRoutingNumber.value.replace(/[^a-zA-Z0-9]/g, '')

  const dataAttrs = {
    'data-tag': 'LeadRoutingNumberAttribution',
    'data-test-id': testId,
  }

  if (!variant) {
    return (
      <LinkWrapper
        additionalProps={{ ...dataAttrs }}
        text={leadRoutingNumber.value}
        href={`tel:+1${cleanNumberString}`}
        className={className}
      />
    )
  }

  return (
    <>
      {variant !== 'cta' ? (
        <div
          data-test-id={testId}
          className={clsx(styles.attribution, className)}
        >
          {getAttributionText(variant)}
          <LinkWrapper
            className={styles.attr_number}
            text={leadRoutingNumber.value}
            href={`tel:+1${cleanNumberString}`}
          />
        </div>
      ) : (
        <LinkWrapperWithChildItems
          additionalProps={{ ...dataAttrs }}
          className={clsx(
            styles.ctaAttr,
            !isBgDark && styles.darkText,
            className
          )}
          href={`tel:+1${cleanNumberString}`}
          onClick={onClick}
        >
          Or call us at{' '}
          <span
            className={clsx(
              styles.ctaAttr_number,
              styleOverrides && styleOverrides.ctaAttrNumber,
              !isBgDark && styles.darkText
            )}
          >
            {leadRoutingNumber.value}
          </span>
        </LinkWrapperWithChildItems>
      )}
    </>
  )
}

export default withErrorBoundary(withSitecoreContext()(LeadRoutingNumber), {
  component: 'Lead-Routing-Number-Attribution',
})
