import React, { ReactNode, useRef } from 'react'
import styles from './styles.module.scss'
import { useCardClick } from '../../Policy-Card/useCardClick'
import { withClassName } from '../../../util/withClassName'

type PolicyCardProps = {
  icon: React.ReactElement
  linkHref: string
  linkTitle: string
  heading: React.ReactElement
  body: React.ReactElement
  bestFor: React.ReactElement
  children: ReactNode
}

const PolicyCard = ({
  icon,
  linkHref,
  linkTitle,
  heading,
  body,
  bestFor,
  children,
}: PolicyCardProps) => {
  const anchorEl = useRef<HTMLAnchorElement>(null)
  const [onCardMouseDown, onCardMouseUp, onAnchorMouseDown] = useCardClick(
    anchorEl
  )

  return (
    <div
      data-test-id={'Policy-Card-Quote'}
      className={styles.card}
      onMouseDown={onCardMouseDown}
      onMouseUp={onCardMouseUp}
    >
      <a
        ref={anchorEl}
        onMouseDown={onAnchorMouseDown}
        href={linkHref}
        title={linkTitle}
        className={styles.head}
      >
        {withClassName(icon, styles.image)}
        {heading}
      </a>
      {withClassName(body, styles.body)}
      <div className={styles.bestForWrap}>
        <div className={styles.bestForHead}>BEST FOR </div>
        {withClassName(bestFor, styles.bestFor)}
      </div>
      <div className={styles.buttonWrap}>{children}</div>
    </div>
  )
}

export default React.memo(PolicyCard)
