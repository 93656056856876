import React, { ReactNode } from 'react'
import { Column } from '../types'
import { addRowHeadings } from './addRowHeadings'
import SpacingContainer from '../SpacingContainer'
import styles from './styles.module.scss'
import clsx from 'clsx'

type DesktopTableProps = {
  rowHeadings: ReactNode[]
  columns: Column[]
  cornerLabel?: ReactNode
  boldRowHeadings?: boolean
  largerFirstColumn?: boolean
  numberOfColumns: number
}

export const DesktopTable = ({
  rowHeadings,
  columns,
  cornerLabel = null,
  boldRowHeadings = true,
  largerFirstColumn = false,
  numberOfColumns,
}: DesktopTableProps) => {
  return (
    <SpacingContainer>
      <table
        className={clsx(
          styles.table,
          largerFirstColumn && styles.largerFirstColumn,
          (numberOfColumns === 1 && styles.firstColumnOverride)
        )}
      >
        <thead>
          <tr>
            <th>{cornerLabel}</th>
            {getColumnTitles(columns)}
          </tr>
        </thead>
        <tbody>{addRowHeadings(boldRowHeadings, rowHeadings, columns)}</tbody>
      </table>
    </SpacingContainer>
  )
}

function getColumnTitles(columns: Column[]) {
  return columns.map((column, index) => <th key={index}>{column.title}</th>)
}

export default DesktopTable
