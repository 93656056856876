import React from 'react'
import styles from './styles.module.scss'
import { TagData } from '../types'
import { TextField } from '../../../types/SitecoreAdapter'
import LinkWrapper from '../../LinkWrapper'

type TagProps = {
  tag: TagData
  pathPrefix: TextField
}

function Tag({tag, pathPrefix }: TagProps) {
  if (!tag.fields.name.value) return null

  return (tag.url) ? (
      <div className={styles.tag}>
        <LinkWrapper text={tag.fields.name.value} href={`/${pathPrefix.value}tag/${tag.name}`} />
      </div>
    ) : (<span className={styles.tag}>{tag.fields.name.value}</span>)
}

export default Tag
