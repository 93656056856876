import React, { Suspense } from 'react'
import loadable from '@loadable/component'
import Nav from '../../page-components/Navigation'
import Banner from '../../components/Banner'
import Heading from '../../components/Heading'
import BreadcrumbsFromRoute from '../../page-components/Breadcrumb/BreadcrumbsFromRoute'
import BreadcrumbList from '../../page-components/Breadcrumb/BreadcrumbList'
import { BooleanField, Item, TextField } from '../../types/SitecoreAdapter'
import PlaceholderFactory from '../../page-components/PlaceholderFactory'
import styles from './styles.module.scss'

const TestMetadata = loadable(() => import('../../components/Test-Metadata'))
const LegalDescriptor = loadable(
  () => import('../../components/LegalDescriptor')
)

type LegalPageProps = {
  route: Item<{
    legalDescriptor: TextField
    heading: TextField
    subhead: TextField
    breadcrumbs?: any
    tpWidgetGlobalSettings: {
      fields: {
        enableTrustPilotHeroWidget: BooleanField
      }
    }
  }>
}

export default function ({ route }: LegalPageProps) {
  const body = <PlaceholderFactory name="Legal-Placeholder" />
  const h1Tag = { fields: { tag: { value: 'h1' } } }

  const heading = (
    <Heading
      testId={'LegalPage-Heading'}
      fields={{
        title: route.fields.heading,
        mobileTitle: route.fields.heading,
        tag: h1Tag,
        descriptor: route.fields.subhead,
      }}
    />
  )

  return (
    <>
      <Nav />
      <Banner />
      <Suspense>
        <div className={'copy'}>
          <TestMetadata pageType="Legal" appStartType="Typeahead" />
          <BreadcrumbsFromRoute>
            {(breadcrumbs) => (
              <BreadcrumbList
                showWrap={true}
                breadcrumbs={breadcrumbs}
                isSticky={true}
                className={styles.breadcrumb}
                paddedLeft
                showCurrentPageCrumb={false}
              />
            )}
          </BreadcrumbsFromRoute>
          {heading}
          <LegalDescriptor />
          {body}
        </div>
      </Suspense>
    </>
  )
}
