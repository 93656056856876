import clsx from 'clsx'
import React from 'react'
import styles from './styles.module.scss'
import { TP_AdditionalProps } from '../../TrustPilotWidget'

// TP Widget Name: Horizontal TrustBox Widget
const LineWidget = React.forwardRef(
  (props: TP_AdditionalProps, ref: React.Ref<HTMLDivElement>) => {
    return (
      <div className={'TrustPilotLineWidget'} data-test-id={'footer-tp-widget'}>
        <div className={clsx(styles.widgetContainer, props.externalStyles)} id="widgetContainer">
          <div
            id={'trustBox'}
            ref={ref}
            className="trustpilot-widget"
            data-locale="en-US"
            data-template-id="5406e65db0d04a09e042d5fc"
            data-businessunit-id="545aa5c800006400057b5df7"
            data-style-height="28px"
            data-style-width="100%"
            data-theme="light"
          ></div>
        </div>
      </div>
    )
  }
)

export default LineWidget
