import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { Item, RouteFields } from '../../types/SitecoreAdapter'
import SearchAppStartContext from '../../quotes/experiences/search-app-start/context'
import SearchAppStart from '../../quotes/experiences/search-app-start/embeds/cta'
import BlogBottomCTA from './BlogBottomCTA'
import styles from './styles.module.scss'

type BlogBottomCTAAdapterProps = RouteFields<{ bottomCta: any }>

const BlogBottomCTAAdapter = ({
  sitecoreContext: {
    route: {
      fields: { bottomCta },
    },
  },
}: BlogBottomCTAAdapterProps) => {
  if (!bottomCta) return null

  return (
    <BlogBottomCTA {...bottomCta.fields}>
      <SearchAppStartContext>
        <SearchAppStart appStartKey="BlogBottomCTA" rootClass={styles.ctaRoot} />
      </SearchAppStartContext>
    </BlogBottomCTA>
  )
}

export default withSitecoreContext()(BlogBottomCTAAdapter)
