import {
  AdditionalLookupType,
  POLICY,
  PolicyLookupItemType,
  QUESTION,
  QUESTION_RESPONSES,
} from './types'
import { policyInfo } from './policy-info'

//==================================
// Recommneded Policy Lookup Data
//==================================

export const policyLookup: PolicyLookupItemType[] = [
  /************************************
   * Question 1
   *************************************/
  {
    que: QUESTION.Q1,
    options: [
      {
        option: QUESTION_RESPONSES.Q1_OPT1,
        policy: POLICY.GENERAL,
        whyRec: ['You own a business'],
        bestFor: policyInfo[POLICY.GENERAL].bestFor,
      },
      {
        option: QUESTION_RESPONSES.Q1_OPT2,
        policy: POLICY.GENERAL,
        whyRec: ['You own a business'],
        bestFor: policyInfo[POLICY.GENERAL].bestFor,
      },
      {
        option: QUESTION_RESPONSES.Q1_OPT3,
        policy: POLICY.GENERAL,
        whyRec: [
          'You have a contract and/or landlord that requires insurance',
          'You own a business',
        ],
        bestFor: policyInfo[POLICY.GENERAL].bestFor,
      },
    ],
  },
  /************************************
   * Question 2
   *************************************/
  {
    que: QUESTION.Q2,
    options: [
      {
        option: QUESTION_RESPONSES.Q2_OPT1,
        policy: POLICY.WORKERS_COMPENSATION,
        whyRec: ['You have employees'],
        bestFor: policyInfo[POLICY.WORKERS_COMPENSATION].bestFor,
      },
      {
        option: QUESTION_RESPONSES.Q2_OPT2,
        policy: POLICY.GENERAL,
        whyRec: ['You own a business'],
        bestFor: policyInfo[POLICY.GENERAL].bestFor,
      },
    ],
  },
  /************************************
   * Question 3
   *************************************/
  {
    que: QUESTION.Q3,
    options: [
      {
        option: QUESTION_RESPONSES.Q3_OPT1,
        policy: POLICY.BOP,
        whyRec: ['You own business property'],
        bestFor: policyInfo[POLICY.BOP].bestFor,
      },
      {
        option: QUESTION_RESPONSES.Q3_OPT2,
        policy: POLICY.COMMERCIAL_AUTO,
        whyRec: ['You own a vehicle(s) used for your business'],
        bestFor: policyInfo[POLICY.COMMERCIAL_AUTO].bestFor,
      },
      {
        option: QUESTION_RESPONSES.Q3_OPT3,
        policy: POLICY.BOP,
        whyRec: ['You have business equipment'],
        bestFor: policyInfo[POLICY.BOP].bestFor,
      },
      {
        option: QUESTION_RESPONSES.Q3_OPT4,
        policy: POLICY.BOP,
        whyRec: ['You have equipment/tools used for your business '],
        bestFor: policyInfo[POLICY.BOP].bestFor,
      },
      {
        option: QUESTION_RESPONSES.Q3_OPT5,
        policy: POLICY.PROFESSIONAL,
        whyRec: ['You provide advice or sell services'],
        bestFor: policyInfo[POLICY.PROFESSIONAL].bestFor,
      },
      {
        option: QUESTION_RESPONSES.Q3_OPT6,
        policy: POLICY.CYBER,
        whyRec: ['You take credit cards or store sensitive data'],
        bestFor: policyInfo[POLICY.CYBER].bestFor,
      },
      {
        option: QUESTION_RESPONSES.Q3_OPT7,
        policy: POLICY.GENERAL,
        whyRec: ['You own a business'],
        bestFor: policyInfo[POLICY.GENERAL].bestFor,
      },
    ],
  },
]

//==================================
// Additional Policy Lookup Data
//==================================

export const defaultAdditionalPolicies = [
  POLICY.COMMERCIAL_UMBRELLA,
  POLICY.PRODUCT,
]

export const additionalPolicyLookup: AdditionalLookupType[] = [
  {
    additional: [
      POLICY.WORKERS_COMPENSATION,
      POLICY.COMMERCIAL_AUTO,
      POLICY.PROFESSIONAL,
    ],
    recommended: [
      [POLICY.GENERAL],
      [POLICY.GENERAL, POLICY.CYBER],
      [POLICY.BOP],
      [POLICY.BOP, POLICY.CYBER],
    ],
  },
  {
    additional: [
      POLICY.COMMERCIAL_AUTO,
      POLICY.CYBER,
      POLICY.COMMERCIAL_UMBRELLA,
    ],
    recommended: [
      [POLICY.GENERAL, POLICY.WORKERS_COMPENSATION],
      [POLICY.GENERAL, POLICY.WORKERS_COMPENSATION, POLICY.PROFESSIONAL],
      [POLICY.BOP, POLICY.WORKERS_COMPENSATION],
      [POLICY.BOP, POLICY.WORKERS_COMPENSATION, POLICY.PROFESSIONAL],
    ],
  },
  {
    additional: [
      POLICY.WORKERS_COMPENSATION,
      POLICY.COMMERCIAL_AUTO,
      POLICY.CYBER,
    ],
    recommended: [
      [POLICY.GENERAL, POLICY.PROFESSIONAL],
      [POLICY.BOP, POLICY.PROFESSIONAL],
      // [POLICY.GENERAL, POLICY.PROFESSIONAL, POLICY.BOP],
    ],
  },
  {
    additional: [
      POLICY.WORKERS_COMPENSATION,
      POLICY.PROFESSIONAL,
      POLICY.CYBER,
    ],
    recommended: [
      [POLICY.GENERAL, POLICY.COMMERCIAL_AUTO],
      [POLICY.BOP, POLICY.COMMERCIAL_AUTO],
    ],
  },
  {
    additional: [
      POLICY.WORKERS_COMPENSATION,
      POLICY.PROFESSIONAL,
      POLICY.COMMERCIAL_UMBRELLA,
    ],
    recommended: [
      [POLICY.GENERAL, POLICY.COMMERCIAL_AUTO, POLICY.CYBER],
      [POLICY.BOP, POLICY.COMMERCIAL_AUTO, POLICY.CYBER],
    ],
  },
  {
    additional: [POLICY.COMMERCIAL_UMBRELLA, POLICY.PRODUCT],
    recommended: [
      [
        POLICY.GENERAL,
        POLICY.WORKERS_COMPENSATION,
        POLICY.PROFESSIONAL,
        POLICY.COMMERCIAL_AUTO,
        POLICY.CYBER,
      ],
      [
        POLICY.BOP,
        POLICY.WORKERS_COMPENSATION,
        POLICY.PROFESSIONAL,
        POLICY.COMMERCIAL_AUTO,
        POLICY.CYBER,
      ],
    ],
  },
  {
    additional: [POLICY.PROFESSIONAL, POLICY.CYBER, POLICY.COMMERCIAL_UMBRELLA],
    recommended: [
      [POLICY.BOP, POLICY.WORKERS_COMPENSATION, POLICY.COMMERCIAL_AUTO],
    ],
  },
]
