import { LabeledEvent } from '../analytics/events'
import { IOption } from '../types'

export enum Status {
  BrowserError,
  Inert,
  Searching,
  AppStart,
}

export type AnalyticsEvent = {
  type: LabeledEvent
  label: string
}

export type AnalyticsEventProp = {
  analyticsEvent?: AnalyticsEvent
}

// if cookie check fails, this
export type State_BrowserError = {
  status: Status.BrowserError
} & AnalyticsEventProp

export type State_Inert = {
  status: Status.Inert
  selection: IOption | undefined
} & AnalyticsEventProp

export type State_Searching = {
  status: Status.Searching
  selection?: IOption
  direct?: boolean
} & AnalyticsEventProp

export type State_AppStart = {
  status: Status.AppStart
  direct: boolean
  selection: IOption
} & AnalyticsEventProp

export type AppSearch_States =
  | State_BrowserError
  | State_Inert
  | State_Searching
  | State_AppStart

export const initialState: State_Inert = {
  status: Status.Inert,
  selection: undefined,
}
