import React from 'react'
import MegaMenu from './MegaMenu'

type CommonMenuState = {
  showMenu: boolean
  activeSection: null | React.ReactElement
}

type ChildHandlers = {
  showSubMenu?: boolean
  onToggleMenu: () => void
  onShowSection: (section: React.ReactElement) => void
  onCloseMenu: (force?: boolean) => void
}

export type CommonMenuBaseProps = {
  children: (props: CommonMenuState & ChildHandlers) => React.ReactNode
}

/**
 * Responsive navigation bar for tablet and mobile devices
 */
class CommonMenuBase extends React.PureComponent<
  CommonMenuBaseProps,
  CommonMenuState
> {
  state = {
    showMenu: false,
    activeSection: null,
  }

  closeSubMenuTimeout: NodeJS.Timeout | undefined = undefined

  closeSubMenu(force = false) {
    this.closeSubMenuTimeout && clearTimeout(this.closeSubMenuTimeout)

    return new Promise<void>(resolve => {
      if (force) {
        this.setState(
          {
            activeSection: null,
          },
          () => resolve()
        )
      } else {
        this.closeSubMenuTimeout = setTimeout(() => {
          this.setState(
            state => ({
              activeSection: state.showMenu ? state.activeSection : null,
            }),
            () => resolve()
          )
        }, 500)
      }
    })
  }

  handleToggleMenu = () => {
    this.setState(state => ({
      showMenu: !state.showMenu,
    }))

    return this.closeSubMenu()
  }

  handleCloseMenu = (force = false) => {
    this.setState(state => ({
      showMenu: false,
    }))

    return this.closeSubMenu(force)
  }

  handleShowSection = (section: React.ReactElement) => {
    this.setState({
      activeSection: section,
    })
  }

  render() {
    return this.props.children({
      ...this.state,
      onToggleMenu: this.handleToggleMenu,
      onShowSection: this.handleShowSection,
      onCloseMenu: this.handleCloseMenu,
    })
  }
}

export default CommonMenuBase
export { MegaMenu }
