import React from 'react'
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react'
import PullQuote from './PullQuote'
import { TextField } from '../../types/SitecoreAdapter'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type PullQuoteAdapterProps = {
  fields: {
    body: TextField
    attribution: TextField
  }
}

const PullQuoteAdapter = ({ fields }: PullQuoteAdapterProps) => {
  const bodyText = <RichText field={fields.body} />
  const attributionText = fields.attribution && fields.attribution.value && (
    <Text field={fields.attribution} />
  )
  return <PullQuote body={bodyText} attribution={attributionText} />
}

export default withErrorBoundary(PullQuoteAdapter, { component: 'Pull-Quote' })
