import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { isServer } from './getEnv'
/**
 * Usage:
 * This hook adds an intersection observer to a component
 * - Be careful as `entry` changes and causes re-renders
 * - Any side effects caused by an intersection obeserver entry change should be in a `useEffect`
 * 
 * Example:
 ```   
const [ref, entry, disconnectObserver, supported] = useIntersect({
  threshold: 0,
  rootMargin: '50px'
});

if (!supported) {
  // fallback
}

useEffect(() => {
  if (entry.isIntersectin) {
    // do stuff
  }
})

<div ref={ref}></div>
```

 * For `root`, `threshold` and `rootMargin`
 * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 * 
 * Returns:
 * [ref, entry, disconnectObserver, supported]
 * 
 * ref:
 * React ref, uses the callback pattern internally
 * 
 * `entry: IntersectionObserverEntry`
 * An interesection "event", if not supported an empty object will be returned
 * https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserverEntry
 * 
 * `disconnectObserver: () => void`
 * Method that disconnects the observer manually.
 * 
 * `supported: boolean`
 * False when intersection observer is not supported
 * 
 * Legacy support
 * When a client doesn't support intersection observer `supported` returns false
 * and `entry` is an empty object.
 */

type IntersectionArgs = {
  root?: Element | null
  rootMargin?: string
  threshold: number | number[]
}

type UseIntersect = [
  (instance: HTMLDivElement | null) => void,
  IntersectionObserverEntry,
  () => void,
  boolean
]

type SetNodeState = [Element | null, Dispatch<SetStateAction<null>>]

export type SetNodeCallback = (instance: HTMLDivElement | null) => void

const noOp = () => {}

export default ({
  root = null,
  rootMargin,
  threshold = 0,
}: IntersectionArgs): UseIntersect => {
  const [entry, updateEntry] = useState({} as IntersectionObserverEntry)
  const [node, setNode]: SetNodeState = useState(null)

  // Legacy/SSR fallback
  if (isServer || !window.IntersectionObserver) {
    return [noOp, {} as IntersectionObserverEntry, noOp, false]
  }

  const observer = useRef(
    new window.IntersectionObserver(([entry]) => updateEntry(entry), {
      root,
      rootMargin,
      threshold,
    })
  )

  useEffect(() => {
    const { current: currentObserver } = observer
    currentObserver.disconnect()

    if (node && node !== null) currentObserver.observe(node)

    return () => currentObserver.disconnect()
  }, [node])

  const disconnectObserver = () => {
    const { current: currentObserver } = observer
    currentObserver.disconnect()
  }

  return [setNode as SetNodeCallback, entry, disconnectObserver, true]
}
