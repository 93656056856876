import React from 'react'
import { ImageField, TextField } from '../../types/SitecoreAdapter'
import { Image, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react'

import SpecialListBase from './base'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type SpecialListProps = {
  fields: {
    icon: ImageField
    title: TextField
  }
  rendering?: any
}

const SpecialList = (props: SpecialListProps) => {
  return (
    <SpecialListBase
      icon={<Image field={props.fields.icon} />}
      title={<Text field={props.fields.title} />}
    >
      <Placeholder name="icon-section-body" rendering={props.rendering} />
    </SpecialListBase>
  )
}

export default withErrorBoundary(SpecialList, { component: 'Special-List' })
