export interface RouteBrandCode {
  fields: {
    brandCode: {
      value: string
    }
  }
}

export default function getBrandCode(route: RouteBrandCode) {
  const code = route.fields.brandCode.value
  if (code === '') {
    throw new Error('Brand Code should not be empty')
  }
  return code
}
