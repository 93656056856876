import * as React from 'react'
import * as CMS from '../../types/SitecoreAdapter'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import Helmet from 'react-helmet'

const OgImage = ({ children }: { children: string }) => {
  return (
    <Helmet>
      <meta property="og:image" content={children} />
    </Helmet>
  )
}

const TwitterImage = ({ children }: { children: string }) => {
  return (
    <Helmet>
      <meta name="twitter:image" content={children} />
    </Helmet>
  )
}

const TwitterImageAlt = ({ children }: { children: string }) => {
  return (
    <Helmet>
      <meta name="twitter:image:alt" content={children} />
    </Helmet>
  )
}

type SocialImageFields = {
  socialImage: CMS.ImageField
  ogImage: CMS.ImageField
  twitterImage: CMS.ImageField
  pageBase: null | { socialImage: CMS.ImageField }
}

type SocialImageAdapterProps = CMS.RouteFields<SocialImageFields>

// I'm seeing some suspicious logic in here around default image src and false
// but leaving for now as out of scope
// Would like to see this broken down further
export const SocialImageAdapter = ({
  sitecoreContext: {
    route: { fields },
  },
}: SocialImageAdapterProps) => {
  const socialImg = getSocialImage(fields)

  const defaultSocialImg =
    fields.socialImage &&
    fields.socialImage.value &&
    fields.socialImage.value.src

  const getOgImageSrc = () =>
    (fields.ogImage.value && fields.ogImage.value.src) || defaultSocialImg

  const getTwitterImageSrc = () =>
    (fields.twitterImage.value && fields.twitterImage.value.src) ||
    defaultSocialImg

  const twitterImageAlt = socialImg && socialImg.alt && (
    <TwitterImageAlt>{socialImg.alt}</TwitterImageAlt>
  )

  return (
    <>
      <OgImage>{getOgImageSrc()}</OgImage>
      <TwitterImage>{getTwitterImageSrc()}</TwitterImage>
      {twitterImageAlt}
    </>
  )
}

// social image can be either directly on the route, or in pageBase
function getSocialImage(fields: SocialImageFields) {
  const socialOnRoute = () =>
    fields.socialImage && fields.socialImage.value && fields.socialImage.value

  const socialOnBase = () =>
    fields.pageBase &&
    fields.pageBase.socialImage &&
    fields.pageBase.socialImage.value

  return socialOnRoute() || socialOnBase()
}

export const SocialImageWired = withSitecoreContext()(SocialImageAdapter)
