import * as React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { take } from 'lodash-es'
import Media from '../Media'
import { LinkWrapperWithChildItems } from '../LinkWrapper'
import ImageWrapper from '../ImageWrapper'
import { addQueryParamsToUrl } from "../../util/imageUrlUtil"

type IndustryRoutingItemProps = {
  label: React.ReactNode
  href: string
  title?: string
  imageProps?: {
    alt: string
    src: string
    width: number | string
    height: number | string
  }
}

export function IndustryRoutingItem({
  label,
  href,
  title,
  imageProps,
}: IndustryRoutingItemProps) {

  return (
    <LinkWrapperWithChildItems
      className={styles.item}
      href={href}
      title={title}
    >
      {imageProps && (
        <div className={styles.icon}>
          <ImageWrapper
            testId={'IndustryItem-Image'}
            src={addQueryParamsToUrl(imageProps.src, `mh=90`)}
            alt={imageProps.alt}
            height={imageProps.height}
            width={imageProps.width}
          />
        </div>
      )}
      <div className={styles.label}>{label}</div>
    </LinkWrapperWithChildItems>
  )
}

type IndustryRoutingProps = {
  heading?: React.ReactNode
  items: React.ReactElement[]
  foot?: React.ReactNode
}

export default function IndustryRouting({
  heading,
  items,
  foot,
}: IndustryRoutingProps) {
  return (
    <div className="IndustryRouting">
      {heading && (
        <h2 className={clsx('h2', 'brick', styles.heading)}>{heading}</h2>
      )}
      <div className={styles.items}>
        <Media mobile={() => take(items, 10)} tablet={() => items} />
      </div>
      {foot && <div className={styles.foot}>{foot}</div>}
    </div>
  )
}
