import * as React from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'

type SpecialListProps = {
  icon: React.ReactElement
  title: React.ReactElement
  children?: any
}

const SpecialList = ({ icon, title, children }: SpecialListProps) => {
  return (
    <div
      className={clsx(
        spaceStyles.contain,
        'block',
        styles.wrap,
        'SpecialList'
      )}
    >
      <div className={styles.imageContainer}>
        {icon}
      </div>
      <div className={styles.textContainer}>
        {title}
        {children}
      </div>
    </div>
  )
}

export default SpecialList
