import React, { useState, useContext } from 'react'
import clsx from 'clsx'

import { moduleClassNames } from '../css-utils'

import MenuOption from '../MenuOption'
import MegaMenu from './MegaMenu'
import QuoteButton from './QuoteButton'
import { NavigationContext } from '../services/context'

import { SearchNavButton } from '../../SiteSearch'

import { LinkWrapperWithChildItems } from '../../../components/LinkWrapper'

import baseStyles from '../styles.module.scss'
import styles from './styles.module.scss'

function DesktopMenu() {
  const [activeSection, setActiveSection] = useState(null)
  const [menuTimeout, setMenuTimeout] = useState()

  const handleOpenMenu = section => {
    return () => {
      if (activeSection === null) {
        clearTimeout(menuTimeout)

        setMenuTimeout(
          setTimeout(() => {
            setActiveSection(section)
          }, 250)
        )
      } else {
        setActiveSection(section)
      }
    }
  }

  const handleCloseMenu = () => {
    clearTimeout(menuTimeout)
    setActiveSection(null)
  }

  const { logo, theme, login, menu, isIE11, pageEditing, quoteBtn } = useContext(
    NavigationContext
  )

  const topLinks = menu.links.map((section, index) => {
    // If menu property 'mobileOnly' exists and is true, don't show in the desktop menu.
    if (section.mobileOnly) {
      return null
    }

    const isOpen = activeSection === section
    const hasChildren = section.links && section.links.length > 0

    const megaMenu = !pageEditing && hasChildren && (
      <MegaMenu
        isOpen={isOpen}
        section={section}
        theme={theme}
        closeHandler={handleCloseMenu}
      />
    )

    return (
      <div
        key={index}
        className={clsx(styles.tab)}
        onMouseEnter={handleOpenMenu(section)}
      >
        <MenuOption
          label={section.text}
          href={section.href}
          title={section.title}
          internal={section.internal}
          theme={['topnav', theme]}
          isOpen={isOpen}
          hasChildren={section.links && hasChildren > 0}
        />
        {megaMenu}
      </div>
    )
  })

  return (
    <>
      <nav
        className={clsx(
          styles.nav,
          moduleClassNames(theme, styles),
          isIE11 && 'ie11'
        )}
        onMouseLeave={handleCloseMenu}
        data-test-context="Nav"
      >
        <div className={styles.navbar}>
          <div className={styles.left}>
            <LinkWrapperWithChildItems
              image={logo.image}
              title={logo.title}
              imageStyles={baseStyles.logo}
              href={logo.href}
              className={baseStyles.logoLink}
            >
              <div className={baseStyles.logo}>
                {logo.img}
              </div>
            </LinkWrapperWithChildItems>
            {topLinks}
          </div>
          <div className={styles.right}>
            {login && (
              <MenuOption
                label={menu.login.text}
                title={menu.login.title}
                href={menu.login.href}
                theme="login"
                noFollow
              />
            )}
            <SearchNavButton />
            {quoteBtn && <QuoteButton />}
          </div>
        </div>
      </nav>
    </>
  )
}

export default DesktopMenu
