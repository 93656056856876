import React from 'react'
import useTrackingApi from './services/useTrackingApi'
import Wrapper from './Wrapper'
import YoutubeIframe from './YoutubeIframe'
import Placeholder from './Placeholder'

export type VideoProps = {
  id: string
  embedUrl: string
  lazyLoad: boolean
}

const getIframe = (id: string, src: string, lazyLoad: boolean) => {
  return <YoutubeIframe id={`ion-yt-${id}`} {...{ src, lazyLoad }} />
}

const Video = ({ id, embedUrl, lazyLoad }: VideoProps) => {
  if (!id || !embedUrl) {
    return null
  }

  useTrackingApi()

  return (
    <Wrapper lazyLoad={lazyLoad}>
      {(showVideo: boolean) =>
        showVideo ? getIframe(id, embedUrl, lazyLoad) : <Placeholder />
      }
    </Wrapper>
  )
}

export default React.memo(Video)
