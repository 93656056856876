import React, { ReactNode } from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'

export type WidthOption = 'sm' | 'md' | 'video' | 'md-separator'

type MultiColumnProps = {
  left: ReactNode
  right: ReactNode
  useCssColumns?: boolean
  centerLeftColumnContent?: boolean
  centerRightColumnContent?: boolean
  block?: boolean
  className?: string
  width?: WidthOption
  mergeColumnsOnMobile?: boolean
  testId?: string
}

const MultiColumn = ({
  left,
  right,
  useCssColumns = false,
  centerLeftColumnContent = false,
  centerRightColumnContent = false,
  block = false,
  className,
  width = 'sm',
  mergeColumnsOnMobile = false,
  testId = 'MultiColumnWrap',
}: MultiColumnProps) => {
  return (
    <div
      data-test-id={testId}
      className={clsx(
        styles.wrap,
        width === 'sm' && spaceStyles.contain,
        block && 'block',
        styles[width],
        'MultiColumn',
        mergeColumnsOnMobile && 'MergedColumns',
        className
      )}
    >
      {useCssColumns ? (
        <div className={styles.cssCol}>{left}</div>
      ) : (
        <>
          <div
            className={clsx(
              styles.col,
              styles.colFirst,
              centerLeftColumnContent && styles.centered
            )}
          >
            {left}
          </div>
          <div
            className={clsx(
              styles.col,
              styles.colSecond,
              styles.bottomCol,
              centerRightColumnContent && styles.centered,
              'MultiColumnBottom'
            )}
          >
            {right}
          </div>
        </>
      )}
    </div>
  )
}

export default MultiColumn
