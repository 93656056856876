import React from 'react'

import styles from './styles.module.scss'

function NoTag() {
  return (
    <div className={styles.noTag}>
      <span className={styles.noTagText}>Sorry, no tags match this search</span>
    </div>
  )
}

export default NoTag