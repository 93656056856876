import * as React from 'react'
import * as CMS from '../../types/SitecoreAdapter'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import Helmet from 'react-helmet'

export const TwitterTitle = ({children}: {children: string}) => {
  return <Helmet><meta name="twitter:title" content={children} /></Helmet>
}

type TwitterTitleAdapterProps = CMS.RouteFields<{
  twittertitle: CMS.TextField,
  pageTitle: CMS.TextField
}>

export const TwitterTitleAdapter = ({
  sitecoreContext: {
    route: {
      fields: {
        twittertitle,
        pageTitle
      }
    }
  }
} : TwitterTitleAdapterProps) => {
  const title = twittertitle.value ? twittertitle.value : pageTitle.value
  return <TwitterTitle>{title}</TwitterTitle>
}

export const TwitterTitleWired = withSitecoreContext()(TwitterTitleAdapter)