import React, { FC, useContext } from "react";
import { PolicyRecommendationContext } from "./context";
import { isEmpty } from "lodash-es";
import RecommendedPolicies from "./components/RecommendedPolicies";
import styles from "./styles.module.scss"
import { PolicyFinderPageProps } from "./types";
import { PolicyRecommendationQuestionnaire } from "../../page-components/PolicyRecommendation/components/Questionnaire";

interface Props {
  route: PolicyFinderPageProps['route']
}

const GetRecommendation: FC<Props> = ({ route }) => {

  const { showResults } = useContext(PolicyRecommendationContext)

  return (
    <>
      {showResults
        ? <RecommendedPolicies />
        : <div className={styles.questionnaireWrap}>
          {!isEmpty(route.fields.policyRecommendationPageSettings) && <PolicyRecommendationQuestionnaire />}
        </div>
      }
    </>
  );
}

export default GetRecommendation;