import React from 'react'
import { Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { LinkWrapperWithChildItems } from '../../components/LinkWrapper'
import Media from '../../components/Media'
import { ImageField, RouteFields, TextField } from '../../types/SitecoreAdapter'

type Props = {
  className: string
} & RouteFields<{
  logo: ImageField
  mobileLogo: ImageField
  logoHref: TextField
}>

const PartnerLogo = ({ className, sitecoreContext: { route } }: Props) => {
  const {
    fields: { logo, mobileLogo, logoHref },
  } = route

  const hasLogoLink = logoHref.value !== ''

  const mobileLogoField =
    mobileLogo.value.src && mobileLogo.value.src !== '' ? mobileLogo : logo

  if (hasLogoLink) {
    return (
      <LinkWrapperWithChildItems href={logoHref.value} className={className}>
        <Media
          tablet={() => <Image field={logo} />}
          mobile={() => <Image field={mobileLogoField} />}
        />
      </LinkWrapperWithChildItems>
    )
  }

  return (
    <Media
      tablet={() => <Image field={logo} className={className} />}
      mobile={() => <Image field={mobileLogoField} className={className} />}
    />
  )
}

export default withSitecoreContext()(PartnerLogo)
