import React, { useReducer, useEffect, useContext, useRef } from 'react'
import { useCookieCheck } from '../services/useCookieCheck'
import usePreselectCheck from '../services/usePreselectCheck'
import useSafariPageLoadPersist from '../../../../util/useSafariPageLoadPersist'
import { AppSearch_Events, Reset } from './events'
import { Status, AppSearch_States } from './states'
import { searchAppReducer, getInitialState } from './reducer'
import { SearchController } from '../services/SearchController'
import {
  triggerNoResults,
  triggerFirstLetter,
  triggerDrop,
  appStartAnalytics,
  getQuoteLabel,
  triggerEventWithValue,
} from '../analytics/search-app-analytics'
import { EnvironmentContext } from '../../../../context/Environment/EnvironmentContext'
import { AppStartSettingsContext } from '../context'
import { OptionList } from '../types'
import { analyticsReducer } from './analytics-reducer'
import { iPhoneScrollRestore } from '../iPhoneScrollFix'

export type CloseCallback = {
  onClose?: () => void
}

export type DispatchProp = {
  dispatch: React.Dispatch<AppSearch_Events>
}

export type SearchAppCallbacks = {
  onInput: (searchInput: string) => void
} & CloseCallback

export type SearchAppStartEmbedProps = {
  loadOptions: (input: string) => Promise<OptionList>
  appState: AppSearch_States
  callbacks: SearchAppCallbacks
} & DispatchProp

type SearchAppStartContainerProps = {
  children: (
    searchAppStartProps: SearchAppStartEmbedProps
  ) => JSX.Element | null
  appStartKey?: string
}

type SearchInputSnapshot = string | false

const SearchAppStartContainer = ({
  children,
  appStartKey
}: SearchAppStartContainerProps): JSX.Element | null => {
  const environmentSettings = useContext(EnvironmentContext)

  const {
    preselectIndustry,
    preselectProfession,
    confirmSelection,
    startAppRouteQueryStr
  } = useContext(AppStartSettingsContext)

  // save the search input string to a ref
  // we don't want to re-render everything on each keypress (at least not yet)
  const searchInputSnapshot = useRef<SearchInputSnapshot>(false)

  // State is {status, direct, selection}
  // Status can be Idle, Searching, or AppStart
  // - Inert - typeahead is visible, but not launched
  // - Searching - typeahead has been launched, and user can interact with it
  // - AppStart - show loading state and launch the application
  const [appState, dispatch] = useReducer(
    analyticsReducer(
      searchAppReducer({ confirmSelection, preselectProfession }),
      searchInputSnapshot.current
    ),
    getInitialState(preselectProfession)
  )

  console.log(`[Search App Container] app state`, appState)

  // support the back button on iPhones
  // on iOS, page state is saved on back  ,
  // which freezes the typeahead in the `loading` state
  // so here, we reset the state so the component is interactive
  useSafariPageLoadPersist(() => dispatch(Reset), `Typeahead Container - ${appStartKey}` ?? 'Typeahead Container')

  // callback updates the search input ref
  const setSearchInputSnapshot = (s: SearchInputSnapshot) =>
    (searchInputSnapshot.current = s)

  // hook to check if cookies are supported
  // dispatches CookieCheck event
  useCookieCheck(dispatch)

  // SearchController instance holds a reference to 2 services:
  // - ListResults pulls industry / profession data from the content service
  // - SearchResults pull search results from a query
  const searchController = new SearchController(
    environmentSettings.endpoints.typeahead,
    environmentSettings.endpoints.typeaheadList,
    environmentSettings.endpoints.professions,
    environmentSettings.endpoints.app,
    { industry: preselectIndustry, profession: preselectProfession },
    {
      onNoResults: triggerNoResults,
    }
  )

  // callback for searching, compatible with react-select's loadOptions prop
  const loadOptions = searchController.loadOptions.bind(searchController)

  // it is possible for authors to preselect an invalid web business class id
  // so we perform a client side check on the data from the content service to ensure
  // that a preselected option maps to a valid application
  usePreselectCheck(searchController, preselectProfession, dispatch)

  // handle app start analytics and the redirect
  // use effect runs after the DOM has updated, so the loading state becomes visible first,
  // and then redirect occurs
  // If we try to redirect before rendering, the redirect causes javascript execution to pause
  // and the loading state isn't visible
  useEffect(() => {
    if (appState.status === Status.AppStart) {
      appStartAnalytics(
        getQuoteLabel({
          professionLabel: appState.selection.label,
          webBusClassId: appState.selection.value,
          searchInput: searchInputSnapshot.current,
          direct: appState.direct,
        })
      ).finally(() => {
        searchController.startApplication(appState.selection.value, startAppRouteQueryStr)
      })
    }
  }, [appState, startAppRouteQueryStr])

  useEffect(() => {
    if (appState.analyticsEvent !== undefined) {
      triggerEventWithValue(appState.analyticsEvent)
    }
  }, [appState])

  // when the component loads, prime the search controller cache with options from the content service
  useEffect(() => {
    searchController.primeCache()
  }, [searchController])

  // callback to handle search input changes
  const onInput = (s: string) => {
    // console.log('[State Container] on input callback', s)
    // this is set to only fire once, but will be called here multiple times
    triggerFirstLetter()
    // we keep the search input text state up to date because it's used elsewhere
    setSearchInputSnapshot(s)
  }

  // callback to handle closing
  const onClose = () => {
    iPhoneScrollRestore()
    // this is set to be called multiple times
    triggerDrop(searchInputSnapshot.current)
  }

  return children({
    appState,
    dispatch,
    loadOptions,
    callbacks: { onInput, onClose },
  })
}

export default SearchAppStartContainer
