import React from 'react'
import {
  withSitecoreContext,
  Image,
  RichText,
  ImageField,
} from '@sitecore-jss/sitecore-jss-react'
import Banner from './Banner'
import {
  BooleanField,
  RouteFields,
  TextField,
} from '../../types/SitecoreAdapter'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

export type BannerAdapterProps = RouteFields<{
  banner: {
    fields: {
      display: BooleanField
      icon?: ImageField
      text: TextField
      dateUp: TextField
      dateDown: TextField
    }
  }
}>

const BannerAdapter = ({
  sitecoreContext: {
    route: {
      fields: { banner },
    },
  },
}: BannerAdapterProps) => {
  if (!banner || !banner.fields.dateUp || !banner.fields.dateDown) {
    return null
  }

  const todaysDate = new Date()

  if (
    banner.fields.dateUp.value === '0001-01-01T00:00:00Z' ||
    banner.fields.dateDown.value === '0001-01-01T00:00:00Z' ||
    todaysDate < new Date(banner.fields.dateUp.value) ||
    todaysDate > new Date(banner.fields.dateDown.value)
  ) {
    return null
  }

  const text = <RichText field={banner.fields.text} />
  const icon = banner.fields.icon &&
    banner.fields.icon.value &&
    banner.fields.icon.value.src && <Image field={banner.fields.icon} />
  return <Banner text={text} icon={icon} />
}

export default withErrorBoundary(withSitecoreContext()(BannerAdapter), {
  component: 'Banner',
})
