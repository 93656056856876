import React from 'react'
import { Text, Image } from '@sitecore-jss/sitecore-jss-react'

import Nav from '../../page-components/Navigation'
import Banner from '../../components/Banner'
import ErrorPage from './ErrorPage'
import { ImageField, TextField, LinkField } from '../../types/SitecoreAdapter'

type Props = {
  route: {
    fields: {
      image: ImageField
      heading: TextField
      link: {
        fields: { text: TextField; href: TextField }
      }
      linkDataTag: TextField
    }
  }
}

const ErrorPageAdapter = ({ route }: Props) => {
  const { image, heading, link, linkDataTag } = route.fields

  return (
    <>
      <Nav />
      <Banner />
      <ErrorPage
        image={<Image field={image} />}
        heading={<Text field={heading} />}
        linkText={link && link.fields.text.value}
        linkHref={link && link.fields.href.value}
        linkDataTag={linkDataTag.value}
      />
    </>
  )
}

export default ErrorPageAdapter
