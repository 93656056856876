import * as React from 'react'
import {
  withSitecoreContext,
  Link,
  Text,
} from '@sitecore-jss/sitecore-jss-react'
import SidebarSection from '../../../components/SidebarSection'
import * as CMS from '../../../types/SitecoreAdapter'
import styles from '../styles.module.scss'

export type LinkItem = { text: string; href: string }

export type LearnMoreArticle = { article: { value: LinkItem } }

export type LearnMoreLink = CMS.Item<LearnMoreArticle>

type ILearnMoreSectionProps = CMS.RouteFields<{
  learnMore: LearnMoreLink[]
  learnMoreSectionHeader: CMS.TextField
}>

export function LearnMoreSection({
  sitecoreContext: { route },
}: ILearnMoreSectionProps) {
  const hasArticle = route.fields.learnMore.some(({ fields: { article } }) =>
    validateArticle(article)
  )

  const learnMore = hasArticle && (
    <SidebarSection
      testId="ArticlePage-LearnMore-SideSection"
      className={styles.learnMoreSection}
      title={<Text field={route.fields.learnMoreSectionHeader} />}
    >
      {route.fields.learnMore.map(({ fields: { article } }, index) => (
        <LearnMoreLink article={article} key={index} />
      ))}
    </SidebarSection>
  )

  return learnMore ? learnMore : null
}

function LearnMoreLink({ article }: LearnMoreArticle) {
  return <Link field={article.value} className={styles.learnMoreLink} />
}

function validateArticle(article: null | { value: LinkItem }) {
  return article && article.value.text !== '' && article.value.href !== ''
}

export default withSitecoreContext()(LearnMoreSection)
