import React, { ReactNode } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { useSetActive } from './services/useSetActive'
import { useFixedModalTop, InlineStyles } from './services/useFixedModalTop'
import { CloseButton } from './CloseButton'
import headingStyles from './heading.module.scss'
import * as Dialog from '@radix-ui/react-dialog'
import Media from '../../components/Media'

export type DesktopModalSize = 'md'

export type ModalProps = {
  modalHeading?: ReactNode
  children?: ReactNode
  onClose?: () => void
  className?: string
  desktopSize?: DesktopModalSize
}

const ModalContent = ({
  inlineStyles,
  onClose,
  className,
  desktopSize,
  modalHeading,
  children,
}: ModalProps & {
  inlineStyles?: InlineStyles
}) => {
  // Set's modal active state
  const isActive = useSetActive()

  const handleClose = () => {
    onClose && onClose()
  }

  return (
    <>
      <Dialog.Overlay
        className={clsx(styles.backdrop, isActive && styles.backdropActive)}
      />
      <Dialog.Content
        aria-label="modal content"
        className={clsx(
          className,
          styles.modal,
          isActive && styles.modalActive,
          desktopSize === 'md' && styles.sizeMd
        )}
        style={inlineStyles}
        onInteractOutside={handleClose}
      >
        {modalHeading && (
          <div className={headingStyles.heading}>{modalHeading}</div>
        )}
        <Dialog.Close asChild>
          <CloseButton onClose={handleClose} />
        </Dialog.Close>
        {children}
      </Dialog.Content>
    </>
  )
}

const DesktopModalContent = (props : ModalProps) => {
  const [inlineStyles] = useFixedModalTop()
  return <ModalContent {...props} inlineStyles={inlineStyles} />
}

export default function Modal(props: ModalProps) {
  return (
    <Media
      mobile={() => <ModalContent {...props} />}
      tablet={() => <DesktopModalContent {...props} />}
    />
  )
}
