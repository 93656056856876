import * as React from 'react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import SectionBase from './Section'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'
import { TextField } from '../../types/SitecoreAdapter'
import { isEmpty } from 'lodash-es'

type BackgroundColorItem = null | {
  fields: {
    color: {
      value: string
    }
  }
}

type SectionProps = {
  fields: {
    backgroundColor: BackgroundColorItem
    cssClass: {
      value: string
    }
    backgroundColorOverride: TextField
  }
  rendering: any
}

function Section({
  fields: {
    backgroundColor: backgroundColorItem,
    cssClass: { value: cssClass },
    backgroundColorOverride,
  },
  rendering,
}: SectionProps) {
  const backgroundOverride = backgroundColorOverride?.value
  return (
    <SectionBase
      backgroundColor={
        !isEmpty(backgroundOverride)
          ? backgroundOverride
          : getBackgroundColor(backgroundColorItem)
      }
      className={cssClass}
    >
      <Placeholder name="Section-Content" rendering={rendering} />
    </SectionBase>
  )
}

function getBackgroundColor(backgroundColorItem: BackgroundColorItem) {
  if (backgroundColorItem === null || backgroundColorItem === undefined) {
    console.warn('Expected Section to have a background color item')
    return 'white'
  }

  return backgroundColorItem.fields.color.value
}

export default withErrorBoundary(Section, { component: 'Section' })
