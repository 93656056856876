export interface RouteBusUnit {
  fields: {
    busUnitId: {
      value: string
    }
  }
}

export default function getBusUnitId(route: RouteBusUnit) {
  const busUnitId = route.fields.busUnitId.value
  if (busUnitId === '') {
    throw new Error('BusUnitId should not be empty')
  }
  return busUnitId
}
