import React, { useContext } from 'react'
import { Status } from '../../state-management/states'
import {
  SelectFocus,
  SelectCTA,
  ModalClose,
  RejectSelection,
  ConfirmSelection,
} from '../../state-management/events'
import { selectProfession } from '../../state-management/reducer'
import {
  triggerInputClick,
  triggerButtonClick,
} from '../../analytics/search-app-analytics'
import SelectAsync from '../../components/Select/SelectAsync'
import SelectInert from '../../components/Select/SelectInert'
import Modal from '../../../../../page-components/Modal'
import QuoteButton from '../../components/QuoteButton'
import { AppStartSettingsContext } from '../../context'
import styles from './styles.module.scss'
import {
  SearchAppStartEmbedProps,
  CloseCallback,
  DispatchProp,
} from '../../state-management/container'
import Button from '../../../../../components/Button'

export default function MobileHeroSearchAppStart({
  loadOptions,
  appState,
  dispatch,
  callbacks: { onClose, onInput },
}: SearchAppStartEmbedProps) {
  const {
    buttonText,
    mobilePlaceholder,
    modalHeading,
    preselectShowInput,
    confirmSelection,
    confirmButtonLabel,
  } = useContext(AppStartSettingsContext)

  if (appState.status === Status.Inert) {
    return (
      <div className={styles.control}>
        {preselectShowInput && (
          <SelectInert
            value={appState.selection}
            placeholder={mobilePlaceholder}
            onFocus={() => {
              triggerInputClick()
              dispatch(SelectFocus)
            }}
          />
        )}

        <div className={styles.mobileButton}>
          <QuoteButton
            onClick={() => {
              triggerButtonClick()
              dispatch(SelectCTA)
            }}
          >
            {buttonText}
          </QuoteButton>
        </div>
      </div>
    )
  }

  if (appState.status === Status.AppStart && appState.direct === true) {
    return (
      <div className={styles.control}>
        {preselectShowInput && (
          <SelectInert
            value={appState.selection}
            placeholder={mobilePlaceholder}
            isDisabled={true}
          />
        )}

        <div className={styles.mobileButton}>
          <QuoteButton loading disabled>
            {buttonText}
          </QuoteButton>
        </div>
      </div>
    )
  }

  return (
    <MobileModal
      onClose={onClose}
      dispatch={dispatch}
      modalHeading={modalHeading}
    >
      {confirmBodyActive() || confirmBodyLoading() || modalBody()}
    </MobileModal>
  )

  function confirmBodyActive() {
    if (
      confirmSelection &&
      appState.status === Status.Searching &&
      appState.selection !== undefined
    ) {
      return (
        <div className={styles.confirmBody}>
          <SelectInert
            className={styles.mobileConfirmSelect}
            value={appState.selection}
            onFocus={() => {
              dispatch(RejectSelection)
            }}
          />
          <Button
            className={styles.mobileConfirmButton}
            onClick={() => dispatch(ConfirmSelection)}
            size="xlarge"
            testId="ConfirmButton"
          >
            {confirmButtonLabel}
          </Button>
        </div>
      )
    }
  }

  function confirmBodyLoading() {
    if (confirmSelection && appState.status === Status.AppStart) {
      return (
        <div className={styles.confirmBody}>
          <SelectInert
            className={styles.mobileConfirmSelect}
            value={appState.selection}
            isDisabled
          />
          <Button
            className={styles.mobileConfirmButton}
            size="xlarge"
            testId="ConfirmButton"
            disabled
            loading
          >
            {confirmButtonLabel}
          </Button>
        </div>
      )
    }
  }

  function modalBody() {
    if (
      appState.status === Status.Searching ||
      appState.status === Status.AppStart
    ) {
      return (
        <div className={styles.horizontalSelectRoot}>
          <SelectAsync
            className={styles.mobileSelect}
            loadOptions={loadOptions}
            onSelect={option => {
              dispatch(selectProfession(option))
            }}
            onInput={onInput}
          />
        </div>
      )
    }
  }
}

type MobileModalProps = {
  children: React.ReactNode
  modalHeading: React.ReactNode
} & CloseCallback &
  DispatchProp

function MobileModal({
  children,
  onClose,
  dispatch,
  modalHeading,
}: MobileModalProps) {
  const handleClose = () => {
    onClose && onClose()
    dispatch(ModalClose)
  }

  return (
    <Modal modalHeading={modalHeading} onClose={handleClose}>
      {children}
    </Modal>
  )
}
