import React from 'react'
import loadable from '@loadable/component'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import ExploreOnInsureon, {
  LinkFields,
} from '../../../components/Explore-On-Insureon'
import { RouteFields, TextField } from '../../../types/SitecoreAdapter'
import { getRelatedPosts, RelatedPostsFields } from './getRelatedPosts'
import RelatedPosts from './RelatedPosts'

const SocialSharingSection = loadable(() => import('./SocialSharingSection'))

type AsideRouteFields = {
  relatedPosts: RelatedPostsFields
  onInsureonTitle: TextField
  onInsureon: LinkFields[]
}

type AsideAdapterProps = RouteFields<AsideRouteFields>

const AsideAdapter = ({
  sitecoreContext: {
    route: {
      fields: { relatedPosts, onInsureonTitle, onInsureon },
    },
  },
}: AsideAdapterProps) => {
  return (
    <>
      <RelatedPosts relatedPosts={getRelatedPosts(relatedPosts)} />
      <ExploreOnInsureon title={onInsureonTitle} linkItems={onInsureon} />
      <SocialSharingSection />
    </>
  )
}

export default withSitecoreContext()(AsideAdapter)
