import * as React from 'react'
import * as CMS from '../../types/SitecoreAdapter'
import { BreadcrumbLink } from './BreadcrumbList'
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react'

type IArticleBreadcrumbProps = {
  children: (breadcrumbs: BreadcrumbLink[]) => JSX.Element
}

type BreadcrumbContextProps = CMS.RouteFields<{ breadcrumbs: BreadcrumbLink[] }>

// Pull breadcrumbs from route information
export function BreadcrumbsFromRoute({ children }: IArticleBreadcrumbProps) {
  const { sitecoreContext: ctx } = useSitecoreContext()
  const { breadcrumbs } = (ctx as BreadcrumbContextProps['sitecoreContext'])
    .route?.fields
  return children(breadcrumbs)
}

export default BreadcrumbsFromRoute
