import React from 'react'

import MultiColumn from '../MultiColumn/base'
import Media from '../Media'
import SelectionModule, { SelectionOption } from './Selection-Module'
import Image from './Image'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type StateModuleProps = {
  fields: {
    image: string
    imageAlt: string
    heading: string
    description: string
    buttonText: string
    options: SelectionOption[]
    headingSize: string
  }
}

const StateModule = ({ fields }: StateModuleProps) => {
  const {
    image,
    imageAlt,
    heading,
    headingSize,
    description,
    buttonText,
    options,
  } = fields

  const headingEl =
    headingSize && headingSize === 'H3' ? (
      <h3>{heading}</h3>
    ) : (
      <h2>{heading}</h2>
    )

  const selection = (
    <SelectionModule
      heading={headingEl}
      description={description}
      buttonText={buttonText}
      options={options}
    />
  )

  const img = <Image src={image} alt={imageAlt} />

  const getDesktop = () => (
    <MultiColumn
      testId={'StateSelection-Module'}
      className="StateModule"
      left={selection}
      right={img}
      block
    />
  )

  const getTablet = () => (
    <MultiColumn
      testId={'StateSelection-Module'}
      className="StateModule"
      left={selection}
      right={img}
      block
    />
  )

  const getMobile = () => (
    <MultiColumn
      testId={'StateSelection-Module'}
      className="StateModule"
      left={img}
      right={selection}
      block
    />
  )

  return <Media desktop={getDesktop} tablet={getTablet} mobile={getMobile} />
}

export default withErrorBoundary(StateModule, { component: 'State-Module' })
