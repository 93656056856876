import React, { ReactNode } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import alert from '../../assets/alert.png'

type CookieErrorProps = {
  children?: ReactNode
  darkBg: Boolean
}

export default function CookieError({ children, darkBg }: CookieErrorProps) {
  return (
    <div
      data-test-context="SearchAppStart"
      data-test-id="CookieError"
      className={clsx(styles.root, darkBg && styles.darkBg)}
    >
      <img width="25" height="25" src={alert} className={styles.icon} />
      {children}
    </div>
  )
}
