import React, { Component, FC, useEffect, useState } from 'react'
import { get } from 'lodash-es'

import Slider from 'react-slick'
import styles from './styles.module.scss'
import '../../styles/slidedots.scss'
import { FeaturedPostsProps } from './types'

const FeaturedPosts: FC<FeaturedPostsProps> = props => {
  const [centerPadding, setCenterPadding] = useState('2.817rem')
  const { posts } = props
  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToScroll: 1,
    slidesToShow: 1,
    arrows: false,
    dotsClass: 'dots featuredDots',
  }

  // safely access window here
  // server rendering doesn't run this code
  useEffect(() => {
    const smallCenterPadding = get(window, 'screen.width') < 350

    if (smallCenterPadding) {
      setCenterPadding('.062rem')
    }
  }, [])

  return (
    <div>
      <div className={`${styles.wrap} slick-wrapper`}>
        <Slider {...settings}>{posts}</Slider>
      </div>
    </div>
  )
}

export default FeaturedPosts