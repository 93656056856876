import React from 'react'
import styles from './styles.module.scss'
import { TagData } from '../types'
import Tag from '../Tag/Tag'
import { TextField } from '../../../types/SitecoreAdapter'

export type TagListProps = {
  tags: TagData[]
  pathPrefix: TextField
}

const TagList = ({ tags, pathPrefix }: TagListProps) => {
  return (
    <ul className={styles.list}>
      {tags
        .map(
        (tagData) =>  (
            <li key={tagData.id} className={styles.item}>
              <Tag tag={tagData} pathPrefix={pathPrefix} />
            </li>
          )
      )}
    </ul>
  )
}

export default TagList
