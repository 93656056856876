import React from 'react'
import styles from './styles.module.scss'
import TagList from './Tag-List'

import { TagProps } from './types'

type DesktopTagListProps = {
  tags: TagProps[]
  title: string
}

const BlogTagTopics = ({ tags, title }: DesktopTagListProps) => (
  <div className={styles.desktopWrap}>
    <div className={styles.title}>{title}</div>
    <TagList tags={tags} rootPath="/blog" />
  </div>
)

export default BlogTagTopics
