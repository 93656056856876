import React, { useContext } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import Button from '../../Button'
import { ModalCTAContext } from '../../../context/ModalCTAContext'
import { triggerButtonClick } from '../../../quotes/experiences/search-app-start/analytics/search-app-analytics'

type PolicyCardButtonProps = {
  getQuotesLabel: React.ReactElement
  learnMoreLabel: React.ReactElement
}

type ModalButtonProps = {
  getQuotesLabel: React.ReactElement
  testId?: string
}

const ModalButton = ({ getQuotesLabel, testId }: ModalButtonProps) => {
  const { showModal } = useContext(ModalCTAContext)

  return (
    <div
      className={styles.modalButtonContainer}
      onMouseDown={e => e.stopPropagation()}
    >
      <Button
        testId={testId}
        className={clsx('button', styles.modalButton)}
        type="primary"
        onClick={() => {
          triggerButtonClick('PolicyCard')
          showModal()
        }}
      >
        {getQuotesLabel}
      </Button>
    </div>
  )
}

const PolicyCardButtons = ({
  getQuotesLabel,
  learnMoreLabel,
}: PolicyCardButtonProps) => {
  return (
    <div className={styles.buttonGroup}>
      <ModalButton
        testId="PolicyCard-GetQuoteBtn"
        getQuotesLabel={getQuotesLabel}
      />
      <Button
        testId="PolicyCard-LearnMoreBtn"
        className={clsx('button', styles.learnMoreButton)}
        type="secondary"
        onClick={() => {
          triggerButtonClick('LearnMore')
        }}
      >
        {learnMoreLabel}
      </Button>
    </div>
  )
}

export default React.memo(PolicyCardButtons)
