import * as React from 'react'
import Media from '../../Media'
import { HomeHeroColumnsBaseProps } from './types'
import Mobile from './Mobile'
import Desktop from './Desktop'

export default function HomeHeroColumnsBase(props: HomeHeroColumnsBaseProps) {
  return (
    <Media
      mobile={() => <Mobile {...props} />}
      laptop={() => <Desktop {...props} />}
    />
  )
}
