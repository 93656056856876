import React from 'react'
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react'
import PolicyCard from './PolicyCard'
import ButtonGroup from './ButtonGroup'
import HeadingAdapter from '../Heading'
import {
  TextField,
  LinkField,
  ImageField,
  Item,
} from '../../types/SitecoreAdapter'
import clsx from 'clsx'
import styles from './styles.module.scss'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type PolicyCardAdapterProps = {
  fields: {
    link: LinkField
    icon: ImageField
    title: TextField
    tag: Item<{ tag: TextField }>
    body: TextField
    desktopButton: TextField
    mobileButton: TextField
    bestFor: TextField
    linkTitle: TextField
    quoteButton: TextField
  }
}

const PolicyCardAdapter = ({
  fields: {
    link,
    icon,
    title,
    tag,
    body,
    desktopButton,
    mobileButton,
    bestFor,
    linkTitle,
    quoteButton,
  },
}: PolicyCardAdapterProps) => (
  <PolicyCard
    icon={<Image field={icon} loading="lazy" />}
    linkHref={link.value.href}
    linkTitle={linkTitle.value}
    heading={
      <HeadingAdapter
        testId={'PolicyCardQuote-Heading'}
        embed={true}
        addStyle={{ title: clsx(styles.title) }}
        fields={{ title, mobileTitle: title, tag }}
      />
    }
    body={<RichText field={body} />}
    bestFor={<RichText field={bestFor} />}
  >
    <ButtonGroup
      getQuotesLabel={<Text field={quoteButton} />}
      learnMoreLabel={learnMoreLabel(mobileButton, desktopButton)}
    />
  </PolicyCard>
)

export default withErrorBoundary(PolicyCardAdapter, {
  component: 'Policy-Card-Quote',
})

function learnMoreLabel(mobileButton: TextField, desktopButton: TextField) {
  return <Text field={mobileButton.value ? mobileButton : desktopButton} />
}
