import React from 'react'
import clsx from 'clsx'

export function withClassName(
  el: React.ReactElement,
  ...classes: (string | undefined)[]
) {
  return React.createElement(el.type, {
    ...el.props,
    className: clsx(el.props.className, classes)
  })
}
