import React, { FC } from 'react'
import AuthorSetContent from './index.base'
import { AuthorSetContentFieldType } from './types'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type AuthorSetContentProps = {
  fields: AuthorSetContentFieldType
}

const AuthorSetContentAdapter: FC<AuthorSetContentProps> = ({
  fields: { heading, cards, seeMoreLink, seeMoreText },
}) => {
  return (
    <AuthorSetContent
      heading={heading}
      cards={cards}
      seeMoreLink={seeMoreLink}
      seeMoreText={seeMoreText}
    />
  )
}

export default withErrorBoundary(AuthorSetContentAdapter, {
  component: 'AuthorSetContent',
})
