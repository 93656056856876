import clsx from 'clsx'
import React from 'react'
import styles from './styles.module.scss'
import { TP_AdditionalProps } from '../../TrustPilotWidget'
import { usePageType } from '../../../../util/usePageType'

type ProductReviewWidgetProps = {
  sku?: string
  productName?: string
} & TP_AdditionalProps

const ProductReviewWidget = React.forwardRef(
  (props: ProductReviewWidgetProps, ref: React.Ref<HTMLDivElement>) => {
    const pageType = usePageType();
    
    return (
      <div
        className={'TrustPilotProductReviewWidget'}
        data-test-id={'product-review-tp-widget'}
      >
        <div
          className={clsx(
            styles.widgetContainer,
            styles.miniWidget,
            'contain',
            styles[`tpWidget_${pageType}`]
          )}
          id="widgetContainer"
        >
          <div
            id={'trustBox'}
            ref={ref}
            className={styles.trustPilotWidget}
            data-locale="en-US"
            data-template-id="60f537b5b0f1639de1fe048c"
            data-businessunit-id="545aa5c800006400057b5df7"
            data-style-height="140px"
            data-style-width="100%"
            data-theme="light"
            data-schema-type="Product"
            data-sku={props.sku || ''}
            data-review-languages="en"
            data-tags="SelectedReview"
            data-no-reviews="hide"
          ></div>
        </div>
      </div>
    )
  }
)

export default ProductReviewWidget
