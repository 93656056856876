import React from 'react'
import { DescriptionWired } from './Description'
import { TwitterDescriptionWired } from './TwitterDescription'
import { OgDescriptionWired } from './OgDescription'
import { RobotsWired } from './Robots'
import { TitleWired } from './Title'
import { TwitterTitleWired } from './TwitterTitle'
import { TwitterHardcoded } from './TwitterHardcoded'
import { CanonicalWired } from './Canonical'
import { FacebookAppId } from './FacebookAppId'
import { OgTitleWired } from './OgTitle'
import { OgUrlWired } from './OgUrl'
import { SocialImageWired } from './SocialImage'
import { VirtualFolder } from './Tracking'
import { IE } from './IE'
import { OgType } from './OgType'

function Metas() {
  return (
    <>
      <TitleWired />
      <CanonicalWired />
      <DescriptionWired />
      <RobotsWired />
      <TwitterHardcoded />
      <OgType />
      <SocialImageWired />
      <TwitterTitleWired />
      <TwitterDescriptionWired />
      <OgTitleWired />
      <OgUrlWired />
      <OgDescriptionWired />
      <FacebookAppId />
      <VirtualFolder />
      <IE />
    </>
  )
}

export default Metas
