import React from 'react'

import styles from './styles.module.scss'
import { LinkWrapperWithChildItems } from '../../../../components/LinkWrapper'

export type SearchResult = {
  canonical: string
  description: string
  title: string
}

export type ResultsProps = {
  results: SearchResult[]
  total: number
}

function ResultItem(props: SearchResult) {
  return (
    <div className={styles.result}>
      <LinkWrapperWithChildItems className={styles.link} href={props.canonical}>
        {props.title}
      </LinkWrapperWithChildItems>
      <p className={styles.description}>{props.description}</p>
    </div>
  )
}

function Results({ results, total }: ResultsProps) {
  const resultItems = results.map((item, index) => (
    <ResultItem key={index} {...item} />
  ))

  return (
    <div className={styles.resultsContainer}>
      <div className={styles.resultCount}>{total} Results</div>
      <div className={styles.resultList}>{resultItems}</div>
    </div>
  )
}

export default Results
