import React, { useContext } from 'react'
import { SearchModalContext } from '../../context/SearchModalContext'
import SearchIcon from '../../assets/icon-gnav-search.svg'

import styles from './styles.module.scss'
import { NavigationContext } from '../../../Navigation/services/context'
import clsx from 'clsx'

const SearchNavButton = () => {
  const { showModal } = useContext(SearchModalContext)
  const { isPolicyFinderPage } = useContext(NavigationContext)

  return (
    <div className={clsx(styles.searchIcon, isPolicyFinderPage && styles.policyRecSearchIcon)} onClick={showModal}>
      <img src={SearchIcon} alt="Search Icon" />
    </div>
  )
}

export default SearchNavButton