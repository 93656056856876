/* 
  Util to generate an array of 2 breadcrumbs:
    1. Insureon Small Business Insurance Breadcrumb
    2. Custom breadcrumb based on provided input

  Used for `Tag Results` and `Blog Author` page types
*/

const crumbLinkAdapter = (text: string, href: string = '') => ({
  fields: {
    title: { value: text },
    link: { value: { href } },
    linkTitle: { value: text }
  },
})

export const getSmallBusinessInsuranceBreadcrumb = (title: string, href?: string) => {
  return [
    crumbLinkAdapter('Insureon Small Business Blog', '/blog'),
    crumbLinkAdapter(title, href),
  ]
}