import React from 'react'
import { Image, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'
import { ImageField, TextField } from '../../types/SitecoreAdapter'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type H3andIconProps = {
  fields: {
    icon: ImageField
    title: TextField
  }
  rendering: any
}

const H3andIcon = ({ fields, rendering }: H3andIconProps) => {
  return (
    <div
      data-test-id="H3WithIcon"
      className={clsx(spaceStyles.contain, 'block', styles.wrap)}
    >
      <Image className={styles.largeImage} field={fields.icon} />
      <div className={styles.right}>
        <Text tag="h3" field={fields.title} />
        <div className={clsx(styles.body, 'Subsection', 'list-style')}>
          <Placeholder name="icon-section-body" rendering={rendering} />
        </div>
      </div>
    </div>
  )
}

export default withErrorBoundary(H3andIcon, { component: 'H3andIcon' })
