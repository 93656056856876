import React from 'react'
import { getThumbnailFromId } from '../services/getThumbnail'
import styles from './styles.module.scss'
import clsx from 'clsx'

// Placeholder for before an author enters a video url
export default function({ id }: { id: string }) {
  const bgStyle = { backgroundImage: `url(${getThumbnailFromId(id)})` }
  const classes = clsx(styles.placeholder, styles.thumbnailPlaceholder) 

  return <div className={classes} style={bgStyle} />
}
