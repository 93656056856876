import * as tx from '@thi.ng/transducers'

// returns an iterable of [bottom, top] slides to crossfade between
// cycles infinitely
// So we have a list of [A B C]
// At the start, we want [A B], then [B C], then [C A], then [A B] ...
// https://docs.thi.ng/umbrella/transducers/modules.html#partition
export const frames = <T>(items: T[]) => {
  // we hint the type checker that this is a tuple of 2 T's
  return tx.partition(2, 1, tx.cycle(items)) as IterableIterator<[T, T]>
}