export type ImageBreakpoints = {
  xsMax: number
  sm: number
  smMax: number
  laptop: number
  laptopMax: number
  md: number
  mdMax: number
  lg: number
  lgMax: number
  xl: number
}

export const Breakpoints : ImageBreakpoints = {
  xsMax: 767,
  sm:    768,
  smMax: 1023,
  laptop: 1024,
  laptopMax: 1199,
  md:    1200,
  mdMax: 1599,
  lg:    1600,
  lgMax: 1899,
  xl:    1900
}