import React from 'react'
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react'
import {
  LinkField,
  TextField,
  ImageField,
  Item,
  BooleanField,
} from '../../types/SitecoreAdapter'
import CostModule, { CostModuleProps } from './CostModule'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type CostModuleFields = {
  heading: TextField
  image: ImageField
  body: TextField
  button: TextField
  mobileButton: TextField
  link: LinkField
  linkTitle: TextField
  buttonType: TextField
  isModalCTA: BooleanField
}

type CostModuleAdapterProps = Item<CostModuleFields>

const getProps = (fields: CostModuleFields) => {
  const { button, mobileButton, link, linkTitle, buttonType, isModalCTA } =
    fields

  const heading = <Text tag={'h2'} field={fields.heading} />
  const image = <Image field={fields.image} />
  const body = <RichText field={fields.body} />
  const desktopText = <Text field={button} />
  const mobileText = mobileButton.value ? (
    <Text field={mobileButton} />
  ) : (
    desktopText
  )
  return {
    heading,
    image,
    body,
    cta: {
      href: link.value.href,
      title: linkTitle.value,
      mobileText,
      desktopText,
      type: buttonType.value === 'Secondary' ? 'secondary' : 'primary',
      isModalCTA: isModalCTA.value,
      testId: 'Get-Quotes-CostModuleButton',
    },
  }
}

const CostModuleAdapter = ({ fields }: CostModuleAdapterProps) => {
  let props: CostModuleProps
  try {
    props = getProps(fields)
  } catch (e) {
    // @ts-ignore
    console.error(`Malformed CostModule template: ${e.message || e}`)
    return null
  }

  return <CostModule {...props} />
}

export default withErrorBoundary(CostModuleAdapter, {
  component: 'Cost-Module',
})
