import React from 'react'
import clsx from 'clsx'
import Button from '../Button'
import ModalCTA from '../Modal-CTA/ModalCTA'
import Media from '../Media'
import styles from './styles.module.scss'

export type CostModuleButtonProps = {
  href: string
  title: string
  mobileText: JSX.Element
  desktopText: JSX.Element
  type: string
  isModalCTA: boolean
  testId: string
}

const CostModuleButton = ({
  href,
  title,
  mobileText,
  desktopText,
  type,
  isModalCTA,
  testId,
}: CostModuleButtonProps) => {
  if (isModalCTA) {
    return getModalButton(mobileText, desktopText, testId)
  }

  return getDefaultButton(type, href, title, mobileText, desktopText, testId)
}

export default React.memo(CostModuleButton)

function getModalButton(mobileText: JSX.Element, desktopText: JSX.Element, testId: string) {
  return (
    <ModalCTA
      testId={testId}
      buttonClassName={clsx('button', styles.button, styles.link)}
      primary={true}
      size="medium"
      alignment={'Left'}
    >
      <Media mobile={() => mobileText} desktop={() => desktopText} />
    </ModalCTA>
  )
}

function getDefaultButton(
  type: string,
  href: string,
  title: string,
  mobileText: JSX.Element,
  desktopText: JSX.Element,
  testId: string
) {
  return (
    <Button
      testId={testId}
      className={clsx('button', styles.button, styles.link)}
      type={type}
      size="medium"
      href={href}
      title={title}
    >
      <Media mobile={() => mobileText} desktop={() => desktopText} />
    </Button>
  )
}
