import React from 'react'
import styles from './styles.module.scss'
import CollapsiblePanel from '../../components/CollapsiblePanel/base'
import { TagBandProps } from './TagBand'
import TagList from './TagList/TagList'

const MobileTagBand = ({ tags, title, pathPrefix }: TagBandProps) => {
  return (
    <div data-test-id={"TagBand-Wrap"} className={styles.containMobile}>
      <div className={styles.mobileWrap}>
        <CollapsiblePanel
          testId="TagBand-CollapsiblePanel"
          toggle={<div>{title.value}</div>}
          styleOverrides={{ body: styles.body, toggle: styles.title }}
        >
          <TagList tags={tags} pathPrefix={pathPrefix} />
        </CollapsiblePanel>
      </div>
    </div>
  )
}

export default MobileTagBand
