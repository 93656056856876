import React from 'react'
import Media from '../../components/Media'
import DesktopTagBand from './TagBand.Desktop'
import MobileTagBand from './TagBand.Mobile'
import { TagData } from './types'
import { TextField } from '../../types/SitecoreAdapter'

export type TagBandContext = {
  fields: TagBandProps
}

export type TagBandProps = {
  title: TextField
  tags: TagData[]
  pathPrefix: TextField
}

const TagBand = (props: TagBandContext) => {
  return (
    <Media
      desktop={() => <DesktopTagBand {...props.fields} />}
      tablet={() => <DesktopTagBand {...props.fields} />}
      mobile={() => <MobileTagBand {...props.fields} />}
    />
  )
}

export default TagBand