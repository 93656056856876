import React from 'react'

import { BooleanField, TextField } from '../../types/SitecoreAdapter'
import TrustPilotWidget from './TrustPilotWidget'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type TrustPilotWidgetAdapterProps = {
  fields: {
    type: TextField
    hideWidgetFooter: BooleanField
    heading?: TextField
    subHeading?: TextField
    sku?: TextField
    productName?: TextField
  }
}

const TrustPilotWidgetAdapter = ({
  fields: { type, hideWidgetFooter, heading, subHeading, sku, productName },
}: TrustPilotWidgetAdapterProps) => {
  return (
    <div data-test-id="TPWidgetContainer" className="block TrustPilotWidget">
      <TrustPilotWidget
        heading={heading}
        subHeading={subHeading}
        type={type.value}
        hideWidgetFooter={hideWidgetFooter.value}
        sku={sku && sku.value}
        productName={productName && productName.value}
      />
    </div>
  )
}

export default withErrorBoundary(TrustPilotWidgetAdapter, {
  component: 'TrustPilot-Widget',
})
