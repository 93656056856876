import * as React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

export default function Section({
  children,
  backgroundColor = '#fff',
  className,
}: {
  children?: React.ReactNode
  backgroundColor?: string
  className?: string
}) {
  return (
    <div
      data-test-id={'Section'}
      className={clsx('Section', 'block', styles.section, className)}
      style={{ backgroundColor: backgroundColor }}
    >
      {children}
    </div>
  )
}
