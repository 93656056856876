import React from 'react'
import { ImageField } from '../../types/SitecoreAdapter'
import { MediaProps } from '../Media'
import ResponsiveImage, { imageHasSrc } from './'
import { ImageBreakpoints } from './breakpoints'

type ResponsiveHeroImageProps = {
  mobileImage: ImageField
  tabletImage: ImageField
  laptopImage: ImageField
  desktopImage: ImageField
  backgroundImage: ImageField
  breakpoints?: ImageBreakpoints
  Media?: React.FC<MediaProps>
  applyDefaultImgSizing?: boolean
  className?: string
}

export function getResponsiveHeroImage({
  mobileImage,
  tabletImage,
  laptopImage,
  desktopImage,
  backgroundImage,
  breakpoints,
  Media,
  applyDefaultImgSizing,
  className,
}: ResponsiveHeroImageProps) {
  const desktopField = imageHasSrc(desktopImage)
    ? desktopImage
    : backgroundImage

  return (
    <ResponsiveImage
      mobileImage={mobileImage}
      tabletImage={tabletImage}
      laptopImage={laptopImage}
      desktopImage={desktopField}
      breakpoints={breakpoints}
      Media={Media}
      applyDefaultImgSizing={applyDefaultImgSizing}
      className={className}
    />
  )
}
