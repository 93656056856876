import React from 'react'
import { RichText } from '@sitecore-jss/sitecore-jss-react'
import styles from './styles.module.scss'

type Props = {
  value: string
}

const Footnote = (footnote: Props) => (
  <>
    {footnote.value && (
      <RichText field={footnote} tag="div" className={styles.footNote} />
    )}
  </>
)

export default Footnote
