import { IOption } from '../types'
import { Event, Event_SelectProfession, AppSearch_Events } from './events'
import {
  Status,
  State_Inert,
  AppSearch_States,
  State_BrowserError,
  State_Searching,
  State_AppStart,
} from './states'

export const getInitialState: (
  preselectProfession?: IOption | false
) => State_Inert = preselectProfession => ({
  status: Status.Inert,
  selection: preselectProfession ? preselectProfession : undefined,
})

export const selectProfession = (option: IOption) =>
  ({
    type: Event.SelectProfession,
    selection: option,
  } as Event_SelectProfession)

export type TypeaheadReducer = (state: AppSearch_States, event: AppSearch_Events) => AppSearch_States

export const searchAppReducer = ({
  confirmSelection,
  preselectProfession
}: {
  confirmSelection: boolean
  preselectProfession?: IOption | boolean
}) => (state: AppSearch_States, event: AppSearch_Events) => {
  // When cookies are disabled, show a browser error
  if (event.type === Event.CookieCheck && event.enabled === false) {
    return <State_BrowserError>{ status: Status.BrowserError }
  }

  // When preselected profession isn't valid, ensure selection is empty
  if (event.type === Event.PreselectCheck && event.valid === false) {
    return <State_Inert>{
      ...state,
      selection: undefined,
    }
  }

  // After closing, go back to inert display
  if (event.type === Event.ModalClose && state.status === Status.Searching) {
    return <State_Inert>{
      ...state,
      status: Status.Inert, 
      selection: !preselectProfession ? undefined : preselectProfession,
    }
  }

  // User selects CTA from inert view, and there isn't a selection yet,
  // Show search
  if (
    event.type === Event.SelectCTA &&
    state.status === Status.Inert &&
    !state.selection
  ) {
    return <State_Searching>{
      ...state,
      status: Status.Searching,
      direct: false,
    }
  }

  // We have preselected profession
  // User selects CTA
  // This is a direct app start because we have a selection at the time of clicking the CTA
  // We are confirming selections, so we open search mode with the hint that this is a direct app
  // that isn't confirmed
  if (
    confirmSelection &&
    (state.status === Status.Inert || state.status === Status.Searching) &&
    event.type === Event.SelectCTA &&
    state.selection !== undefined
  ) {
    return <State_Searching>{
      status: Status.Searching,
      selection: state.selection,
      direct: true,
    }
  }

  // We are confirming selections
  // We've gotten a selection confirmation event
  // We have a selection to start an app with
  // Fire away
  if (
    confirmSelection &&
    state.status === Status.Searching &&
    event.type === Event.ConfirmSelection &&
    state.selection !== undefined
  ) {
    return <State_AppStart>{
      status: Status.AppStart,
      direct: state.direct || false,
      selection: state.selection
    }
  }

  // We are confirming selections
  // We've gotten a reject selection event
  // Clear the selection
  if (
    confirmSelection &&
    state.status === Status.Searching &&
    event.type === Event.RejectSelection &&
    state.selection !== undefined
  ) {
    return <State_Searching>{
      ...state,
      status: Status.Searching,
      selection: undefined
    }
  }

  // We are not confirming selections
  // User hit a CTA and we have a selection, so this is a direct app start
  // Start the app
  if (
    !confirmSelection &&
    event.type === Event.SelectCTA &&
    (state.status === Status.Inert || state.status === Status.Searching) &&
    state.selection !== undefined
  ) {
    return <State_AppStart>{
      status: Status.AppStart,
      selection: state.selection,
      direct: true,
    }
  }

  if (event.type === Event.SelectFocus && state.status === Status.Inert) {
    return <State_Searching>{
      ...state,
      status: Status.Searching,
    }
  }

  if (event.type === Event.ModalOpen && state.status === Status.Inert) {
    return <State_Searching>{
      ...state,
      status: Status.Searching,
    }
  }

  // We are not confirming selections
  // User selected a profession, fire away
  if (
    !confirmSelection &&
    event.type === Event.SelectProfession &&
    state.status === Status.Searching
  ) {
    return <State_AppStart>{
      status: Status.AppStart,
      selection: event.selection,
    }
  }

  // We are confirming selections
  // User selected profession
  if (
    confirmSelection &&
    event.type === Event.SelectProfession &&
    state.status === Status.Searching
  ) {
    return <State_Searching>{
      ...state,
      selection: event.selection
    }
  }

  // after back navigation on mobile (ios) and browsers with bckward/forward cache enabled, resets the state
  if (event.type == Event.Reset && Status.AppStart === state.status) {
    return <State_Inert>{
      ...state,
      ...(!preselectProfession ? {selection: undefined} : {}),
      status: Status.Inert,
    }
  }

  return state
}
