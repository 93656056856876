declare global {
  interface Window {
    Sitecore: any;
  }
}

const fixRemoveRenderingFunction = (): void => {
  const urlParams = new URLSearchParams(window.location.search);
  const isEditMode = urlParams.get('sc_mode') === 'edit';

  if (isEditMode) {
    window.Sitecore.LayoutDefinition.removeRendering = function (device: any, uid: string): any {
      for (var n = 0; n < device.r.length; n++) {
        if (this.getShortID(device.r[n]["@uid"]) === uid) {
          var r = device.r[n];
          device.r.splice(n, 1);
          return r;
        }
      }
      return null;
    };
  }
};

export default fixRemoveRenderingFunction;