import React, { useState, ReactNode } from 'react'
import useSafariBackPageLoad from '../../../util/useSafariPageLoadPersist';
import { iPhoneScrollFix, iPhoneScrollRestore } from '../../../quotes/experiences/search-app-start/iPhoneScrollFix'

type SearchModalContextProps = {
  children?: ReactNode
}

type SearchModalContextSettings = {
  isVisible: boolean
  showModal: () => void
  hideModal: () => void
}

const initialSettings: SearchModalContextSettings = {
  isVisible: false,
  showModal: () => {},
  hideModal: () => {}
}

export const SearchModalContext = React.createContext(initialSettings);

export function SearchModalProvider({ children }: SearchModalContextProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  console.log('[Search Modal Provider] is modal open', isModalOpen)
  
  useSafariBackPageLoad(() => setIsModalOpen(false), 'Search Modal state')

  const contextObj = {
    isVisible: isModalOpen,
    showModal: () => {
      iPhoneScrollFix()
      setIsModalOpen(true)
    },
    hideModal: () => {
      iPhoneScrollRestore()
      setIsModalOpen(false)
    }
  }

  return (
    <SearchModalContext.Provider value={contextObj}>
      {children}
    </SearchModalContext.Provider>
  )
}
