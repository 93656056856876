import React from 'react'

import {
  BooleanField,
  ImageField,
  LinkField,
  RouteFields,
  TextField,
} from '../../types/SitecoreAdapter'

import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import TrustPilotWidget from '../TrustPilot-Widget/TrustPilotWidget'
import styles from './styles.module.scss'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type CustomerTestimonialAdapterProps = {
  fields: {
    heading: TextField
    link: LinkField
    mobileImage: ImageField
    desktopImage: ImageField
    trustPilotWidgetType?: TextField // Type of TrustPilot widget (i.e. Grid,Carousel, etc.)
    sku?: TextField
    productName?: TextField
  }
} & RouteFields<{
  tpWidgetGlobalSettings: {
    fields: {
      replaceCustomerTestimonial: BooleanField
    }
  }
}>

const CustomerTestimonial = ({
  fields: {
    heading,
    trustPilotWidgetType = { value: 'Carousel' },
    sku,
    productName,
  },
  sitecoreContext: {
    route: {
      fields: { tpWidgetGlobalSettings },
    },
  },
}: CustomerTestimonialAdapterProps) => {
  return (
    <div
      data-test-id="CustomerTestimonial"
      className="block CustomerTestimonial"
    >
      <TrustPilotWidget
        heading={heading}
        type={trustPilotWidgetType.value}
        sku={(sku && sku.value) || ''}
        productName={(productName && productName.value) || ''}
        externalStyles={styles.wrap}
      />
    </div>
  )
}

export default withErrorBoundary(withSitecoreContext()(CustomerTestimonial), {
  component: 'Customer-Testimonial',
})
