import React, { ReactNode } from 'react'

import clsx from 'clsx'

import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type SidebarSectionProps = {
  title?: ReactNode
  children?: ReactNode
  className?: string
  bottomMargin?: string
  testId?: string
}

const SidebarSection = ({
  title,
  children,
  className,
  bottomMargin,
  testId = 'SidebarSection',
}: SidebarSectionProps) => (
  <section
    data-test-id={testId}
    className={clsx(
      styles.base,
      styles[`base-${bottomMargin}`],
      className,
      spaceStyles.contain
    )}
  >
    {title && <div className={styles.title}>{title}</div>}
    <div>{children}</div>
  </section>
)

export default withErrorBoundary(SidebarSection, {
  component: 'SidebarSection',
})
