import * as React from 'react'
import * as CMS from '../../types/SitecoreAdapter'
import Helmet from 'react-helmet'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'

const Canonical = ({ children }: { children: string }) => {
  return (
    <Helmet>
      <link rel="canonical" href={children} />
    </Helmet>
  )
}

type CanonicalAdapterProps = CMS.RouteFields<{
  canonical: CMS.TextField
}>

const CanonicalAdapter = ({
  sitecoreContext: {
    route: {
      fields: { canonical },
    },
  },
}: CanonicalAdapterProps) => {
  return canonical.value ? <Canonical>{canonical.value}</Canonical> : null
}

export const CanonicalWired = withSitecoreContext()(CanonicalAdapter)
