import React from 'react'
import clsx from 'clsx'
import MultiColumn from '../MultiColumn/base'
import CostModuleButton, { CostModuleButtonProps } from './CostModuleButton'
import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'

export type CostModuleProps = {
  heading: JSX.Element
  image: JSX.Element
  body: JSX.Element
  cta: CostModuleButtonProps
}

const CostModule = ({ heading, image, body, cta }: CostModuleProps) => {
  return (
    <div data-test-id="CostModule" className={clsx('block', spaceStyles.contain, 'CostModule')}>
      <div className={styles.head}>{heading}</div>
      <MultiColumn
        left={React.createElement(image.type, {
          ...image.props,
          className: styles.img,
        })}
        right={
          <div className={clsx('list-style', styles.textWrap)}>
            {body}
            <CostModuleButton {...cta} />
          </div>
        }
      />
    </div>
  )
}

export default React.memo(CostModule)
