import * as React from 'react'
import styles from './styles.module.scss'
import { LinkWrapperWithChildItems } from '../LinkWrapper'

export default function CallCard({
  icon,
  label,
  phoneNumber,
}: {
  icon: React.ReactNode
  label: React.ReactNode
  phoneNumber: string
}) {
  const phoneLink = `tel:${phoneNumber}`
  return (
    <LinkWrapperWithChildItems
      additionalProps={{
        'data-tag': 'ContactBar',
        'data-test-id': 'ContactBar-PhoneLink',
      }}
      href={phoneLink}
      className={styles.card}
    >
      <div className={styles.icon}>{icon}</div>
      <div className={styles.label}>{label}</div>
    </LinkWrapperWithChildItems>
  )
}
