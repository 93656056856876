import clsx from 'clsx'
import React from 'react'
import styles from './styles.module.scss'
import { TP_AdditionalProps } from '../../TrustPilotWidget'

const MiniWidget = React.forwardRef(
  (props: TP_AdditionalProps, ref: React.Ref<HTMLDivElement>) => {
    return (
      <div className={'TrustPilotMiniWidget'} data-test-id={'mini-tp-widget'}>
        <div
          className={clsx(
            styles.widgetContainer,
            styles.miniWidget,
            props.variant === 'hero' && styles.tpMiniHeroWidget
          )}
          id="widgetContainer"
        >
          <div
            id={'trustBox'}
            ref={ref}
            className={styles.trustPilotWidget}
            data-locale="en-US"
            data-template-id="53aa8807dec7e10d38f59f32"
            data-businessunit-id="545aa5c800006400057b5df7"
            data-style-height="20px"
            data-style-width="100%"
            data-theme="light"
            data-tags="SelectedReview"
          ></div>
        </div>
      </div>
    )
  }
)

export default MiniWidget
