import { IOption } from '../../../types'

type SelectState = {
  inputValue: string
  value: IOption | null
  // We change this key on the async component to force a re-render after clicking clear
  key?: string
}

export const initialState = {
  inputValue: '',
  value: null,
  key: 'search',
}

enum SelectEvent {
  SetInput,
  ClearInput,
}

export const clearInput = { type: SelectEvent.ClearInput }

type SetInputEvent = {
  type: SelectEvent.SetInput
  value: string
}

export const setInput = (text: string) => {
  return {
    type: SelectEvent.SetInput,
    value: text,
  } as SetInputEvent
}

type SelectAction = { type: SelectEvent } | SetInputEvent

export function reducer(state: SelectState, action: SelectAction) {
  // console.log('[Insureon Select] reducer', action)

  if (action.type === SelectEvent.SetInput) {
    const ev = action as SetInputEvent
    const inputValue = cleanInput(ev.value)

    return {
      ...state,
      key: 'search',
      inputValue,
    }
  }

  if (action.type === SelectEvent.ClearInput) {
    return {
      key: 'clear',
      inputValue: '',
      value: null,
    }
  }

  return state
}

// ampersands at the beginning of a word cause react select to freeze
// so we remove them
export function cleanInput(input: string) {
  return input.replace(/[^a-zA-Z0-9 ]/g, '')
}
