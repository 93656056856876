import React, { FC } from 'react'
import styles from './../styles.module.scss'
import Stepper from '../../../../../components/Stepper'
import Button from './../../../../../components/Button'
import { QuestionType } from '../types'
import Question, { QuestionProps } from './Question'
import { isEmpty } from 'lodash-es'

interface QuestionViewProps {
  activeQuestion: QuestionType
  totalQuestions: number
  activeQueNumber: number
  handleAnswerSelection: QuestionProps['onAnswerSelect']
  selectedAnswer: QuestionProps['selectedAnswer']
  actionBtnProps: {
    onClick: () => void
    label: string
  }
}

const QuestionView: FC<QuestionViewProps> = ({
  activeQueNumber,
  activeQuestion,
  handleAnswerSelection,
  totalQuestions,
  selectedAnswer,
  actionBtnProps,
}) => {
  return (
    <div
      data-test-id={'prt-question-section'}
      className={styles.questionSectionWrap}
    >
      <div data-test-id={'prt-question-icon'} className={styles.questionIcon}>
        <img {...activeQuestion.icon} />
      </div>

      <div className={styles.questionProgressWrap}>
        <div
          data-test-id={'prt-question-progress-bar'}
          className={styles.stepperWrap}
        >
          <Stepper
            id="policy-rec-quetionnaire"
            steps={new Array(totalQuestions).fill('')}
            activeStep={activeQueNumber}
          />
        </div>
      </div>

      <Question
        data={activeQuestion}
        onAnswerSelect={handleAnswerSelection}
        questionNumber={activeQueNumber}
        selectedAnswer={selectedAnswer}
      />

      <Button
        testId={'prt-question-action-button'}
        size="medium"
        className={styles.actionButton}
        disabled={isEmpty(selectedAnswer)}
        onClick={actionBtnProps.onClick}
      >
        {actionBtnProps.label}
      </Button>
    </div>
  )
}

export default QuestionView
