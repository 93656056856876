import * as React from 'react'
import SlideshowContainer from './SlideshowContainer'
import SlideshowUi from './SlideshowUi'

type SlideshowProps = {
  // each slide must have a key set
  slides: JSX.Element[]
  height?: number
  width?: number
  className?: string
  durationSeconds?: number
}

export default function Slideshow({
  slides,
  height = 300,
  width = 500,
  className,
  durationSeconds,
}: SlideshowProps) {
  return (
    <SlideshowContainer slides={slides} durationSeconds={durationSeconds}>
      {({ frame }) => (
        <SlideshowUi
          frame={frame}
          height={height}
          width={width}
          className={className}
        />
      )}
    </SlideshowContainer>
  )
}
