import React, { Suspense } from 'react'

import { Placeholder, RouteData } from '@sitecore-jss/sitecore-jss-react'

import TestMetadata from '../../components/Test-Metadata'
import AboutHero, { AboutHeroProps } from './About-Hero'
import CTABanner, { ICTABannerProps } from '../../components/CTA-Banner'
import IconTabAdapter, {
  SitecoreTab,
} from '../../page-components/IconTabs/Adapter'
import Nav from '../../page-components/Navigation'
import Banner from '../../components/Banner'
import CTAAppStartSwitch from '../../quotes/CTAAppStartSwitch'

import styles from './styles.module.scss'

type PageProps = {
  route: {
    fields: {
      hero: {
        fields: AboutHeroProps
      }
      iconTabs: SitecoreTab[]
      ctaBanner: { fields: ICTABannerProps }
    }
  } & RouteData
}

const AboutPage = ({ route }: PageProps) => {
  const {
    fields: { hero, iconTabs, ctaBanner },
  } = route

  return (
    <>
      <Nav />
      <Banner />
      <Suspense>
        <div className="copy">
          <TestMetadata pageType="About" appStartType="Typeahead" />
          <AboutHero {...hero.fields} />

          <Placeholder name="Hero-Placeholder" rendering={route} />

          <IconTabAdapter tabs={iconTabs} />
          <div className={styles.contentSeparator} />
          <Placeholder name="About-Placeholder" rendering={route} />
          {ctaBanner && (
            <CTABanner
              testId="AboutPage-CTABanner"
              heading={ctaBanner.fields.heading}
              subhead={ctaBanner.fields.subhead}
              className={''}
            >
              <CTAAppStartSwitch />
            </CTABanner>
          )}
        </div>
      </Suspense>
    </>
  )
}

export default AboutPage
