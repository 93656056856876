import React, { ComponentType } from 'react'
import ErrorBoundary from './index'
import { WithErrorBoundaryProps } from './types'
import { DefaultFallback } from './Fallback'

const withErrorBoundary = <P extends Record<string, any>>(
  WrappedComponent: ComponentType<P>,
  config: WithErrorBoundaryProps
): ComponentType<P> => {
  return (props: P) => (
    <ErrorBoundary
      component={config.component}
      fallback={config?.fallback ? config?.fallback : DefaultFallback}
    >
      <WrappedComponent {...props} />
    </ErrorBoundary>
  )
}

export default withErrorBoundary
