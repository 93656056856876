import React from 'react'
import styles from './styles.module.scss'
import { TextField } from '../../types/SitecoreAdapter'
import { convertToLocalTZInvariantString } from '../../util/date'
import clsx from 'clsx'

export type UpdatedDateProps = {
  date: TextField
  fallbackDate: TextField
}

const UpdatedDate = ({ date, fallbackDate }: UpdatedDateProps) => {
  let displayDate
  if (date && date.value !== '0001-01-01T00:00:00Z') {
    displayDate = convertToLocalTZInvariantString(date.value)
  } else if (fallbackDate && fallbackDate.value !== '0001-01-01T00:00:00Z') {
    displayDate = convertToLocalTZInvariantString(fallbackDate.value)
  } else {
    displayDate = null
  }

  return (
    <>
      {displayDate && (
        <div className={clsx(styles.updatedDate, 'contain')}>
          Updated: {displayDate}
        </div>
      )}
    </>
  )
}

export default UpdatedDate
