import * as React from 'react'
import IndustryRouting, { IndustryRoutingItem } from './IndustryRouting'
import * as D from '../../types/SitecoreAdapter'
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react'
import Button from '../Button'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type IndustryAdapterProps = {
  href: {
    jss: D.TextField
  }
  text: {
    jss: D.TextField
  }
  title: {
    jss: D.TextField
  }
  image: {
    alt: string
    src: string
    width: number | string
    height: number | string
  }
}

type LinkButtonFields = {
  href: { value: string }
  title: { value: string }
  text: { value: string }
}

type IndustryRoutingAdapterProps = {
  fields: {
    data: {
      datasource: {
        heading: {
          jss: D.TextField
        }
        moreButton: {
          targetItem: LinkButtonFields
        }
        industries: {
          targetItems: IndustryAdapterProps[]
        }
      }
    }
  }
}

function IndustryRoutingAdapter({
  fields: {
    data: {
      datasource: {
        heading,
        moreButton,
        industries: { targetItems },
      },
    },
  },
}: IndustryRoutingAdapterProps) {
  return (
    <IndustryRouting
      heading={<RichText field={heading.jss} />}
      items={targetItems.map(IndustryItemAdapter)}
      foot={<LinkButtonAdapter button={moreButton.targetItem} />}
    />
  )
}

function IndustryItemAdapter({
  href,
  text,
  image,
  title,
}: IndustryAdapterProps) {
  return (
    <IndustryRoutingItem
      href={href.jss.value}
      label={<Text field={text.jss} />}
      imageProps={image || {}}
      key={href.jss.value}
      title={title.jss.value}
    />
  )
}

function LinkButtonAdapter({
  button,
  testId = 'IndustryRouting-FooterActionBtn',
}: {
  button: LinkButtonFields
  testId?: string
}) {
  if (!button) {
    return null
  }

  return (
    <Button
      href={button.href.value}
      title={button.title.value}
      type="secondary"
      testId={testId}
    >
      {button.text.value}
    </Button>
  )
}


export default withErrorBoundary(IndustryRoutingAdapter, {component: 'Industry-Routing'})