import React, { useEffect } from 'react'

// https://reacttraining.com/react-router/web/guides/scroll-restoration
export default function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return null
}
