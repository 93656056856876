import React, { Suspense, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { request } from 'graphql-request'

import Nav from '../../page-components/Navigation'
import ScrollToTopOnMount from '../../util/ScrollToTopOnMount'
import {
  SearchInput,
  LoadingResults,
  NullResults,
  Results,
  SearchResult,
  Paging,
  IndustryLinkProps,
  PolicyLinkProps,
  PAGE_SIZE,
  SEARCH_QUERY,
  SEARCH_PAGE,
} from '../../page-components/SiteSearch'
import { EnvironmentContext } from '../../context/Environment/EnvironmentContext'

import styles from './styles.module.scss'
import clsx from 'clsx'
import Media from '../../components/Media'
import { RouteData } from '@sitecore-jss/sitecore-jss-react'
import { Helmet } from 'react-helmet'

type SearchPageProps = {
  route: {
    fields: {
      nullResultPolicies: PolicyLinkProps[]
      nullResultIndustries: IndustryLinkProps[]
      nullResultIndustryEscapeLink: IndustryLinkProps
    }
  } & RouteData
}

const SearchPage = ({ route }: SearchPageProps) => {
  const environmentSettings = useContext(EnvironmentContext)

  const location = useLocation()
  const navigate = useNavigate()

  const [isError, setIsError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState<SearchResult[] | null>(null)
  const [startCursor, setStartCursor] = useState('0')
  const [endCursor, setEndCursor] = useState('0')
  const [total, setTotal] = useState(0)

  const query = new URLSearchParams(location.search).get('q') || ''
  const start = new URLSearchParams(location.search).get('s') || '0'

  const resetSearchState = () => {
    setIsError(false)
    setResults(null)
    setStartCursor('0')
    setEndCursor('0')
    setTotal(0)
  }

  const onNextPage = () => {
    changePage((p) => p + PAGE_SIZE)
  }

  const onPreviousPage = () => {
    changePage((p) => p - PAGE_SIZE)
  }

  const changePage = (getNewStart: (page: number) => number) => {
    const currentPage = Number.parseInt(start, 10)
    const newStart = getNewStart(currentPage)

    const qFragment = `q=${encodeURIComponent(query)}`
    const sFragment = newStart > 0 ? `&s=${newStart.toString(10)}` : ''

    navigate(`${SEARCH_PAGE}?${qFragment}${sFragment}`)
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    if (query == '') {
      return
    }

    setIsError(false)
    setLoading(true)
    const abortController = new AbortController()
    request({
      url: environmentSettings.endpoints.siteSearch,
      document: SEARCH_QUERY,
      variables: {
        query: query,
        cursor: start,
        pageSize: PAGE_SIZE,
      },
    })
      .then((response) => {
        setResults(response.siteSearch.results.items)
        setStartCursor(response.siteSearch.results.pageInfo.startCursor)
        setEndCursor(response.siteSearch.results.pageInfo.endCursor)
        setTotal(response.siteSearch.results.totalCount)
      })
      .catch((reason: any) => {
        if (abortController.signal.aborted) {
          return
        }
        console.dir(reason)
        resetSearchState()
        setIsError(true)
        setResults([]) // Display null results if we have an error
      })
      .finally(() => {
        setLoading(false)
      })
  }, [query, start])

  return (
    <>
      <Nav />
      <Helmet>
        <title>{query} - Search Results | Insureon</title>
      </Helmet>
      <Suspense>
        <div className={clsx(styles.search, 'copy')}>
          <ScrollToTopOnMount />

          <div className={styles.searchInputContainer}>
            <div className={styles.heading}>Search Results</div>
            <Media
              mobile={false}
              tablet={() => (
                <div className={styles.searchLabel}>Search Insureon</div>
              )}
            />
            <SearchInput key={query} defaultValue={query} />
          </div>

          <div className={styles.searchResultsContainer}>
            {loading && !results && <LoadingResults />}

            {results && results.length > 0 && (
              <>
                <Results results={results} total={total} />
                <div className={styles.pagingContainer}>
                  <Paging
                    total={total}
                    startCursor={startCursor}
                    endCursor={endCursor}
                    onNextPage={onNextPage}
                    onPrevPage={onPreviousPage}
                  />
                </div>
              </>
            )}

            {!loading && results && results.length === 0 && (
              <NullResults
                isError={isError}
                searchTerm={query}
                policies={route.fields.nullResultPolicies}
                industries={route.fields.nullResultIndustries}
                industryEscapeLink={route.fields.nullResultIndustryEscapeLink}
              />
            )}
          </div>
        </div>
      </Suspense>
    </>
  )
}

export default SearchPage
