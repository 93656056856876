import React, { ReactNode } from 'react'

import clsx from 'clsx'
import { chunk, get, isEmpty } from 'lodash-es'

import { NavigationItem } from '../../types'

import { LinkWrapperWithChildItems } from '../../../../components/LinkWrapper'

import PolicyRecommendationCTA from '../../../../components/Policy-Recommendation-CTA'
import { useSitecoreContext } from '../../../../util/useSitecoreContext'
import baseStyles from '../../styles.module.scss'
import styles from './styles.module.scss'

type TopLevelOption = {
  title: string
  href: string
  links: Section[]
}

type Section = {
  title: string
  href?: string
  links: NavigationItem[]
  numsPerColumn?: number
  width?: string
  divider?: boolean
  alignment?: string
  className?: string
}

type MegaMenuProps = {
  section: TopLevelOption
  closeHandler: () => void
  isOpen: boolean
}

const MegaMenu = ({ section, closeHandler, isOpen }: MegaMenuProps) => {
  const className = clsx(baseStyles.megaMenu, styles.megaMenu, isOpen && styles.isOpen)
  const { sitecoreContext } = useSitecoreContext()
  const hasPolicyCTA = section.href === "/resources" && !isEmpty(get(sitecoreContext, 'route.fields.policyRecommendationCtaSettings'))

  return (
    <div className={className} onMouseLeave={closeHandler}>
      <div className={styles.wrap}>
        <Row row={section.links} hasPolicyCTA={hasPolicyCTA} />
      </div>
    </div>
  )
}

export default MegaMenu

const Row = ({ row, hasPolicyCTA }: { row: Section[], hasPolicyCTA: boolean }) => {
  const isDualPane = row.length > 1

  const rowElements = row.map((outColumn, index) => {
    const rowStyle =
      isDualPane && index === 0 ? styles.divider : undefined

    const inlineStyle: { width?: string } = {}
    if (isDualPane && hasPolicyCTA) {
      inlineStyle.width = index === 0 ? '53%' : hasPolicyCTA ? '20%' : '47%'
    }

    return (
      <div
        key={index}
        className={clsx(styles.row, rowStyle)}
        style={{ ...inlineStyle }}
      >
        <Title title={outColumn.title} href={outColumn.href} />
        <div className={clsx(styles.columnWrap)}>
          <Column
            columns={outColumn.links}
            perCol={4}
            alignment={(isDualPane && 'half') || undefined}
          />
        </div>
      </div>
    )
  })

  return <>
    {rowElements}
    {hasPolicyCTA && <div style={{ width: '27%' }} className={clsx(styles.columnWrap, styles.hasPolicyCTA)}>
      <PolicyRecommendationCTA isVisible visibleOn={['desktop']} />
    </div>}
  </>
}

const Title = ({ title, href }: { title: ReactNode; href?: string }) => {
  const titleElement = <div className={styles.title}>{title}</div>
  return href ? (
    <LinkWrapperWithChildItems href={href} className={styles.titleLink} data-tag="meganav">
      {titleElement}
    </LinkWrapperWithChildItems>
  ) : (
    titleElement
  )
}

type ColumnProps = {
  columns: NavigationItem[]
  perCol?: number
  alignment?: string
}

const Column = ({ columns, perCol, alignment }: ColumnProps): JSX.Element => {
  const columnStyle = alignment === 'half' && styles.halfWidthColumn

  const colElements = chunk(columns, perCol).map((column, index) => {
    const links = column.map((link, index) => <Link link={link} key={index} />)

    return (
      <div key={index} className={clsx(styles.column, columnStyle)}>
        {links}
      </div>
    )
  })
  return <>{colElements}</>
}

type LinkProps = {
  link: NavigationItem
}

const Link = ({ link }: LinkProps) => (
  <LinkWrapperWithChildItems
    href={link.href}
    className={clsx(styles.link, link.isSummaryLink && styles.moreLink)}
    data-tag="meganav"
    title={link.title}
  >
    {!isEmpty(link.icon) && (
      <img
        src={link.icon}
        alt={link.iconAlt}
        title={link.iconAlt}
        className={styles.icon}
        loading={'lazy'}
      />
    )}
    {link.text}
  </LinkWrapperWithChildItems>
)
