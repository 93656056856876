import React from 'react'
import styles from './styles.module.scss'
import { Author } from '..'
import { isEmpty, toLower } from 'lodash-es'
import AuthorBioContent from './Content'
import { DEFAULT_VALUES } from '../../../../constants/blog-author'
import { LinkWrapperWithChildItems } from '../../../../components/LinkWrapper'
import Popover, { PopoverTrigger } from '../../../../components/Popover'
import { isServer } from '../../../../util/getEnv'

export type ContenProps = Author['fields']

const AuthorBioPopover = ({ ...contentProps }: ContenProps) => {
  const { shortDescription, name } = contentProps
  const isInsureonStaff =
    toLower(name?.value.trim()) === DEFAULT_VALUES.INSUREON_STAFF
  const isPopoverVisible = !isEmpty(shortDescription?.value)

  return isPopoverVisible ? (
    isInsureonStaff ? (
      <LinkWrapperWithChildItems
        href="/about-us/our-editorial-process"
        title={name?.value}
        className={styles.bioPageLink}
      >
        {name?.value}
      </LinkWrapperWithChildItems>
    ) : isServer ? (
      <span className={styles.activeAuthor}>{name?.value}</span>
    ) : (
      <Popover placement="bottom-start">
        <PopoverTrigger asChild>
          <span className={styles.activeAuthor}>{name?.value}</span>
        </PopoverTrigger>
        <AuthorBioContent {...contentProps} />
      </Popover>
    )
  ) : (
    <span className={styles.inactiveAuthor}>{name?.value}</span>
  )
}

export default AuthorBioPopover
