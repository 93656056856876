import React from 'react'
import { Image } from '@sitecore-jss/sitecore-jss-react'
import {
  NavigationType,
  NavigationSettings,
  LogoResponse,
  NavigationSitecoreContextType,
} from '../types'
import { ThemeEnum } from '../../../context/Theme'
import { PageTypes } from '../../../constants/page-type'

function resolveLogo(logo: LogoResponse) {
  // Fallback to default shape
  if (!logo) {
    console.warn('Missing logo from navigation tree')
    return { img: { src: '' }, mobileImg: { src: '' }, href: '' }
  }

  const mobileImageField = logo.mobileImg || logo.img

  return {
    img: { src: logo.img.src, alt: logo.img.alt },
    mobileImg: {
      src: mobileImageField.src,
      alt: mobileImageField.alt,
      width: mobileImageField.width,
      height: mobileImageField.height,
    },
    href: logo.href,
    title: logo.title,
  }
}

export default function getSettings({
  navigation,
  route: { templateName },
  pageEditing,
}: NavigationSitecoreContextType): NavigationSettings {

  const isIE11 = false
  const isPolicyFinderPage = templateName === PageTypes.POLICY_RECOMMENDATIONS_PAGE
  const logo = resolveLogo(
    (navigation && navigation.menu && navigation.menu.logo) || null
  )

  return {
    menu: navigation.menu,
    login: !isPolicyFinderPage, // Hides login btn on policy recommendation page
    logo: {
      img: <Image field={logo.img} width="154" height="64" />,
      mobileImg: (
        <Image
          field={logo.mobileImg}
          width="145"
          height="50"
        />
      ),
      href: logo.href,
      title: logo.title,
    },
    pageEditing,
    isIE11: false,
    pageTheme: ThemeEnum.Q221,
    navigationType: isIE11 ? NavigationType.DesktopNav : navigation.navigationType,
    quoteBtn: !isPolicyFinderPage, // Hides quoute btn on policy recommendation page
    isPolicyFinderPage
  }
}
