import { useCallback } from 'react'
import { useSitecoreContext } from '../../../../../util/useSitecoreContext'
import { useContext, useMemo } from 'react'
import { PolicyRecommendationContext } from '../../../context'
import { RecommendationAdaperProps } from '../types'
import { getPolicyCodes, mapFooterData, mapPolicyData } from './mapper'
import { compact, isEmpty } from 'lodash-es'
import { ModalCTAContext } from '../../../../../context/ModalCTAContext'
import { useNavigate } from 'react-router-dom'
import { PRT_CTA_URL } from '../../../../../constants/policy-recommendation'

export const useRecommendedPolicies = () => {
  /**
   * Constants
   */
  const navigate = useNavigate()
  const { sitecoreContext: ctx } = useSitecoreContext()
  const pageData = (ctx as RecommendationAdaperProps['sitecoreContext']).route
    .fields.policyRecommendationPageSettings
  const {
    selectedPolicies,
    setSelectedPolicies,
    recommendedPolicies: { main: mainPolicies, additional: additionalPolicies },
  } = useContext(PolicyRecommendationContext)
  const { showModal, setCTAData } = useContext(ModalCTAContext)
  const recommendedPolicies = useMemo(
    () => mapPolicyData({ pageData, policies: mainPolicies }),
    [pageData, mainPolicies]
  )
  const additionalRecommendedPolicies = useMemo(
    () => mapPolicyData({ pageData, policies: additionalPolicies }),
    [pageData, additionalPolicies]
  )
  const policyCodes = getPolicyCodes({ pageData, selectedPolicies })
  const startAppQueryString = `${
    !isEmpty(policyCodes) ? `LobCodes=${compact(policyCodes).join(',')}` : ''
  }`

  /**
   * Functions
   */
  const onPolicySelect = useCallback(
    (pid: string) => {
      const value = pid.trim()
      let finalizedPolicies = [...selectedPolicies]
      if (selectedPolicies.includes(value)) {
        finalizedPolicies = finalizedPolicies.filter((a) => a.trim() !== value)
      } else finalizedPolicies.push(value)
      setSelectedPolicies([...finalizedPolicies])
    },
    [selectedPolicies]
  )

  const onGetQuote = useCallback(() => {
    showModal()
    setCTAData && setCTAData({ startAppQueryString })
  }, [startAppQueryString, showModal])

  return {
    recommendedPolicies,
    onPolicySelect,
    selectedPolicies,
    additionalRecommendedPolicies,
    footer: mapFooterData({
      pageData,
      onStartOver: () => navigate(PRT_CTA_URL),
      selectedPolicies,
      onGetQuote,
    }),
  }
}
