import React, { useState } from 'react'
import { PopupModal } from 'react-calendly'
import clsx from 'clsx'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import styles from './styles.module.scss'
import { iPhoneScrollFix } from '../../quotes/experiences/search-app-start/iPhoneScrollFix'
import { RouteFields, TextField } from '../../types/SitecoreAdapter'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'

type CalendlyWidgetFromRouteProps = RouteFields<{
  calendlyWidgetAttributes: {
    fields: {
      calendlyUrl: TextField
      popupWidgetText: TextField
    }
  }
}> & {
  externalStyles?: string
  textOverride?: string
}

const CalendlyWidgetFromRoute = ({
  sitecoreContext: {
    route: {
      fields: {
        calendlyWidgetAttributes: {
          fields: { calendlyUrl, popupWidgetText },
        },
      },
    },
  },
  externalStyles,
  textOverride,
}: CalendlyWidgetFromRouteProps) => {
  const [isOpen, setIsOpen] = useState(false)

  if (!calendlyUrl || calendlyUrl.value === '') {
    return null
  }

  const handleOnClick = () => {
    iPhoneScrollFix()
    disableBodyScroll(window.document && window.document.body)
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
    clearAllBodyScrollLocks()
  }

  return (
    <>
      <div
        data-test-id={'calendly-link'}
        className={clsx(styles.popupText, externalStyles)}
        onClick={handleOnClick}
      >
        {textOverride || popupWidgetText.value}
      </div>
      {typeof window !== 'undefined' && window.document && (
        <PopupModal
          url={calendlyUrl.value}
          onModalClose={handleClose}
          open={isOpen}
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={window.document.body}
        />
      )}
    </>
  )
}

export default withSitecoreContext()(CalendlyWidgetFromRoute)
