import React, { FC, ReactElement } from 'react'
import PolicyCardList from './PolicyCardList'
import { RichText, withPlaceholder } from '@sitecore-jss/sitecore-jss-react'
import { BooleanField, TextField } from '../../types/SitecoreAdapter'
import { HeadingTagField } from '../Heading/types'
import { useSitecoreContext } from '../../util/useSitecoreContext'
import { PlaceholderProps } from '@sitecore-jss/sitecore-jss-react/types/components/PlaceholderCommon'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

const placeholderName = 'policy-cards-list'

type PolicyCardAdapterProps = PlaceholderProps & {
  policyCards: ReactElement[]
  fields: {
    threeCol: BooleanField
    escapeText: TextField
    title: TextField
    descriptor: TextField
    tag: HeadingTagField
  }
}

// TODO: `Heading` needs a refactor, for now we're just passing the CMS fields through
const PolicyCardListAdapter: FC<PolicyCardAdapterProps> = ({
  fields,
  policyCards,
}) => {
  const { sitecoreContext } = useSitecoreContext()
  const pageEditing = sitecoreContext && sitecoreContext.pageEditing
  const escapeTextElement = fields?.escapeText.value ? (
    <RichText field={fields?.escapeText} />
  ) : null

  return (
    fields && (
      <PolicyCardList
        title={fields?.title}
        titleTag={fields?.tag}
        descriptor={fields?.descriptor}
        escapeText={escapeTextElement}
        threeCol={fields.threeCol.value}
        forceSingleCol={pageEditing}
      >
        {policyCards}
      </PolicyCardList>
    )
  )
}

export default withErrorBoundary(
  withPlaceholder({
    placeholder: placeholderName,
    prop: 'policyCards',
  })(PolicyCardListAdapter as FC<PlaceholderProps>),
  { component: 'Policy-Card-List' }
)
