import React from 'react'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

const CareersList = () => {
  return (
    <div
      data-test-id="CareersList"
      className="paylocity-jobs"
      style={{
        maxWidth: '35rem',
        padding: '1rem',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <iframe
        title="Career Opportunities at Insureon"
        allowTransparency={true}
        frameBorder="0"
        scrolling="yes"
        width="100%"
        height="920px"
        src="https://recruiting.paylocity.com/recruiting/jobs/All/13403ee5-c6d2-4999-8047-f2b5b6553043/BIN-Insurance-Holdings-LLC"
      ></iframe>
    </div>
  )
}

export default withErrorBoundary(CareersList, { component: 'CareersList' })
