import * as React from 'react'
import Helmet from 'react-helmet'

export const TwitterHardcoded = () => {
  return (
    <Helmet>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@insureon" />
      <meta name="twitter:creator" content="@insureon" />
    </Helmet>
  )
}
