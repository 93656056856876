import React from 'react'
import styles from './styles.module.scss'
import LinkWrapper from '../../../components/LinkWrapper'
import { TagProps } from '../types'

function Tag({ label, href }: TagProps) {
  if (href) {
    return <LinkWrapper href={href} className={styles.tag} text={label} />
  }

  if (!label) return null

  return <span className={styles.tag}>{label}</span>
}

export default Tag
