import * as React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'

import BottomCtaBase from './base'

import { TextField } from '../../types/SitecoreAdapter'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type BottomCtaProps = {
  fields: {
    heading: TextField
  }
}

const BottomCTA = ({ fields: { heading } }: BottomCtaProps) => {
  return <BottomCtaBase heading={heading} />
}

export default withErrorBoundary(BottomCTA, { component: 'Bottom-CTA' })
