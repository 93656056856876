import React from 'react'
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react'
import { RouteFields, TextField } from '../../../types/SitecoreAdapter'
import Heading from '../../../components/Heading'
import Footnote from '../Footnote'
import ArticleBody from './ArticleBody'
import Attribution, { Author } from '../Attribution'
import { convertToLocalTZInvariantString } from '../../../util/date'
import { DEFAULT_VALUES as BLOG_AUTHOR_DEFAULT_VALUES } from '../../../constants/blog-author'

type ArticleBodyAdapterProps = RouteFields<{
  heading: any
  subhead: any
  blogAbout: any
  author: Author
  blogPostDate: any
  lastPublishedDate: any
  postDescription: any
  footnote: any
  contributor?: Author[]
  contributorPreText?: TextField
}>

const ArticleBodyAdapter = ({
  sitecoreContext: {
    route: {
      fields: {
        heading,
        subhead,
        blogAbout,
        author,
        blogPostDate,
        lastPublishedDate,
        postDescription,
        footnote,
        contributor,
        contributorPreText,
      },
    },
  },
}: ArticleBodyAdapterProps) => {
  const h1Tag = { fields: { tag: { value: 'h1' } } }

  const headingEl = (
    <Heading
      testId={'BlogPage-ArticleHeading'}
      fields={{
        title: heading,
        mobileTitle: heading,
        tag: h1Tag,
        descriptor: subhead,
      }}
      embed
    />
  )

  const footNote = (footnote && <Footnote {...footnote} />) || null

  let displayDate
  if (blogPostDate && blogPostDate.value !== '0001-01-01T00:00:00Z') {
    displayDate = convertToLocalTZInvariantString(blogPostDate.value)
  } else if (
    lastPublishedDate &&
    lastPublishedDate.value !== '0001-01-01T00:00:00Z'
  ) {
    displayDate = convertToLocalTZInvariantString(lastPublishedDate.value)
  } else {
    displayDate = null
  }

  return (
    <ArticleBody
      heading={headingEl}
      meta={
        <Attribution
          author={author}
          displayDate={displayDate}
          contributor={contributor}
          contributorPreText={
            contributorPreText?.value || BLOG_AUTHOR_DEFAULT_VALUES.REVIEWED_BY
          }
        />
      }
      description={<Text field={postDescription} />}
      footNote={footNote}
    />
  )
}

export default withSitecoreContext()(ArticleBodyAdapter)
