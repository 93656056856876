import clsx from 'clsx'
import React from 'react'
import styles from './styles.module.scss'
import { TP_AdditionalProps } from '../../TrustPilotWidget'

const MicroComboWidget = React.forwardRef(
  (props: TP_AdditionalProps, ref: React.Ref<HTMLDivElement>) => {
    return (
      <div
        className={'TrustPilotMicroComboWidget'}
        data-test-id={'micro-combo-tp-widget'}
      >
        <div
          className={clsx(
            styles.widgetContainer,
            props.variant === 'home' && styles.tpMicroHomeHeroWidget
          )}
          id="widgetContainer"
        >
          <div
            id={'trustBox'}
            ref={ref}
            className={styles.trustPilotWidget}
            data-locale="en-US"
            data-template-id="5419b6ffb0d04a076446a9af"
            data-businessunit-id="545aa5c800006400057b5df7"
            data-style-height="20px"
            data-style-width="100%"
            data-theme={props.darkBg ? 'dark' : 'light'}
            data-tags="SelectedReview"
          ></div>
        </div>
      </div>
    )
  }
)

export default MicroComboWidget
