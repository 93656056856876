import * as React from 'react'
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'
import { getResponsiveHeroImage } from '../Responsive-Image/HeroImageAdapter'
import { HomePageHeroProps } from '../Homepage-Hero/types'
import HomepageHeroColumns from './Base'
import SearchAppStart from '../../quotes/experiences/search-app-start/embeds/hero'
import SearchAppStartContext from '../../quotes/experiences/search-app-start/context'
import { heroBreakpoints } from './breakpoints'
import { MediaListener, getMedia } from '../Media'
import PolicyRecommendationCTA from '../Policy-Recommendation-CTA'
import styles from './styles.module.scss'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

function HomepageHeroColumnsAdapter({
  fields: {
    heading,
    descriptor,
    backgroundImage,
    mobileImage,
    tabletImage,
    laptopImage,
    desktopImage,
    quoteButtonText,
    isDark,
    showTrustPilotWidget,
  },
}: HomePageHeroProps) {
  // we're using a modified set of breakpoints,
  // with the switch between xs and sm being 600 instead of 768
  const CurrentBreakpoint = React.createContext('source')

  const Media = getMedia(CurrentBreakpoint.Consumer)

  const responsiveImage = getResponsiveHeroImage({
    mobileImage,
    tabletImage,
    laptopImage,
    desktopImage,
    backgroundImage,
    Media,
  })

  return (
    <MediaListener
      breakpoints={heroBreakpoints}
      BreakpointProvider={CurrentBreakpoint.Provider}
    >
      <HomepageHeroColumns
        backgroundImage={responsiveImage}
        heading={<RichText field={heading} />}
        descriptor={<Text field={descriptor} />}
        isDark={isDark}
        showTrustPilotWidget={showTrustPilotWidget}
      >
        <SearchAppStartContext
          darkBg={isDark && isDark.value}
          quoteButtonText={quoteButtonText.value}
        >
          <SearchAppStart />
        </SearchAppStartContext>
        {/* Policy Recommendation CTA - Home Page */}
        <PolicyRecommendationCTA
          wrapClassName={styles.prtCTAWrap}
          visibleOn={['desktop', 'laptop']}
        />
      </HomepageHeroColumns>
    </MediaListener>
  )
}

export default withErrorBoundary(HomepageHeroColumnsAdapter, {
  component: 'Homepage-Hero-Columns',
})
