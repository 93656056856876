import * as React from 'react'
import { Text, Placeholder } from '@sitecore-jss/sitecore-jss-react'
import { TextField } from '../../types/SitecoreAdapter'
import PolicyCardList from './PolicyCardList'
import Button from '../Button'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type SecondCTAProps = null | {
  fields: {
    text: TextField
    href: TextField
    title: TextField
  }
}

type PolicyCardListAdapterProps = {
  rendering: any
  fields: {
    heading: TextField
    descriptor: TextField
    quoteButtonText: TextField
    secondaryButton: SecondCTAProps
  }
}

function PolicyCardListAdapter({
  rendering,
  fields: { heading, descriptor, secondaryButton },
}: PolicyCardListAdapterProps) {
  return (
    <PolicyCardList
      heading={<Text field={heading} />}
      descriptor={<Text field={descriptor} />}
      cards={<Placeholder name="policy-cards-list" rendering={rendering} />}
      foot={<>{secondCTA(secondaryButton)}</>}
    />
  )
}

function secondCTA(props: SecondCTAProps) {
  if (props === null || props === undefined || props.fields.href.value === '') {
    return null
  }

  const {
    fields: { text, href, title },
  } = props

  return (
    <Button
      href={href.value}
      type="secondary"
      title={title.value}
      testId="PolicyCardList-FooterActionBtn"
    >
      {<Text field={text} />}
    </Button>
  )
}

export default withErrorBoundary(PolicyCardListAdapter, {
  component: 'Policy-Card-List-Q221',
})
