import React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import clsx from 'clsx'
import { TextField } from '../../types/SitecoreAdapter'

import styles from './styles.module.scss'
import './styles.scss'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

export interface ICTABannerProps {
  heading: TextField
  subhead: TextField
  className?: string
  children: React.ReactNode
  testId?: string
}

const CTABanner = ({
  heading,
  subhead,
  className,
  children,
  testId = 'CTABanner',
}: ICTABannerProps) => (
  <div
    data-test-id={testId}
    className={clsx(className, styles.main, 'block', 'CTABanner')}
    data-test-context="CTABanner"
  >
    <div className={styles.title}>
      <Text field={heading} tag="h3" />
    </div>
    <div className={styles.descriptor}>
      <Text field={subhead} />
    </div>
    {children}
  </div>
)

export default withErrorBoundary(CTABanner, { component: 'CTA-Banner' })
