import React, { ReactNode } from 'react'
import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'

type PullQuoteProps = {
  body: ReactNode
  attribution: ReactNode
}

const PullQuote = ({ body, attribution }: PullQuoteProps) => {
  return (
    <div data-test-id="PullQuote-Wrap" className={spaceStyles.contain}>
      <div className={styles.root}>
        <div className={styles.body}>{body}</div>
        {attribution && <div className={styles.attribution}>{attribution}</div>}
      </div>
    </div>
  )
}

export default React.memo(PullQuote)
