import React from 'react'
import styles from './styles.module.scss'
import placeholder from './video-play-button.png'
import clsx from 'clsx'

// Placeholder for before an author enters a video url
export default function() {
  return (
    <div className={clsx(styles.placeholder, styles.emptyPlaceholder)}>
      <img src={placeholder} width="100" />
    </div>
  )
}
