import React, { FC } from 'react'
import styles from './styles.module.scss'
import PolicyAccordion from './Accordion'
import { RecommendedPolicyType } from '../../types'

interface PolicyListSectionProps {
  title: string
  selectedPolicies: string[]
  policies?: RecommendedPolicyType[]
  onPolicySelect: (p: string) => void
}

const PolicyListSection: FC<PolicyListSectionProps> = ({
  title,
  policies = [],
  onPolicySelect,
  selectedPolicies,
}) => {
  return (
    <>
      <div className={styles.policyListSectionTitle}>{title}</div>

      {policies && (
        <div data-test-id={'prt-recommendation-policy-list'}>
          {policies.map((p, i) => (
            <PolicyAccordion
              key={p.id + i}
              {...p}
              onPolicySelect={onPolicySelect}
              isSelected={selectedPolicies.includes(p.id.trim())}
            />
          ))}
        </div>
      )}
    </>
  )
}

export default PolicyListSection
