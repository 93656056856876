import React, { memo, ReactNode } from 'react'
import Link from './Link'
import styles from './styles.module.scss'

export type ServerLink = {
  link: string
  title: string
  text: string
}

type ListProps = {
  links: ServerLink[]
  show?: boolean
  onLinkClick: ()=>void
}

export function List({ links, onLinkClick, show = true }: ListProps) {
  // we use display none so the list is always in the DOM, which is useful for SEO
  const showStyle = show ? { display: 'block' } : { display: 'none' }

  return (
    <ul style={showStyle} className={styles.listWrap}>
      {getLinkElements(links, onLinkClick)}
    </ul>
  )
}

function getLinkElements(links: ServerLink[], onLinkClick: ()=>void) {
  return links.map(i => linkFromServerSpec(i, onLinkClick)).map(wrapListItem)
}

function wrapListItem(linkElement: JSX.Element, index: number) {
  return <ListItem key={index}>{linkElement}</ListItem>
}

function linkFromServerSpec({ link, title, text }: ServerLink,  onLinkClick: ()=>void) {
  return <Link url={link} title={title} text={text} onClick={onLinkClick} />
}

function ListItem({ children }: { children: ReactNode }) {
  return <li className={styles.listItem}>{children}</li>
}

export default memo(List)
