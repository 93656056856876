import React from 'react'
import ExploreCardSection from './base'
import { ExploreCardSelectionFields } from './types.js'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type Props = {
  fields: ExploreCardSelectionFields
}

const ExploreCardSelection = ({ fields: { heading, cards } }: Props) => {
  return <ExploreCardSection heading={heading} cards={cards} />
}

export default withErrorBoundary(ExploreCardSelection, {
  component: 'Explore-Card-Section',
})
