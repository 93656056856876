import React, { ReactNode, useEffect } from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import {
  Navigation,
  NavigationType,
  LegacyNavFields,
  NavigationSettings,
  NavigationSitecoreContextType,
} from '../types'
import getNavSettings from './getNavSettings'
import useTheme from '../../../context/Theme/useTheme'
import { ThemeEnum } from '../../../context/Theme'

type NavigationContextProps = {
  sitecoreContext: NavigationSitecoreContextType
  children?: ReactNode
}

const initialSettings: NavigationSettings = {
  pageEditing: false,
  logo: { img: null, mobileImg: null, href: '', title: '' },
  navigationType: NavigationType.DesktopNav,
  menu: null,
  login: true,
  isIE11: false,
  pageTheme: ThemeEnum.Q221,
}

export const NavigationContext = React.createContext(initialSettings)

function NavigationContextComponent({
  sitecoreContext,
  children,
}: NavigationContextProps) {
  if (!sitecoreContext.navigation || !sitecoreContext.navigation.menu) {
    console.warn(
      'Navigation is missing from context and is required to render the nav'
    )
    return null
  }

  const settings: NavigationSettings = getNavSettings(sitecoreContext)

  useEffect(() => {
    settings.isIE11 = /Trident\/7/.test(window.navigator.userAgent)
    settings.navigationType = settings.isIE11
      ? NavigationType.DesktopNav
      : sitecoreContext.navigation.navigationType
  })

  settings.pageTheme = useTheme()

  return (
    <NavigationContext.Provider value={settings}>
      {children}
    </NavigationContext.Provider>
  )
}

export default withSitecoreContext()(NavigationContextComponent)
