import React from 'react'
import Tag from './Tag'
import styles from './taglist.module.scss'
import clsx from 'clsx'

import { TagListProps } from './types'

const TagList = ({ tags, rootPath = '', className }: TagListProps) => (
  <ul className={clsx(styles.list, className)}>
    {tags.map(({ label, href }, index) => (
      <li key={index} className={styles.item}>
        <Tag label={label} href={`${rootPath}${href}`} />
      </li>
    ))}
  </ul>
)

export default TagList
