import React, { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'

import { moduleClassNames } from '../css-utils'

import CommonMenuBase, { MegaMenu } from '../CommonMenu/index.base'
import ModalCTA from '../../../components/Modal-CTA/ModalCTA'
import { NavigationContext } from '../services/context'

import { SearchNavButton } from '../../SiteSearch'

import commonStyles from '../CommonMenu/styles.module.scss'
import styles from './styles.module.scss'
import { NavViewportSize } from '../types'
import LinkWrapper from '../../../components/LinkWrapper'
import RoutingNumberButton from '../../../components/Lead-Routing-Number-Attribution/components/RoutingNumberButton'

function MobileMenu() {
  const { logo, theme, menu, quoteBtn } = useContext(NavigationContext)
  const [screenWidth, setScreenWidth] = useState(
    (typeof window !== 'undefined' && window.innerWidth) || 0
  )

  useEffect(() => {
    if (typeof window !== 'undefined' && window.document) {
      setScreenWidth(window.innerWidth)

      const handleResizeWindow = () => setScreenWidth(window.innerWidth)
      // subscribe to window resize event "onComponentDidMount"
      window.addEventListener('resize', handleResizeWindow)
      return () => {
        // unsubscribe "onComponentDestroy"
        window.removeEventListener('resize', handleResizeWindow)
      }
    }
  }, [])

  return (
    <CommonMenuBase>
      {({
        showMenu,
        onToggleMenu,
        activeSection,
        showSubMenu,
        onCloseMenu,
        onShowSection,
      }) => {
        const menuClassName = clsx(
          commonStyles.menu,
          showMenu && commonStyles.isOpen
        )

        return (
          <nav
            className={clsx(
              commonStyles.nav,
              moduleClassNames(theme, commonStyles)
            )}
            data-test-context="Nav"
          >
            <div className={clsx(commonStyles.navbar, styles.navbar)}>
              <div className={commonStyles.left}>
                <div
                  data-test-id={'nav-hamburger-icon'}
                  className={menuClassName}
                  onClick={onToggleMenu}
                ></div>
                <LinkWrapper
                  href={logo.href}
                  title={logo.title}
                  className={commonStyles.logoLink}
                  image={logo.mobileImg}
                  imageStyles={clsx(commonStyles.logo, styles.logo)}
                />
              </div>
              <div className={commonStyles.right}>
                {screenWidth > NavViewportSize.xsMax ? (
                  <SearchNavButton />
                ) : (
                  <RoutingNumberButton />
                )}
                {quoteBtn && (
                  <ModalCTA
                    buttonClassName={clsx('button', styles.modalButton)}
                    wrapClassName={styles.modalButtonWrap}
                    alignment={'Left'}
                    primary={true}
                    size="slim"
                  >
                    {menu.startQuote.title}
                  </ModalCTA>
                )}
              </div>
            </div>

            <MegaMenu
              links={menu.links}
              activeSection={activeSection}
              isOpen={showMenu}
              menuClassName={styles.megaMenu}
              theme={theme}
              isSubMenuOpen={showSubMenu}
              onRequestClose={onCloseMenu}
              onShowSection={onShowSection}
              showSearch={screenWidth <= NavViewportSize.xsMax}
              hideCallUs={screenWidth <= NavViewportSize.xsMax}
            />
          </nav>
        )
      }}
    </CommonMenuBase>
  )
}

export default MobileMenu
