import React, { ReactNode, memo } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

export function CellHeader({
  children,
  className,
}: {
  children?: ReactNode
  className?: string
}) {
  return <div className={clsx(styles.header, className)}>{children}</div>
}

export default memo(CellHeader)
