import React from 'react'
import PlaceholderFactory from '../../../page-components/PlaceholderFactory'
import styles from './styles.module.scss'

type ArticleBodyProps = {
  heading: JSX.Element
  meta: JSX.Element | null
  description: JSX.Element
  footNote: JSX.Element
}

const ArticleBody = ({
  heading,
  meta,
  description,
  footNote,
}: ArticleBodyProps) => {
  return (
    <article>
      {heading}
      {meta && <div className={styles.meta}>{meta}</div>}
      <div className={styles.description}>{description}</div>
      <PlaceholderFactory name="Blog-Placeholder" />
      {footNote}
    </article>
  )
}

export default ArticleBody
