import React from 'react';
import styles from './styles.module.scss';

type CloseButtonProps = {
  onClose: () => void;
};

export const CloseButton = ({ onClose }: CloseButtonProps) => (
  <button
    className={styles.closeButton}
    type="button"
    onClick={onClose}
  ></button>
);
