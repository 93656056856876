import React from 'react'
import styles from './styles.module.scss'
import CTALinkIntercept from '../../quotes/CTALinkIntercept'

function EscapeText({ escapeText }: { escapeText?: React.ReactNode }) {
  if (escapeText) {
    return (
      <div className={styles.escapeText}>
        <CTALinkIntercept>{escapeText}</CTALinkIntercept>
      </div>
    )
  }

  return null
}

export default React.memo(EscapeText)
