import {
  flattenDeep,
  isArray,
  isEmpty,
  isEqual,
  map,
  pick,
  toLower,
  uniq,
  uniqWith,
} from 'lodash-es'
import {
  additionalPolicyLookup,
  defaultAdditionalPolicies,
  policyLookup,
} from './lookup-info'
import { policyInfo } from './policy-info'
import {
  AdditionalLookupType,
  AdditionalPolicyResType,
  FlatQueType,
  GetRecQuestionType,
  POLICY,
  PolicyLookupItemType,
  PolicyResType,
  QUESTION,
  QUESTION_RESPONSES,
} from './types'

const trimAns = (a: string) => toLower(a.trim())

const isValidQue = (qId: string) =>
  Object.values(QUESTION).map(a => toLower(a)).includes(toLower(qId as QUESTION))

const ansValidator = (aId: string) =>
  Object.values(QUESTION_RESPONSES).map(a => toLower(a)).includes(toLower(aId as QUESTION_RESPONSES))

const isValidAns = (ans: string) =>
  ans.includes(',') ? ans.split(',').every(ansValidator) : ansValidator(ans)

/**
 * Parses question and answer IDs from the URL
 * @returns An array of questions with selected ans.
 */
export const getQueAnsFromQS = (qs: string) => {
  const entries = new URLSearchParams(qs)
  const queAns: GetRecQuestionType[] = []
  for (const [que, ans] of entries) {
    if (isValidQue(que) && isValidAns(ans))
      queAns.push({ que, ans: ans.includes(',') ? ans.split(',') : ans })
  }
  return queAns
}

/**
 * Finds the policy for any given questions
 */
const lookupPolicy = (q: FlatQueType) =>
  pick(
    (policyLookup.find(
      pl => trimAns(pl.que) === trimAns(q.que)
    ) as PolicyLookupItemType).options.find(
      o => trimAns(o.option) === trimAns(q.ans)
    ),
    ['policy', 'whyRec', 'bestFor']
  ) as PolicyResType

/**
 * Returns finalized recommended policies based on the lookup data
 */
const getRecommendedPolicies = (policies: PolicyResType[]) => {
  let finalizedPolicies: PolicyResType[] = []
  uniqWith(policies, isEqual).forEach(p => {
    const pIndex = finalizedPolicies.findIndex(a => a.policy === p.policy)
    if (pIndex !== -1) {
      const indexedPolicy = finalizedPolicies[pIndex]
      finalizedPolicies[pIndex] = {
        ...indexedPolicy,
        bestFor: uniq([...indexedPolicy.bestFor, ...p.bestFor]),
        whyRec: uniq([...indexedPolicy.whyRec, ...p.whyRec]),
      }
    } else finalizedPolicies.push(p)
  })
  const hasBOP = finalizedPolicies.find(fp => fp.policy === POLICY.BOP)
  // Removes GL if BOP is recommended
  if (hasBOP) {
    finalizedPolicies = finalizedPolicies.filter(
      fp => fp.policy !== POLICY.GENERAL
    )
  }
  return [...finalizedPolicies]
}

/**
 * Finds additional policies based on the recommende policies
 */
const getAdditionalPolicies = (recommendedPolicies: PolicyResType[]) => {
  if (!isEmpty(recommendedPolicies)) {
    const recPolicyNames = map(recommendedPolicies, 'policy')
    const adPolicyLookup: AdditionalLookupType = additionalPolicyLookup.find(
      ap => ap.recommended.find(rp => isEqual(recPolicyNames.sort(), rp.sort()))
    ) as AdditionalLookupType
    const adPolicyNames = [
      ...(adPolicyLookup
        ? adPolicyLookup.additional
        : defaultAdditionalPolicies),
    ]
    return adPolicyNames.map(ap => ({ policy: ap, ...policyInfo[ap] }))
  } else return []
}

/**
 * Returns recommended and additional list of policies based on questions data
 */
export const getPolicyRecommendations = (
  ques: GetRecQuestionType[]
): {
  recommendedPolicies: PolicyResType[]
  additionalPolicies: AdditionalPolicyResType[]
} => {
  const flattenQues = flattenDeep(
    ques.map(q =>
      isArray(q.ans)
        ? q.ans.map(a => ({ que: q.que, ans: a }))
        : (q as FlatQueType)
    )
  )
  const policies: PolicyResType[] = flattenQues.map(q => lookupPolicy(q))
  const recommendedPolicies: PolicyResType[] = getRecommendedPolicies(policies)
  const additionalPolicies: AdditionalPolicyResType[] = getAdditionalPolicies(
    recommendedPolicies
  )

  return {
    recommendedPolicies,
    additionalPolicies,
  }
}
