import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { FC } from '../types/common'

// https://reacttraining.com/react-router/web/guides/scroll-restoration
const ScrollToTop: FC = ({ children }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return <>{children}</>
}

export default ScrollToTop
