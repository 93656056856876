import { useState, useEffect } from 'react'

// This hook is used when we need to know when a component renders in the DOM
// and is intended to be used for adding a post-render class for animations.
// -> Used for modal and modal background animations
//
// Example:
// const isActive = useSetActive()
// <div className={isActive && styles.active} />
export function useSetActive() {
  const [isActive, setActive] = useState(false)

  useEffect(() => {
    setActive(true)
  })

  return isActive
}
