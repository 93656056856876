import React, { Suspense } from 'react'

import Nav from '../../page-components/Navigation'
import Banner from '../../components/Banner'
import TestMetadata from '../../components/Test-Metadata'
import BlogBottomCTA from '../../page-components/BlogBottomCTA'
import BlogContain from '../../components/Blog-Card/Contain'
import BlogHero from '../../page-components/BlogHero'
import ScrollToTopOnMount from '../../util/ScrollToTopOnMount'
import PlaceholderFactory from '../../page-components/PlaceholderFactory'

import styles from './styles.module.scss'

const BlogHome = () => {
  return (
    <>
      <Nav />
      <Banner />
      <Suspense>
        <div className="copy BlogHome">
          <TestMetadata pageType="BlogHome" appStartType="Typeahead" />

          <ScrollToTopOnMount />

          <BlogHero />

          <PlaceholderFactory name="Blog-Home-Placeholder" />

          <div className={styles.bottomCTA}>
            <BlogContain>
              <BlogBottomCTA />
            </BlogContain>
          </div>
        </div>
      </Suspense>
    </>
  )
}

export default BlogHome
