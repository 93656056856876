import React from 'react'
import { ErrorBoundaryProps, ErrorBoundaryState } from './types'

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, error: { message: '' } }
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI
    return { hasError: true }
  }

  componentDidCatch(error: any, info: any) {
    // Set the error message
    this.setState({ error: error })
    console.log(`[Error Boundary] - ${this.props.component}`, { error, info })
  }

  render() {
    // Render fallback UI
    if (this.state.hasError) {
      return typeof this.props.fallback === 'function'
        ? this.props.fallback({
            error: this.state.error,
            component: this.props.component,
          })
        : this.props.fallback
    }

    return this.props.children
  }
}

export default ErrorBoundary
