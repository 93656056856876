import React from 'react'
import { RichText } from '@sitecore-jss/sitecore-jss-react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { TextField } from '../../types/SitecoreAdapter'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type Props = {
  pageDescription: TextField
}

const PageDescription = ({ pageDescription }: Props) => (
  <RichText
    className={clsx('contain', styles.description)}
    field={pageDescription}
  />
)

export default withErrorBoundary(PageDescription, {
  component: 'Page-Description',
})
