import * as React from 'react'
import BackgroundImage from '../../../Homepage-Hero/BackgroundImage'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { HomeHeroColumnsBaseProps } from '../types'
import TrustPilotWidget from '../../../TrustPilot-Widget/TrustPilotWidget'

export default function HomepageHeroColumnsDesktop({
  heading,
  descriptor,
  backgroundImage,
  isDark,
  showTrustPilotWidget,
  children,
}: HomeHeroColumnsBaseProps) {
  const darkBg = isDark && isDark.value

  return (
    <div className={styles.root} data-test-context="Hero">
      <div className={styles.content}>
        <div className={styles.heroHeadingContainer}>
          <h1
            className={clsx(styles.headline, !darkBg && styles.darkText)}
            data-test-id="desktopHeroHeadline"
          >
            {heading}
          </h1>
          {showTrustPilotWidget && showTrustPilotWidget.value && (
            <TrustPilotWidget type={'microCombo'} variant={'home'} />
          )}
        </div>
        
        <div className={styles.card}>
          <div className={clsx(styles.descriptor, 'h4')}>{descriptor}</div>
          {children}
        </div>
      </div>

      <BackgroundImage className={styles.backgroundImage}>
        {backgroundImage}
      </BackgroundImage>
    </div>
  )
}
