import * as React from 'react'
import * as CMS from '../../types/SitecoreAdapter'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import Helmet from 'react-helmet'

const OgTitle = ({ children }: { children: string }) => {
  return (
    <Helmet>
      <meta property="og:title" content={children} />
    </Helmet>
  )
}

type OgTitleAdapterProps = CMS.RouteFields<{
  ogtitle: CMS.TextField
  pageTitle: CMS.TextField
}>

const OgTitleAdapter = ({
  sitecoreContext: {
    route: {
      fields: { ogtitle, pageTitle },
    },
  },
}: OgTitleAdapterProps) => {
  const title = ogtitle.value ? ogtitle.value : pageTitle.value
  return <OgTitle>{title}</OgTitle>
}

export const OgTitleWired = withSitecoreContext()(OgTitleAdapter)
