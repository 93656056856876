import { IOption } from '../types'

export enum Event {
  CookieCheck,
  SelectFocus,
  SelectCTA,
  SelectProfession,
  ClearProfession,
  ModalOpen,
  ModalClose,
  Reset,
  PreselectCheck,
  ConfirmSelection,
  RejectSelection,
}

export type Event_PreselectCheck = {
  type: Event.PreselectCheck
  valid: boolean
}

export type Event_SelectProfession = {
  type: Event.SelectProfession
  selection: IOption
}

export type Event_CookieCheck = {
  type: Event.CookieCheck
  enabled: boolean
}

export type Event_SelectFocus = {
  type: Event.SelectFocus
}

export type Event_SelectCTA = {
  type: Event.SelectCTA
}

export const SelectCTA = {
  type: Event.SelectCTA,
} as Event_SelectCTA

export const SelectFocus = {
  type: Event.SelectFocus,
} as Event_SelectFocus

export type Event_ModalOpen = {
  type: Event.ModalOpen
}

export const ModalOpen = {
  type: Event.ModalOpen,
} as Event_ModalOpen

export type Event_ModalClose = {
  type: Event.ModalClose
}

export const ModalClose = {
  type: Event.ModalClose,
} as Event_ModalClose

export type Event_Reset = {
  type: Event.Reset
}

export const Reset = {
  type: Event.Reset,
} as Event_Reset

export type Event_ConfirmSelection = {
  type: Event.ConfirmSelection
}

export const ConfirmSelection = {
  type: Event.ConfirmSelection
} as Event_ConfirmSelection

export type Event_RejectSelection = {
  type: Event.RejectSelection
}

export const RejectSelection = {
  type: Event.RejectSelection
} as Event_RejectSelection

export type AppSearch_Events =
  | Event_ModalOpen
  | Event_SelectProfession
  | Event_CookieCheck
  | Event_SelectFocus
  | Event_ModalClose
  | Event_Reset
  | Event_SelectCTA
  | Event_PreselectCheck
  | Event_ConfirmSelection
  | Event_RejectSelection
