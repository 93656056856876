import React, { useContext } from 'react'
import { SearchModalContext } from '../../context/SearchModalContext'
import Modal from '../../../Modal'
import SearchInput from '../SearchInput'

import styles from './styles.module.scss'
import LeadRoutingNumber from '../../../../components/Lead-Routing-Number-Attribution'

function SearchModal() {
  const { isVisible, hideModal } = useContext(SearchModalContext);

  if (!isVisible) { return null; }

  return (
    <Modal className={styles.searchModal} onClose={hideModal}>
      <div className={styles.contentWrapper} data-test-context="SiteSearch">
        <div className={styles.heading}>Search Insureon</div>
        <SearchInput onSearch={ hideModal } />
        <div className={styles.routingAttr}>
          <LeadRoutingNumber testId={'SiteSearchModal-LeadRouteNumber'} variant={'search'} />
        </div>
      </div>
    </Modal>
  )
}

export default SearchModal;