import React, { FC } from 'react'
import { Field, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import CMSButton from './CMSButton'
import { BooleanField } from '../../types/SitecoreAdapter'
import { ButtonAlignment, ButtonSize } from '../../types/common'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

const getTitle = (isEditing: boolean, title: string) => {
  return !title && isEditing ? 'Edit Button' : title
}

type Fields = {
  title: Field<string>
  href: Field<string>
  alignment: { value: ButtonAlignment }
  type: Field<string>
  size: {
    value: 'Slim' | 'Medium' | 'Large' | 'Extra Large'
  }
  newWindow: BooleanField
}

type Props = {
  fields: Fields
  sitecoreContext: { pageEditing: boolean }
}

const Button: FC<Props> = ({ fields, sitecoreContext: { pageEditing } }) => {
  if (
    !fields ||
    (!pageEditing && (!fields.title.value || !fields.href.value))
  ) {
    return null
  }

  // defaults to primary, so if type is blank or not secondary, use primary
  const primary = fields.type.value !== 'Secondary'

  // map dropdown options to button size props
  const sizes = {
    Slim: 'slim',
    Medium: 'medium',
    Large: 'large',
    'Extra Large': 'xlarge',
  }

  // defaults size to large
  const normalizedSize = sizes[fields.size.value || 'Large']

  // defaults alignment to left
  const normalizedAlignment = fields.alignment.value || 'Left'

  const normalizedTitle = getTitle(pageEditing, fields.title.value)

  // prevents button from being clickable while editing
  const normalizedHref = pageEditing ? '' : fields.href.value

  return (
    <CMSButton
      title={normalizedTitle}
      href={normalizedHref}
      primary={primary}
      size={normalizedSize as ButtonSize}
      alignment={normalizedAlignment}
      newWindow={fields.newWindow.value}
      testId={'CMS-Button'}
    >
      {fields.title.value}
    </CMSButton>
  )
}

export default withErrorBoundary(withSitecoreContext()(Button), {
  component: 'CMS-Button',
})
