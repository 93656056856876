import { IOption } from '../types'
import { AsyncProfessionList, resultToOption } from './ListResults'

/**
 * SearchResults pulls results based on a search query string
 */
export class SearchResults {
  endpoint: string

  constructor(endpoint: string) {
    if (!endpoint) {
      throw new Error('Search endpoint required')
    }

    // console.log('[Search Results] constructor')
    this.endpoint = endpoint
  }

  async fetch(input: string): Promise<IOption[]> {
    try {
      const scrubbedInput = encodeURIComponent(cleanInput(input))
      // console.log(`[Atom Results] scrubbed input `, scrubbedInput)
      const response = await fetch(`${this.endpoint}${scrubbedInput}`)
      const atomResult: AsyncProfessionList = await response.json()
      return atomResult.map(resultToOption)
    } catch (err) {
      console.log(`Unable to fetch search results for ${input} from Atom`)
      console.log(err)
    }
    return []
  }
}

// treating this as a singleton for now
type SearchResultsInstance = false | SearchResults

let searchResultsInstance: SearchResultsInstance = false

export function getSearchResults(endpoint: string): SearchResults {
  if (searchResultsInstance === false) {
    searchResultsInstance = new SearchResults(endpoint)
  }

  return searchResultsInstance
}

function cleanInput(input: string) {
  return input.replace(/&/g, '')
}
