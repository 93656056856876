import React, {memo, ReactNode} from 'react'
import styles from './styles.module.scss'

export type LinkProp = {
  url: string
  title: string
  text: ReactNode
  onClick: ()=>void
}

export function Link({
  url,
  title,
  text,
  onClick
}: LinkProp) {
  return (
    <a href={url} title={title} className={styles.link} onClick={onClick}>
      {text}
    </a>
  )
}

export default memo(Link)