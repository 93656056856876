import React, { useContext } from 'react'
import Media from '../../../../../components/Media'
import Mobile from './Mobile'
import Desktop from './Desktop'
import { triggerEscapeLink } from '../../analytics/search-app-analytics'
import { AppStartSettingsContext } from '../../context'
import SearchAppStartContainer from '../../state-management/container'
import { Status } from '../../state-management/states'
import clsx from 'clsx'
import styles from './styles.module.scss'
import PolicyRecommendationCTA from '../../../../../components/Policy-Recommendation-CTA'
import LeadRoutingNumberAttribution from '../../../../../components/Lead-Routing-Number-Attribution'
import { isEmpty } from 'lodash-es'

export type CTASearchAppStartProps = {
  showEscapeLink?: Boolean
  rootClass?: string
  appStartKey?: string
}

export default function CTASearchAppStart({
  showEscapeLink = true,
  rootClass,
  appStartKey,
}: CTASearchAppStartProps) {
  const {
    cookieError,
    darkBg,
    hasPolicyRecCTA = false,
    escapeLinkText = '',
  } = useContext(AppStartSettingsContext)
  const escapeLinkClsx = clsx(
    darkBg !== undefined
      ? [styles.escapLink, darkBg ? styles.darkBg : styles.lightBg]
      : styles.escapLinkDefault
  )

  const handleClick = () => {
    if (triggerEscapeLink) {
      triggerEscapeLink().finally(() => {
        console.log('[Search App] Espcape Link waited for analytics')
      })
      return false // don't follow the link by default
    } else return true
  }

  return (
    <SearchAppStartContainer appStartKey={appStartKey || 'CTASearchAppStart'}>
      {({ appState, dispatch, loadOptions, callbacks }) => {
        // cookie check fails
        if (appState.status === Status.BrowserError) {
          return cookieError
        }

        return (
          <div
            className={clsx('CTA', rootClass)}
            data-test-context="SearchAppStart"
          >
            <Media
              mobile={() => (
                <Mobile
                  appState={appState}
                  dispatch={dispatch}
                  loadOptions={loadOptions}
                  callbacks={callbacks}
                />
              )}
              tablet={() => (
                <Desktop
                  appState={appState}
                  dispatch={dispatch}
                  loadOptions={loadOptions}
                  callbacks={callbacks}
                />
              )}
            />
            {showEscapeLink && !isEmpty(escapeLinkText) && (
              <div className={styles.escapLinkWrap}>
                <LeadRoutingNumberAttribution
                  testId={'SearchAppCTA-LeadRouteNumber'}
                  isBgDark={darkBg}
                  className={escapeLinkClsx}
                  styleOverrides={{
                    ctaAttrNumber: escapeLinkClsx,
                  }}
                  variant="cta"
                  onClick={handleClick}
                />
              </div>
            )}
            {/* Policy Recommendation CTA */}
            {hasPolicyRecCTA && (
              <PolicyRecommendationCTA
                theme={darkBg ? 'light' : 'dark'}
                direction="row"
                variant="default"
                visibleOn={['desktop', 'laptop', 'mobile', 'tablet']}
              />
            )}
          </div>
        )
      }}
    </SearchAppStartContainer>
  )
}
