import React from 'react'
import Button from '../Button'
import Media from '../Media'

import styles from './styles.module.scss'

type PaginationProps = {
  currentPage: number
  totalResults: number
  pageSize: number
  onNextClick: (event: MouseEvent) => void
  onPreviousClick: (event: MouseEvent) => void
  nextPath: string
  prevPath: string
  testId?: string
}

const Pagination = ({
  currentPage,
  totalResults,
  pageSize,
  onNextClick,
  onPreviousClick,
  nextPath,
  prevPath,
  testId = "Pagination"
}: PaginationProps) => {
  const currentIndex = currentPage - 1
  const fromResult = currentIndex * pageSize + 1
  const toResult = Math.min((currentPage * pageSize), totalResults)
  const resultsPerPage = Math.min(totalResults - toResult, pageSize)
  
  return (
    <div data-test-id={testId} className={styles.pagination}>
      <div className={styles.showing}>
        Showing {fromResult} - {toResult} of {totalResults}
      </div>
      {(prevPath || nextPath) && <div className={styles.xlargeSpacer} />}
      <div className={styles.buttons}>
        {prevPath && (
          <Button testId='Pagination-BackBtn' size="medium" secondary href={prevPath} onClick={onPreviousClick}>
            <Media
              desktop={() => `Show previous ${pageSize} results`}
              tablet={() => `Previous ${pageSize} results`}
              mobile={() => `Go back`}
            />
          </Button>
        )}
        {prevPath && nextPath && <div className={styles.spacer} />}
        {nextPath && (
          <Button testId='Pagination-NextBtn' size="medium" href={nextPath} onClick={onNextClick}>
            <Media
              desktop={() => `Show next ${resultsPerPage} results`}
              mobile={() => `Next ${resultsPerPage} results`}
            />
          </Button>
        )}
      </div>
    </div>
  )
}

export default Pagination
