import React from 'react'
import NavigationContext from './services/context'
import Navigation from './Navigation'

const NavAdapter = () => (
  <NavigationContext>
    <Navigation />
  </NavigationContext>
)

export default NavAdapter
