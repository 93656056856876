import React, { ReactNode } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

type BannerProps = {
  text: ReactNode
  icon?: ReactNode
}

const Banner = ({ text, icon }: BannerProps) => {
  return (
    <div data-test-id="Banner" className={clsx(styles.banner, 'copy-article')}>
      {icon && (
        <div className={styles.icon}>{icon}</div>
      )}
      <div className={styles.text}>{text}</div>
    </div>
  )
}

export default React.memo(Banner)
