import React, { ReactNode } from 'react'
import Nav from '../../../page-components/Navigation'
import Banner from '../../../components/Banner'
import TestMetadata from '../../../components/Test-Metadata'
import ScrollToTopOnMount from '../../../util/ScrollToTopOnMount'
import BreadcrumbAdapter from './BreadcrumbAdapter'

type WrapperProps = {
  children: ReactNode
}

const Wrapper = ({ children }: WrapperProps) => (
  <>
    <Nav />
    <Banner />
    <TestMetadata pageType="BlogPost" appStartType="Typeahead" />
    <BreadcrumbAdapter />
    <div className="copy-article">
      <ScrollToTopOnMount />
      {children}
    </div>
  </>
)

export default Wrapper
