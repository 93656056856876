import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React, { ReactNode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppRoot from './main/AppRoot'

import config from './temp/config'
import { initSentry } from './sentry'
import { createRoot, hydrateRoot } from 'react-dom/client'
import i18nInit from './i18n'

type ParsedServerState = { success: true; data: any }

type EmptyServerState = { success: false }

type ServerStateResult = EmptyServerState | ParsedServerState

initSentry() // Initializes Sentry

let initLanguage = config.defaultLanguage

let renderFunction = (rootElement: Element, component: ReactNode) => {
  const root = createRoot(rootElement)
  root.render(component)
}

/*
  SSR Data
  If we're running in a server-side rendering scenario,
  the server will provide JSON in the #__JSS_STATE__ element
  for us to acquire the initial state to run with on the client.
  This enables us to skip a network request to load up the layout data.
  We are emitting a quiescent script with JSON so that we can take advantage
  of JSON.parse()'s speed advantage over parsing full JS, and enable
  working without needing `unsafe-inline` in Content Security Policies.
  SSR is initiated from /server/server.js.

  https://github.com/Sitecore/jss/blob/dev/samples/react/src/index.js
*/

const hydrateStateResult = tryGetHydrateState()

const rootElement = document.getElementById('root')

/*
  App Rendering
*/
i18nInit(config.defaultLanguage, '').then(() => {
  if (hydrateStateResult.success) {
    // when React initializes from a SSR-based initial state, you need to render with `hydrateRoot` instead of `render`
    renderFunction = hydrateRoot

    console.log('[Index] Hydrating')
    console.log('[Index] Environment is ' + config.env)
    renderFunction(
      rootElement as Element,
      <AppRoot
        path={window.location.pathname}
        Router={BrowserRouter}
        serverData={hydrateStateResult.data}
      />
    )
  } else {
    console.log('[Index] No server state for hydrate')
    renderFunction(
      rootElement as Element,
      <AppRoot path={window.location.pathname} Router={BrowserRouter} />
    )
  }
})

function tryGetHydrateState(): ServerStateResult {
  const ssrRawJson = document.getElementById('__JSS_STATE__')

  if (ssrRawJson) {
    const data = JSON.parse(ssrRawJson.innerHTML)
    if (data !== null) {
      return { success: true, data: data }
    }
  }

  return { success: false }
}
