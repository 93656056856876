import React from 'react'
import EmptyPlaceholder from './EmptyPlaceholder'
import ThumbnailPlaceholder from './ThumbnailPlaceholder'
import wrapperStyles from '../Wrapper/styles.module.scss'
import styles from '../styles.module.scss'
import clsx from 'clsx'

const getPlaceholder = (id?: string) => {
  if (!id) {
    return <EmptyPlaceholder />
  }

  return <ThumbnailPlaceholder id={id} />
}

type PlaceholderProps = {
  id?: string
}

// Placeholder for before an author enters a video url
export default function({ id }: PlaceholderProps) {
  return (
    <div className={clsx(wrapperStyles.root, 'Video')}>
      <div className={styles.parent}>
        <div className={styles.child}>
          {getPlaceholder(id)}
        </div>
      </div>
    </div>
  )
}
