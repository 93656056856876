import React from 'react'
import CollapsiblePanelBase from '../../CollapsiblePanel/base'
import styles from './../styles.module.scss'
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react'
import LinkWrapper from '../../LinkWrapper'
import { AdditionalPolicyItemType } from '../types'

const Policy: React.FC<{ policy: AdditionalPolicyItemType }> = ({ policy }) => {
  const policyIcon = policy.fields?.iconOverride,
    policyTitle = policy.fields?.titleOverride.value,
    policyOverviewInfo = policy?.fields?.bodyOverride,
    policyLinkHref = policy?.fields?.policyLinkHref?.value,
    policyLinkText = policy?.fields?.policyLinkText?.value,
    policyLinkTitle = policy?.fields?.policyLinkTitle?.value

  return (
    <CollapsiblePanelBase
      styleOverrides={{
        contain: styles.policyAccordionWrap,
        body: styles.policyAccordionDetailsWrap,
        accordionItemTitle: styles.accordionItemTitle,
      }}
      toggle={
        <div className={styles.policyAccrdSummaryWrap}>
          <div className={styles.policyIcon}>
            <Image field={policyIcon} />
          </div>
          <h3 className={styles.policyTitle}>{policyTitle}</h3>
        </div>
      }
    >
      <div className={styles.policyDetailsSectionWrap}>
        <div className={styles.policyOverviewInfo}>
          <RichText field={policyOverviewInfo} />
        </div>
        <div className={styles.viewMoreInfoWrap}>
          <LinkWrapper
            href={policyLinkHref}
            text={policyLinkText}
            title={policyLinkTitle}
          />
        </div>
      </div>
    </CollapsiblePanelBase>
  )
}

export default Policy
