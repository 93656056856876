import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { LinkWrapperWithChildItems } from '../../components/LinkWrapper'

type LinkedPageProps = {
  href: string
  linkTitle: string
  title: JSX.Element
}

export function LinkedPage({ href, linkTitle, title }: LinkedPageProps) {
  if (!href || !title) return null
  const titleAttribute = linkTitle ? { title: linkTitle } : {}
  return (
    <span className={clsx(styles.crumb, styles.linkedPageCrumb)}>
      <LinkWrapperWithChildItems className={styles.breadcrumbLink} href={href} {...titleAttribute}>
        {title}
      </LinkWrapperWithChildItems>
    </span>
  )
}
