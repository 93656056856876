import * as React from 'react'
import * as CMS from '../../types/SitecoreAdapter'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import Helmet from 'react-helmet'

export const OgDescriptionMeta = ({ children }: { children: string }) => {
  return (
    <Helmet>
      <meta property="og:description" content={children} />
    </Helmet>
  )
}

type OgDescriptionAdapterProps = CMS.RouteFields<{
  ogdescription: CMS.TextField
  description: CMS.TextField
}>

export const OgDescriptionAdapter = ({
  sitecoreContext: {
    route: {
      fields: { ogdescription, description },
    },
  },
}: OgDescriptionAdapterProps) => {
  // try for a twitter description, if empty drop to description
  // if that's empty, it'll render meta tag empty
  const desc = ogdescription.value ? ogdescription.value : description.value
  return <OgDescriptionMeta>{desc}</OgDescriptionMeta>
}

export const OgDescriptionWired = withSitecoreContext()(OgDescriptionAdapter)
