import React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { Item, LinkField, TextField } from '../../types/SitecoreAdapter'
import Breadcrumb from './Breadcrumb'
import { LinkedPage } from './LinkedPage'
import { CurrentPage } from './CurrentPage'

export type BreadcrumbLink = Item<{
  link: LinkField
  title: TextField
  linkTitle: TextField
}>

export type BreadcrumbAdapterProps = {
  breadcrumbs: BreadcrumbLink[]
  isSticky?: boolean
  showWrap?: boolean
  innerClassName?: string
  className?: string
  paddedLeft?: boolean
  showCurrentPageCrumb?: boolean
}

const LinkedPageAdapter = (link: BreadcrumbLink) => {
  const { href } = link.fields.link.value
  const title = <Text field={link.fields.title} />
  const linkTitle = link.fields.linkTitle && link.fields.linkTitle.value
  return <LinkedPage href={href} linkTitle={linkTitle} title={title} key={`link-${href}`} />
}

const CurrentPageAdapter = (link: BreadcrumbLink) => (
  <CurrentPage title={<Text field={link.fields.title} />} key="current" />
)

const BreadcrumbList = ({
  breadcrumbs,
  isSticky = true,
  showWrap = true,
  innerClassName,
  paddedLeft = false,
  showCurrentPageCrumb = true,
  className,
}: BreadcrumbAdapterProps) => {
  // if there are no breadcrumbs, don't render
  if (breadcrumbs.length === 0) {
    return null
  }

  // all but the last element
  const links = breadcrumbs.slice(0, -1)

  // last element
  const currentPage = breadcrumbs[breadcrumbs.length - 1]

  // React element for current page
  const currentPageElement = CurrentPageAdapter(currentPage)

  // React elements for links
  const linkedPageElements = links.map(LinkedPageAdapter)

  // breadcrumb list in order
  const breadcrumbListElements = showCurrentPageCrumb
    ? [...linkedPageElements, currentPageElement]
    : [...linkedPageElements]

  return (
    <Breadcrumb
      isSticky={isSticky}
      showWrap={showWrap}
      innerClassName={innerClassName}
      className={className}
      paddedLeft={paddedLeft}
    >
      {breadcrumbListElements}
    </Breadcrumb>
  )
}

export default BreadcrumbList
