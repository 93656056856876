import * as React from 'react'
import { NavLink } from 'react-router-dom'

import clsx from 'clsx'
import { moduleClassNames } from '../css-utils'
import { LinkWrapperWithChildItems } from '../../../components/LinkWrapper'

import styles from './styles.module.scss'

type MenuOptionProps = {
  href: string
  theme: string
  label: React.ReactNode
  title?: string
  isOpen: boolean
  hasChildren: boolean
  noFollow: boolean
  internal: boolean
}

// props
//   - internal - this means the link is in Sitecore and can be loaded via React Router without a full page refresh
function MenuOption({
  href,
  theme,
  label,
  title,
  isOpen,
  hasChildren,
  noFollow,
  internal,
}: MenuOptionProps) {
  const noFollowProps = noFollow ? { rel: 'nofollow' } : {}

  const openableTabSyle = isOpen && hasChildren && styles.tab
  const hasChildrenTabStyle = hasChildren && styles.tabArrow
  const differTabAndLogin =
    !hasChildren && theme !== 'login' && styles.notChildTab

  const cssClasses = clsx(
    styles.base,
    moduleClassNames(theme, styles),
    hasChildrenTabStyle,
    openableTabSyle,
    differTabAndLogin
  )

  if (internal) {
    return (
      <NavLink
        to={href}
        title={title}
        className={({ isActive }) => clsx(styles.cssClasses, isActive && styles.active)}
        data-tag="meganav"
        {...noFollowProps}
      >
        {label}
      </NavLink>
    )
  }

  return (
    <LinkWrapperWithChildItems
      href={href}
      title={title}
      className={cssClasses}
      data-tag="meganav"
      {...noFollowProps}
    >
      {label}
    </LinkWrapperWithChildItems>
  )
}

export default React.memo(MenuOption)
