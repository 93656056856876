import React, { ReactNode } from 'react'
import CardSection from './CardSection'
import { PolicyCardListProps } from './PolicyCardList'
import Columns from './Columns'

type Props = { columnCount: number; children: Array<ReactNode> } & Omit<
  PolicyCardListProps,
  'threeCol' | 'forceSingleCol'
>

const ColumnSection = ({
  columnCount,
  descriptor,
  escapeText,
  title,
  titleTag,
  children,
}: Props) => (
  <CardSection
    escapeText={escapeText}
    title={title}
    titleTag={titleTag}
    descriptor={descriptor}
    isMobile={columnCount === 1}
  >
    <Columns columnCount={columnCount}>{children}</Columns>
  </CardSection>
)

export default ColumnSection
