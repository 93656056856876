import React, { useContext } from 'react'
import { NavigationContext } from '../../services/context'
import Button from '../../../../components/Button'
import { NavigationSettings } from '../../types'

const getStartQuote = ({ menu }: NavigationSettings) => {
  return (menu && menu.startQuote) || { href: '', title: '' }
}

export default function LegacyButton() {
  const { href, title } = getStartQuote(useContext(NavigationContext))

  return (
    <Button primary href={href}>
      {title}
    </Button>
  )
}
