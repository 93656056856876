import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import TagTopics from '../../page-components/TagTopics'
import BlogContain from '../../components/Blog-Card/Contain'
import styles from './styles.module.scss'

type TagTopicsAdapterProps = {
  sitecoreContext: {
    topicsCovered: any
  }
}

const TagTopicsAdapter = ({
  sitecoreContext: { topicsCovered },
}: TagTopicsAdapterProps) => {
  if (!topicsCovered) return null

  return (
    <div className={styles.tagTopicsWrap}>
      <BlogContain className="TagTopics">
        <TagTopics tags={topicsCovered} />
      </BlogContain>
    </div>
  )
}

export default withSitecoreContext()(TagTopicsAdapter)
