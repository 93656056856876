import React from 'react'
import { usePopoverContext } from '../context'
import {
  FloatingArrow,
  FloatingFocusManager,
  FloatingPortal,
  useMergeRefs,
  useTransitionStyles,
} from '@floating-ui/react'

export const PopoverContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(function PopoverContent({ style, ...props }, propRef) {
  const { context: floatingContext, ...context } = usePopoverContext()
  const ref = useMergeRefs([context.refs.setFloating, propRef])
  const { styles: transitionStyles } = useTransitionStyles(floatingContext, {
    initial: {
      opacity: 0,
      transform: 'translateY(-2px)',
    },
  })

  if (!floatingContext.open) return null

  return (
    <FloatingPortal>
      <FloatingFocusManager
        context={floatingContext}
        modal={context.modal}
        initialFocus={-1}
      >
        <div
          ref={ref}
          data-side={floatingContext.placement}
          className={props.className}
          style={{ ...context.floatingStyles, ...style }}
          aria-labelledby={context.labelId}
          aria-describedby={context.descriptionId}
          {...context.getFloatingProps(props)}
        >
          <div style={{ ...transitionStyles }}>{props.children}</div>
          <FloatingArrow
            ref={context.arrowRef}
            height={10}
            width={30}
            context={floatingContext}
            fill="#fff"
            stroke="#d9d8ea"
            style={{ ...transitionStyles }}
            strokeWidth={1}
          />
        </div>
      </FloatingFocusManager>
    </FloatingPortal>
  )
})
