import { useState, useMemo } from 'react'
import {
  QuestionType,
  QuestionsAdapterProps,
  SelectedAnswersType,
} from '../types'
import { OptionType } from '../../../../../components/RadioGroup/types'
import { useSitecoreContext } from '../../../../../util/useSitecoreContext'
import { isEmpty, map, toLower } from 'lodash-es'
import { mapQuestionsData, getAns } from './mapper'
import { useNavigate } from 'react-router-dom'
import { PRT_CTA_URL } from '../../../../../constants/policy-recommendation'
import { GetRecQuestionType } from '../../../../../page-types/policy-recommendation/services/policy-lookup/types'
import { PageTypes } from '../../../../../constants/page-type'

export const isOptionNone = (a: OptionType) =>
  toLower(a.label.trim()) === 'none of the above'

export const useRecommendationQuestionnaire = () => {
  /**
   * Constants
   */
  const { sitecoreContext: ctx } = useSitecoreContext()
  const navigate = useNavigate()
  const pageData = (ctx as QuestionsAdapterProps['sitecoreContext']).route
    .fields.policyRecommendationPageSettings
  const [questionNumber, setQuestionNumber] = useState<number>(1)
  const [selectedAnswers, setSelectedAnswers] = useState<SelectedAnswersType>(
    {}
  )
  const questions: QuestionType[] = useMemo(
    () =>
      pageData !== undefined ? mapQuestionsData(pageData.fields.questions) : [],
    [pageData]
  )
  const totalQuestions = questions.length
  const activeQuestion = questions[questionNumber - 1]
  const selectedAnswer = selectedAnswers[questionNumber]
  const actionBtnProps = {
    label: questionNumber === totalQuestions ? 'Get Recommendations' : 'Next',
    onClick: () => goNext(),
  }

  /**
   * Functions
   */
  const goBack = () =>
    questionNumber > 1 && setQuestionNumber((prev) => prev - 1)

  const goNext = () => {
    if (!isEmpty(selectedAnswer)) {
      // Scrolls to top while moving to the next question, only on PRT page
      if (ctx?.route?.templateName === PageTypes.POLICY_RECOMMENDATIONS_PAGE) {
        window.scroll(0, 0)
      }

      if (questionNumber < totalQuestions) setQuestionNumber((prev) => prev + 1)
      else {
        const ques: any = map(
          selectedAnswers,
          (ans: OptionType | OptionType[], qNo: number) =>
            getAns(ans, qNo, questions)
        )
        const qs = map(
          ques,
          (v: GetRecQuestionType) => `${v.que}=${v.ans.toString()}`
        ).join('&') // Generates query string
        navigate(`${PRT_CTA_URL}?${qs}`) // Navigates to PRT page with query string generated from questions data
      }
    }
  }

  const handleAnswerSelection = (
    questionNumber: number,
    answer: OptionType | OptionType[]
  ) => {
    let finalizedAns = answer
    if (activeQuestion.questionType === 'Multiple Response') {
      const prevAns = (selectedAnswer as OptionType[]) || [],
        newAns = (answer as OptionType[]) || [],
        prevAnsHasNone = prevAns.find(isOptionNone),
        newAnsHasNone = newAns.find(isOptionNone)
      if (!prevAnsHasNone && newAnsHasNone) finalizedAns = [newAnsHasNone]
      else {
        if (prevAnsHasNone && newAnsHasNone && newAns.length > 1) {
          finalizedAns = newAns.filter((a) => !isOptionNone(a))
        }
      }
    }
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionNumber]: finalizedAns,
    }))
  }

  return {
    questionNumber,
    totalQuestions,
    selectedAnswer,
    activeQuestion,
    actionBtnProps,
    goBack,
    handleAnswerSelection,
  }
}
