import * as React from 'react'
import * as CMS from '../../types/SitecoreAdapter'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import Helmet from 'react-helmet'

export const TwitterDescriptionMeta = ({ children }: { children: string }) => {
  return (
    <Helmet>
      <meta name="twitter:description" content={children} />
    </Helmet>
  )
}

type TwitterDescriptionAdapterProps = CMS.RouteFields<{
  twitterdescription: CMS.TextField
  description: CMS.TextField
}>

export const TwitterDescriptionAdapter = ({
  sitecoreContext: {
    route: {
      fields: { twitterdescription, description },
    },
  },
}: TwitterDescriptionAdapterProps) => {
  // try for a twitter description, if empty drop to description
  // if that's empty, it'll render meta tag empty
  const desc = twitterdescription.value
    ? twitterdescription.value
    : description.value
  return <TwitterDescriptionMeta>{desc}</TwitterDescriptionMeta>
}

export const TwitterDescriptionWired = withSitecoreContext()(
  TwitterDescriptionAdapter
)
