import React from 'react'

import Media from '../../../Media'

import ContentWrapper from './ContentWrapper'
import Desktop from './Desktop'
import Mobile from './Mobile'

type CarouselRouterProps = {
  content: {
    heading?: React.ReactNode
    subhead?: React.ReactNode
    escapeText?: React.ReactNode
  }
  items: any
}

type PageProps = {
  pageType?: string
}

export default function CarouselRouter({
  content,
  items,
  pageType
}: CarouselRouterProps & PageProps) {
  const pageStyles =
    pageType === 'article' || pageType === 'policy'
      ? 'typeArticle'
      : pageType === 'home'
      ? 'typeHome'
      : 'typeCopy'
  
  return (
    <ContentWrapper content={content} pageStyles={pageStyles}>
      {getChildren(items)}
    </ContentWrapper>
  )
}

function getChildren(
  routerItems: JSX.Element[]
): JSX.Element {
  if (routerItems.length < 6) {
    return <Mobile routerItems={routerItems} />
  }

  return (
    <Media
      mobile={() => <Mobile routerItems={routerItems} />}
      tablet={() => <Desktop routerItems={routerItems} />}
    />
  )
}
