import React from 'react'
import styles from './styles.module.scss'
import AuthorBioPopover from './BioPopover'
import { ImageField, TextField } from '../../../types/SitecoreAdapter'
import { RichTextField } from '@sitecore-jss/sitecore-jss-react'
import { isEmpty } from 'lodash-es'
import { defaultAuthorProfilePic } from '../../blog-author/AuthorProfile/ProfileInfo'

export type Author = {
  displayName: string
  fields: {
    profilePic: ImageField
    authorPageMapping: TextField
    summarySectionHeading?: TextField
    summary: RichTextField
    educationSectionHeading?: TextField
    education: RichTextField
    experienceSectionHeading?: TextField
    experience: RichTextField
    linkedinUrl: TextField
    name: TextField
    shortDescription: TextField
  }
}

type AttributionProps = {
  author: Author
  displayDate: string | null
  contributor?: Author[]
  contributorPreText?: string
}

const Attribution = ({
  author,
  displayDate,
  contributorPreText,
  contributor,
}: AttributionProps) => {
  if (!author || !author?.fields?.profilePic?.value) {
    return <></>
  }

  const profilePic = isEmpty(author?.fields?.profilePic?.value?.src)
    ? defaultAuthorProfilePic
    : author?.fields?.profilePic?.value

  return (
    <div className={styles.attribution}>
      <img
        className={styles.profilePic}
        {...profilePic}
        height={50}
        width={50}
      />
      <div className={styles.byLineContainer}>
        <div>
          By <AuthorBioPopover key="writtenBy" {...author?.['fields']} />
        </div>
        {!isEmpty(contributor) && (
          <div>
            <span className={styles.contributors}>
              <span>{contributorPreText}</span>
              {contributor?.map((_r, _ri) => (
                <span>
                  <AuthorBioPopover
                    key={`reviewedBy-${_r.displayName}`}
                    {..._r?.['fields']}
                  />
                  {_ri < contributor.length - 1 && ','}
                </span>
              ))}
            </span>
          </div>
        )}
        {displayDate && <div>Updated: {displayDate}</div>}
      </div>
    </div>
  )
}

export default Attribution
