import React, { ReactNode } from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'

export type CardListProps = {
  fullWidth?: boolean
  children: ReactNode
  testId?: string
}

function CardList({ fullWidth, testId, children }: CardListProps) {
  return (
    <div
      data-test-id={testId}
      className={clsx(styles.list, fullWidth && styles.fullWidth)}
    >
      {children}
    </div>
  )
}

export default CardList
