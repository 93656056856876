import React from 'react'
import { TextField } from '../../types/SitecoreAdapter'
import CalendlyWidget from './CalendlyWidget'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type CalendlyWidgetProps = {
  popupText: TextField
  calendlyUrl: TextField
}

const CalendlyWidgetAdapter = ({
  popupText,
  calendlyUrl,
}: CalendlyWidgetProps) => {
  return (
    <CalendlyWidget
      popupText={popupText.value}
      calendlyUrl={calendlyUrl.value}
    />
  )
}

export default withErrorBoundary(CalendlyWidgetAdapter, {
  component: 'CalendlyWidget',
})
