import { useState, useCallback, SyntheticEvent } from 'react'

// Entire cards should be clickable but the link should only be wrapped
// around headings for SEO purposes:
// https://hubjira.atlassian.net/browse/ION-101
//
// We used techniques from inclusive components:
// https://inclusive-components.design/cards/
//
// We record an onMouseDown and compare it to an onMouseUp timestamp to
// determine if the user "clicked" or was selecting text
//
// We supress this mousedown/up timestamp operation if the user has 
// clicked the anchor element itself

type EventHandler = (e?: SyntheticEvent) => void
type CardClickHook = [EventHandler, EventHandler, EventHandler]

export function useCardClick(anchorEl: React.RefObject<HTMLAnchorElement>): CardClickHook {
  const [clickStart, setClickStart] = useState(0)
  
  // Record mouse down timestamp
  const onCardMouseDown: EventHandler = () => {
    setClickStart(new Date().getTime())
  }

  // Compare mouse down to mouse timestamp and emulate link click
  const onCardMouseUp: EventHandler = useCallback((e) => {
    const clickEnd = new Date().getTime()
    if (clickEnd - clickStart < 180) {
     if (anchorEl && anchorEl.current) {
        anchorEl.current.click()
      }
    }
  }, [clickStart])

  // prevents duplicate click events
  const onAnchorMouseDown: EventHandler = (e) => {
    e && e.stopPropagation()
  }
  
  return [onCardMouseDown, onCardMouseUp, onAnchorMouseDown]
}