import React, { ReactNode } from 'react'
import { LinkWrapperWithChildItems } from '../../components/LinkWrapper'

import styles from './styles.module.scss'

type Props = {
  image: ReactNode
  heading: ReactNode
  linkText: string
  linkHref: string
  linkDataTag: string
}

const ErrorPage = ({
  image,
  heading,
  linkText,
  linkHref,
  linkDataTag,
}: Props) => {
  return (
    <div className={styles.errorPage}>
      <div className={styles.imageWrapper}>{image}</div>
      <h1 className={styles.heading}>{heading}</h1>
      {linkText && (
        <LinkWrapperWithChildItems
          data-tag={linkDataTag}
          className={styles.gotoLink}
          href={linkHref}
        >
          {linkText}
        </LinkWrapperWithChildItems>
      )}
    </div>
  )
}

export default ErrorPage
