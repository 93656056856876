import React, { FC } from 'react'
import styles from './styles.module.scss'
import { PolicyRecommendationQuestionnaire } from '../../page-components/PolicyRecommendation/components/Questionnaire'
import { BooleanField } from '../../types/SitecoreAdapter'
import clsx from 'clsx'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type PRTQuestionnaireAdapterProps = {
  fields: {
    showBorders: BooleanField
  }
}

const PRTQuestionnaire: FC<PRTQuestionnaireAdapterProps> = ({
  fields: { showBorders },
}) => {
  return (
    <div
      data-test-id="PolicyRecommendationQuestionnaire"
      className="block contain"
    >
      <div
        className={clsx(
          styles.questionnaireWrap,
          showBorders.value && styles.showBorders
        )}
      >
        <PolicyRecommendationQuestionnaire />
      </div>
    </div>
  )
}

export default withErrorBoundary(PRTQuestionnaire, {
  component: 'PolicyRecommendationQuestionnaire',
})
