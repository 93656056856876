import React, { useContext } from 'react'
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react'

import RouterItem from './components/RouterItem'
import CarouselRouter from './components/CarouselRouter'
import GridRouter from './components/GridRouter'

import {
  UniversalRouterAdapterProps,
  RouterItemAdapterProps,
  UniversalRouterBaseProps,
} from './types'

import Media, { CurrentBreakpoint } from '../Media'
import PageTypography from '../PageTypography'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

const getRouterItems = (
  routerItems: RouterItemAdapterProps[],
  styleLayout: string
) => {
  return routerItems.map(({ image, text, href, title }, i) => {
    const imageEl = (
      <img
        src={image.src}
        alt={image.alt}
        width="115"
        height="76"
        loading="lazy"
      />
    )

    return (
      <RouterItem
        key={i}
        image={imageEl}
        text={text.jss.value}
        href={href.jss.value}
        title={title.jss.value}
        styleClassName={styleLayout}
      />
    )
  })
}

const process = ({
  heading,
  subhead,
  escapeText,
  exploreMoreButton,
  exploreMoreType,
  desktopLayout,
  mobileLayout,
  routerItems,
}: UniversalRouterBaseProps) => {
  const currentBreakpoint = useContext(CurrentBreakpoint)
  const styleLayout =
    currentBreakpoint === 'mobile' || currentBreakpoint === 'tablet'
      ? mobileLayout.jss.value
      : desktopLayout.jss.value

  return {
    content: {
      heading: <Text field={heading.jss} tag="h2" />,
      subhead: <Text field={subhead.jss} />,
      escapeText: <RichText field={escapeText.jss} />,
      exploreMore: exploreMoreButton,
      exploreMoreType:
        (exploreMoreType && exploreMoreType.jss.value) || 'secondary',
    },
    layout: {
      desktopLayout: desktopLayout.jss.value,
      mobileLayout: mobileLayout.jss.value,
    },
    routerItems: getRouterItems(routerItems.targetItems, styleLayout),
  }
}

const UniversalRouterAdapter = ({ fields }: UniversalRouterAdapterProps) => {
  if (!fields.data.datasource) {
    return null
  }

  const { content, routerItems, layout } = process(fields.data.datasource)

  const getUniversalRouter = (layoutStyle: string) => {
    return (
      <PageTypography>
        {(pageType: string) => (
          <>
            {layoutStyle === 'grid' ? (
              <GridRouter
                heading={content.heading}
                subhead={content.subhead}
                items={routerItems}
                exploreMore={content.exploreMore.targetItem}
                exploreMoreType={content.exploreMoreType}
                pageType={pageType}
              />
            ) : (
              <CarouselRouter
                content={content}
                items={routerItems}
                pageType={pageType}
              />
            )}
          </>
        )}
      </PageTypography>
    )
  }

  return (
    <Media
      tablet={() => getUniversalRouter(layout.desktopLayout)}
      mobile={() => getUniversalRouter(layout.mobileLayout)}
    />
  )
}

export default withErrorBoundary(UniversalRouterAdapter, {
  component: 'UniversalRouter',
})
