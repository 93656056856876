import React, { ReactNode, useContext } from 'react'
import Button from '../../../../../components/Button'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { ComponentConsumerProps } from '@sitecore-jss/sitecore-jss-react/types/enhancers/withSitecoreContext'
import {
  Item,
  RouteFields,
  TextField,
} from '../../../../../types/SitecoreAdapter'

export type QuoteButtonProps = {
  onClick?: (event: any) => void
  disabled?: boolean
  loading?: boolean
} & RouteFields<{ ctaStyle: Item<{ ctaClassName: TextField }> }>

export interface OptionalChildrenProp {
  children?: ReactNode
}

const QuoteButton: React.FC<
  QuoteButtonProps & OptionalChildrenProp & ComponentConsumerProps
> = ({ onClick, disabled, loading, children, sitecoreContext }) => {
  const ctaClassName =
    sitecoreContext?.route?.fields?.ctaStyle &&
    sitecoreContext?.route?.fields?.ctaStyle?.fields?.ctaClassName &&
    sitecoreContext?.route?.fields?.ctaStyle?.fields?.ctaClassName.value

  const overrideClassName =
    ctaClassName === 'cta-green' ? styles.greenButtonOverrides : ''

  return (
    <Button
      className={clsx(styles.button, styles[ctaClassName], overrideClassName)}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      testId="QuoteButton"
      size="xlarge"
    >
      {children}
    </Button>
  )
}

export default withSitecoreContext()(QuoteButton)
