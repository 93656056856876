import React from 'react'
import styles from './styles.module.scss'

export type IframeProps = {
  id: string
  src: string
  allowFullscreen?: boolean
  lazyLoad: boolean
}

const YoutubeIframe = ({ id, src, allowFullscreen = true, lazyLoad }: IframeProps) => {
  return (
    <div className={styles.parent} data-nosnippet>
      <iframe
        id={id}
        src={src}
        className={styles.child}
        frameBorder={0}
        allowFullScreen={allowFullscreen}
        loading={lazyLoad ? "lazy" : "eager"}
      ></iframe>
    </div>
  )
}

export default React.memo(YoutubeIframe)
