import React from 'react'
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react'
import {
  Item,
  BooleanField,
  TextField,
  ImageField,
  RouteFields,
} from '../../types/SitecoreAdapter'
import BlogHero from './BlogHero'
import { getResponsiveHeroImage } from '../../components/Responsive-Image/HeroImageAdapter'
import { ResponsiveImageProps } from '../../components/Responsive-Image'

type BlogHeroAdapterInnerProps = {
  backgroundImage: ImageField
  heading: TextField
  descriptor: TextField
  isDark: BooleanField
} & ResponsiveImageProps

type BlogHeroAdapterProps = RouteFields<{
  hero: Item<BlogHeroAdapterInnerProps>
  tpWidgetGlobalSettings: {
    fields: {
      enableTrustPilotHeroWidget: BooleanField
    }
  }
}>

const BlogHeroAdapter = ({
  sitecoreContext: {
    route: {
      fields: { hero, tpWidgetGlobalSettings },
    },
  },
}: BlogHeroAdapterProps) => {
  if (!hero || !hero.fields) return null

  const {
    backgroundImage,
    mobileImage,
    tabletImage,
    laptopImage,
    desktopImage,
    heading,
    descriptor,
    isDark,
  } = hero.fields

  const { enableTrustPilotHeroWidget } = tpWidgetGlobalSettings.fields

  const responsiveImage = getResponsiveHeroImage({
    mobileImage,
    tabletImage,
    laptopImage,
    desktopImage,
    backgroundImage,
  })

  return (
    <BlogHero
      showTrustPilotWidget={false}
      title={<Text field={heading} tag="h1" />}
      subtitle={descriptor && <Text field={descriptor} tag="div" />}
      backgroundImage={responsiveImage}
      isDark={isDark.value}
    />
  )
}

export default withSitecoreContext()(BlogHeroAdapter)
