export enum PageTypes {
  HOME_PAGE = 'Home-Page-Type',
  ABOUT_PAGE = 'About-Page-Type',
  POLICY_PAGE = 'Policy-Page-Type',
  ARTICLE_PAGE = 'Article-Page-Type',
  MAJOR_PAGE = 'Major-Page-Type',
  MINOR_PAGE = 'Minor-Page-Type',
  LEGAL_PAGE = 'Legal-Page-Type',
  BLOG_PAGE = 'Blog-Page-Type',
  BLOG_HOME_PAGE = 'Blog-Home-Page-Type',
  BLOG_AUTHOR_PAGE = 'Author-Page-Type',
  CATEGORY_LANDING_PAGE = 'Category-Landing-Page-Type',
  TAG_RESUTLS_PAGE = 'Tag-Results-Page-Type',
  TEST_PAGE = 'Test-Page-Type',
  ERROR_PAGE = 'Error-Page-Type',
  PARTNER_PAGE = 'Partner-Page-Type',
  SEARCH_PAGE = 'Search-Page-Type',
  POLICY_RECOMMENDATIONS_PAGE = 'Policy-Recommendations-Page-Type',
}
