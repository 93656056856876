import React, { FC } from 'react'
import BlogCard from '../Blog-Card'
import BlogContain from '../Blog-Card/Contain'
import BlogCardList from '../Blog-Card/List'
import { LinkWrapperWithChildItems } from '../LinkWrapper'
import Media from '../Media'

import styles from './styles.module.scss'
import { AuthorSetContentFieldType } from './types'
import { addQueryParamsToUrl } from "../../util/imageUrlUtil"

const AuthorSetContentBase: FC<AuthorSetContentFieldType> = ({
  heading,
  cards,
  seeMoreLink,
  seeMoreText,
}) => {
  const desktopSeeMore = seeMoreLink && seeMoreText && (
    <Media
      mobile={false}
      tablet={() => (
        <LinkWrapperWithChildItems
          href={seeMoreLink}
          className={styles.moreLink}
        >
          {seeMoreText}
        </LinkWrapperWithChildItems>
      )}
    />
  )

  const mobileSeeMore = seeMoreLink && seeMoreText && (
    <Media
      mobile={() => (
        <div className={styles.linkPosition}>
          <LinkWrapperWithChildItems
            href={seeMoreLink}
            className={styles.moreLink}
          >
            {seeMoreText}
          </LinkWrapperWithChildItems>
        </div>
      )}
      tablet={false}
      laptop={false}
      desktop={false}
      wide={false}
    />
  )

  const headingEl = heading && <h2 className={styles.title}>{heading}</h2>

  return (
    <BlogContain testId="AuthorSetContent" block className={'AuthorSetContent'}>
      <div className={styles.headingWrap}>
        {headingEl}
        {desktopSeeMore}
      </div>
      <BlogCardList testId="AuthorSetContent-Cards">
        {cards.map(card => (
          <BlogCard
            key={card.title}
            image={card.image && addQueryParamsToUrl(card.image, `mh=190`)}
            imageAlt={card.imageAlt}
            imageWidth={390}
            imageHeight={190}
            title={card.title}
            href={card.href}
            tag={card.tag}
            feature={false}
            horizontal={false}
          >
            {card.description}
          </BlogCard>
        ))}
      </BlogCardList>
      {mobileSeeMore}
    </BlogContain>
  )
}

export default AuthorSetContentBase
