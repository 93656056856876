import React from 'react'
import { CrossSellPolicyFields } from '../types'
import AdditionalPolicy from './Policy'
import styles from './../styles.module.scss'

interface AdditionalPoliciesProps {
  relatedPolicies: CrossSellPolicyFields['relatedPolicies']
}

const MAX_ALLOWED_POLICIES = 7

const AdditionalPolicies: React.FC<AdditionalPoliciesProps> = ({
  relatedPolicies,
}) => {
  return (
    <div className={styles.additionalPoliciesWrap}>
      {relatedPolicies?.slice(0, MAX_ALLOWED_POLICIES)?.map((policy, i) => (
        <AdditionalPolicy policy={policy} key={`policy-${i}`} />
      ))}
    </div>
  )
}

export default AdditionalPolicies
