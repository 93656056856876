import React, { Suspense } from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'

import Nav from '../../page-components/Navigation'
import Banner from '../../components/Banner'
import TestMetadata from '../../components/Test-Metadata'
import BlogHero from '../../page-components/BlogHero'
import BlogContain from '../../components/Blog-Card/Contain'
import BlogBottomCTA from '../../page-components/BlogBottomCTA'
import ScrollToTopOnMount from '../../util/ScrollToTopOnMount'
import PlaceholderFactory from '../../page-components/PlaceholderFactory'
import TagTopics from './TagTopicsAdapter'

import styles from './styles.module.scss'

const CategoryLandingPage = () => {
  const body = (
    <div>
      <PlaceholderFactory name="Category-Landing-Placeholder" />
    </div>
  )

  return (
    <>
      <Nav />
      <Banner />
      <Suspense>
        <div className="copy">
          <TestMetadata pageType="CategoryLanding" appStartType="Typeahead" />

          <ScrollToTopOnMount />

          <BlogHero />
          <TagTopics />
          {body}
          <div className={styles.bottomCTA}>
            <BlogContain>
              <BlogBottomCTA />
            </BlogContain>
          </div>
        </div>
      </Suspense>
    </>
  )
}

export default withSitecoreContext()(CategoryLandingPage)
