import React, { createContext, ReactNode } from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import getBrandCodeFromRoute, { RouteBrandCode } from './getBrandCode'
import getBusUnitIdFromRoute, { RouteBusUnit } from './getBusUnitId'
import getEnvironmentFromConfig from './getEnvironmentFromConfig'
import { RouteFields } from '../../types/SitecoreAdapter'

const DEFAULT_BRANDCODE = 'ION'
const DEFAULT_BUSUNITID = '2'

export const EnvironmentContext = createContext(
  getEnvironmentFromConfig(DEFAULT_BRANDCODE, DEFAULT_BUSUNITID)
)

type SitecoreAwareEnvironmentContextProps = {
  children?: ReactNode
} & RouteFields<RouteBrandCode['fields'] & RouteBusUnit['fields']>

function SitecoreAwareEnvironmentContext({
  children,
  sitecoreContext: { route },
}: SitecoreAwareEnvironmentContextProps) {
  const isPartnerPage = route.templateName === 'Partner-Page-Type'

  const brandCode = isPartnerPage
    ? getBrandCodeFromRoute(route)
    : DEFAULT_BRANDCODE
  const busUnitId = isPartnerPage
    ? getBusUnitIdFromRoute(route)
    : DEFAULT_BUSUNITID

  const environment = getEnvironmentFromConfig(brandCode, busUnitId)

  return (
    <EnvironmentContext.Provider value={environment}>
      {children}
    </EnvironmentContext.Provider>
  )
}

export const SitecoreAwareEnvironmentContextComponent = withSitecoreContext()(
  SitecoreAwareEnvironmentContext
)
