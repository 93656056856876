import React, { Suspense } from 'react'
import Nav from '../../page-components/Navigation'
import Banner from '../../components/Banner'
import { PolicyFinderPageProps } from './types'
import PolicyRecommendationProvider from './context'
import GetRecommendation from './GetRecommendation'
import { ViewPolicyInfoModalProvider } from './context/ViewPolicyInfo'
import PolicyInfoModal from './components/RecommendedPolicies/components/PolicyListSection/ViewMoreInfo/Modal'
import { useLocation } from 'react-router-dom'
import {
  getPolicyRecommendations,
  getQueAnsFromQS,
} from './services/policy-lookup'

const PolicyFinder = ({ route }: PolicyFinderPageProps) => {
  const { search } = useLocation()
  const ques = getQueAnsFromQS(search) // Parses questons & answers' data from URL query params
  const { recommendedPolicies = [], additionalPolicies = [] } =
    getPolicyRecommendations(ques)

  return (
    <>
      <Nav />
      <Banner />
      <Suspense>
        <div className="copy-article">
          <ViewPolicyInfoModalProvider>
            <PolicyRecommendationProvider
              recommendedPoliciesVal={{
                main: recommendedPolicies,
                additional: additionalPolicies,
              }}
              selectedPoliciesVal={recommendedPolicies.map((r) => r.policy)}
            >
              <GetRecommendation route={route} />
              <PolicyInfoModal />
            </PolicyRecommendationProvider>
          </ViewPolicyInfoModalProvider>
        </div>
      </Suspense>
    </>
  )
}

export default PolicyFinder
