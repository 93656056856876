import React from 'react';
import { flatten, every, trim } from 'lodash-es';

type Pair<T> = [T, T];

type SelectionRange = Pair<number>;

// Disjoint ranges don't overlap
// Valid [1,3] [5,10]
// Invalid [1,3] [2,6] -- overlap between the ranges
export function isDisjointRange([[Astart, Aend], [Bstart, Bend]]: [
  SelectionRange,
  SelectionRange
]): boolean {
  const isBefore = Aend < Bstart;
  const isAfter = Astart > Bend;
  return isBefore || isAfter;
}

// given an array, pair off the first value of the array with the rest of the values of the array
// given [A, B, C, D] => [[A, B], [A,C], [A, D]]
export function pairFirst<T>(input: T[]): Pair<T>[] {
  if (input.length < 2) {
    return [];
  }

  const pairs: Pair<T>[] = [];
  const head = input[0];

  for (let i = 1; i < input.length; i++) {
    pairs.push([head, input[i]]);
  }

  return pairs;
}

// Pairs of each mathematical combination of values in an array
// [star, kite, car] => [[star, kite], [star, car], [kite, car]]
export function pairCombinations<T>(input: T[]): Pair<T>[] {
  const pairs = input.reduce<Pair<T>[][]>((acc, _, index, list) => {
    const slice: T[] = list.slice(index);
    const fst: Pair<T>[] = pairFirst(slice);
    acc.push(fst);
    return acc;
  }, []);

  return flatten(pairs);
}

export function sanitizeRegexInput(term: string): string {
  return term.replace(/[#-.]|[[-^]|[?|{}]/g, '');
}

export function searchForTerm(term: string): RegExp {
  return new RegExp(`\\b${sanitizeRegexInput(term)}`, 'ig');
}

export function rangesFromRegexSearch(searcher: RegExp, text: string): SelectionRange[] {
  const results: SelectionRange[] = [];
  let match;
  while ((match = searcher.exec(text)) != null) {
    const startIndex = match.index;
    const endIndex = match.index + match[0].length;
    results.push([startIndex, endIndex]);
  }

  return results;
}

export function isValidSearchTerm(term: string): boolean {
  return trim(term).length > 0;
}

export function getSearchTerms(input: string): string[] {
  return input.split(' ').filter(isValidSearchTerm);
}

export function oneOf<T>(valid: T[]) {
  return function (candidate: T) {
    return valid.indexOf(candidate) > -1;
  };
}

export function rangesAreDisjoint(ranges: SelectionRange[]): boolean {
  return every(pairCombinations(ranges).map(isDisjointRange));
}

export function getLabelHTML(input: string, label: string): string {
  const ranges = flatten(
    getSearchTerms(input).map((term) => {
      const searcher = searchForTerm(term);
      return rangesFromRegexSearch(searcher, label);
    })
  );

  if (rangesAreDisjoint(ranges)) {
    const output: string[] = [];

    const isStart = oneOf<number>(ranges.map(([start]) => start));
    const isEnd = oneOf<number>(ranges.map(([_, end]) => end));

    for (let i = 0; i < label.length; i++) {
      if (isStart(i)) {
        output.push('<strong>');
        output.push(label[i]);
      } else if (isEnd(i)) {
        output.push('</strong>');
        output.push(label[i]);
      } else {
        output.push(label[i]);
      }
    }

    return output.join('');
  }

  return label;
}

export function formatOptionLabel(
  { label }: { label: string },
  { inputValue }: { inputValue: string }
) {
  return <div dangerouslySetInnerHTML={{ __html: getLabelHTML(inputValue, label) }} />;
}
