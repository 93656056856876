import React from 'react'
import { Text, Link } from '@sitecore-jss/sitecore-jss-react'
import styles from './styles.module.scss'
import BlogCard from '../Blog-Card'
import { PostsByTagProps } from './types'
import { addQueryParamsToUrl } from "../../util/imageUrlUtil"

const PostsByTag = ({ seeMore, title, blogCardData }: PostsByTagProps) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.display}>
        <div className={styles.header}>
          <span className={styles.head}>
            <Text field={{ value: title }} tag="h2" />
          </span>
          <span className={styles.seemore}>
            <Link field={seeMore} />
          </span>
        </div>
        <div className={styles.grid}>
          {blogCardData.map(card => (
            <BlogCard
              key={card.title}
              image={card.image && addQueryParamsToUrl(card.image, `mh=190`)}
              imageAlt={card.imageAlt}
              imageWidth={390}
              imageHeight={190}
              title={card.title}
              href={card.href}
              tag={card.tag}
            >
              {card.body}
            </BlogCard>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PostsByTag
