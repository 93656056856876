import React, { FC } from 'react'
import SearchAppStartContext from './experiences/search-app-start/context'
import SearchAppStart from './experiences/search-app-start/embeds/cta'

type Props = { hasPolicyRecCTA?: boolean; appStartKey?: string }

const CTAAppStartSwitch: FC<Props> = ({
  hasPolicyRecCTA,
  appStartKey = 'CTAAppStartSwitch',
}) => {
  return (
    <SearchAppStartContext hasPolicyRecCTA={hasPolicyRecCTA}>
      <SearchAppStart appStartKey={appStartKey} />
    </SearchAppStartContext>
  )
}

export default CTAAppStartSwitch
