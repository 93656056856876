import clsx from 'clsx'
import styles from './styles.module.scss'
import React from 'react'
import { CrossSellPolicyFields } from './types'
import HeadingBanner from './Banner'
import AdditionalPolicies from './AdditionalPolicies'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type Props = {
  fields: CrossSellPolicyFields
}

const CrossSellPolicies: React.FC<Props> = ({ fields }) => {
  return (
    <div className={clsx(styles.crossSellPoliciesWrap, 'block', 'contain')}>
      <HeadingBanner {...fields} />
      <AdditionalPolicies relatedPolicies={fields.relatedPolicies} />
    </div>
  )
}

export default withErrorBoundary(CrossSellPolicies, {
  component: 'Cross-Sell-Policies',
})
