import React, { Suspense } from 'react'
import Nav from '../../page-components/Navigation'
import Banner from '../../components/Banner'
import TestMetadata from '../../components/Test-Metadata'
import ScrollToTopOnMount from '../../util/ScrollToTopOnMount'
import RecentPosts from '../../components/RecentPosts'
import AuthorProfile from './AuthorProfile'
import {
  SitecoreContextValue,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'
import { Author } from '../blog/Attribution'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { BlogCardProps } from '../../components/Blog-Card'
import { isEmpty } from 'lodash-es'
import Breadcrumb from '../../page-components/Breadcrumb/BreadcrumbList'
import { getSmallBusinessInsuranceBreadcrumb } from '../../util/getSBIBreadcrumb'

type BlogAuthorPageProps = {
  sitecoreContext: SitecoreContextValue & {
    recentArticlesByAuthor: Array<
      { description?: string } & Omit<BlogCardProps, 'children'>
    >
    route: {
      fields: { author: Author }
    }
  }
}

const BlogAuthorPage = ({
  sitecoreContext: {
    recentArticlesByAuthor,
    route: {
      fields: { author },
    },
  },
}: BlogAuthorPageProps) => {
  
  const breadcrumbs = getSmallBusinessInsuranceBreadcrumb(author.fields.authorPageMapping.value)
  
  return (
    <>
      <Nav />
      <Banner />
      <Suspense>
        <div className={clsx(styles.blogAuthorPage, 'copy')}>
          <TestMetadata pageType="BlogAuthor" appStartType="Typeahead" />

          <Breadcrumb
            breadcrumbs={breadcrumbs}
            showCurrentPageCrumb={false}
          />

          <ScrollToTopOnMount />

          <AuthorProfile author={author} />

          {!isEmpty(recentArticlesByAuthor) && (
            <RecentPosts
              fields={{
                title: `Latest from ${author?.fields?.name?.value}`,
                cards: [...recentArticlesByAuthor],
              }}
              styleOverride={{ titleHead: styles.latestPostsTitle }}
              hideCardDescription={true}
            />
          )}
        </div>
      </Suspense>
    </>
  )
}

export default withSitecoreContext()(BlogAuthorPage)
