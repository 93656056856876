import * as React from 'react'
import * as CMS from '../../types/SitecoreAdapter'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import Helmet from 'react-helmet'

export const RobotsMeta = ({ index }: { index: boolean }) => {
  const policy = index ? 'index, follow' : 'noindex, nofollow'
  return (
    <Helmet>
      <meta name="robots" content={policy} />
    </Helmet>
  )
}

type RobotsAdapterProps = CMS.RouteFields<{
  robots: CMS.BooleanField
}>

export const RobotsAdapter = ({
  sitecoreContext: {
    route: {
      fields: { robots },
    },
  },
}: RobotsAdapterProps) => {
  return <RobotsMeta index={robots.value} />
}

export const RobotsWired = withSitecoreContext()(RobotsAdapter)
