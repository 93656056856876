import { Item, TextField } from '../../../types/SitecoreAdapter'

export type RelatedPostsFields = Item<{
  text: TextField
  href: TextField
}>

type Fields = {
  text: string
  href: string
}

function unwrapFields({ fields: { text, href } }: RelatedPostsFields) {
  return {
    text: text.value,
    href: href.value,
  }
}

function isValidPost({ text, href }: Fields) {
  return text !== '' && href !== ''
}

export function getRelatedPosts(posts: any) {
  return posts.map(unwrapFields).filter(isValidPost)
}
