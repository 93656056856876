import React from 'react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import * as SC from '../../types/SitecoreAdapter'

import MultiColumnBase, { WidthOption } from './base'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type MulticolumnFields = {
  useCssColumns: SC.BooleanField
  centerLeftColumnContent: SC.BooleanField
  centerRightColumnContent: SC.BooleanField
  width: { value: WidthOption }
  mergeColumnsOnMobile: SC.BooleanField
}

type MulticolumnProps = {
  fields?: MulticolumnFields
  rendering: any
}

const MultiColumn = (props: MulticolumnProps) => {
  return (
    <MultiColumnBase
      left={<Placeholder name="left-column" rendering={props.rendering} />}
      right={<Placeholder name="right-column" rendering={props.rendering} />}
      useCssColumns={props.fields && props.fields.useCssColumns.value}
      centerLeftColumnContent={
        props.fields && props.fields.centerLeftColumnContent.value
      }
      centerRightColumnContent={
        props.fields && props.fields.centerRightColumnContent.value
      }
      width={props.fields && props.fields.width.value}
      mergeColumnsOnMobile={
        (props.fields &&
          props.fields.mergeColumnsOnMobile &&
          props.fields.mergeColumnsOnMobile.value) ||
        false
      }
    />
  )
}

export default withErrorBoundary(MultiColumn, {component: 'MultiColumn'})
