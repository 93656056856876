import React, { lazy } from 'react'

import { ImageWrapperProps } from './ImageTypes'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

const ImageWrapper = ({
  src,
  alt,
  width,
  height,
  title,
  imageStyles,
  testId,
  loading = 'lazy',
}: ImageWrapperProps) => {
  return (
    <img
      className={imageStyles}
      src={src}
      alt={alt}
      width={width}
      height={height}
      title={title}
      data-test-id={testId}
      loading={loading}
    />
  )
}

export default withErrorBoundary(ImageWrapper, { component: 'ImageWrapper' })
