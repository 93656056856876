import React from 'react'
import { PopoverProps } from './types'
import {
  flip,
  offset,
  shift,
  useHover,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  autoUpdate,
  arrow,
  safePolygon,
} from '@floating-ui/react'

export function usePopover({
  initialOpen = false,
  placement = 'bottom',
  modal,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
}: PopoverProps = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen)
  const [labelId, setLabelId] = React.useState<string | undefined>()
  const [descriptionId, setDescriptionId] = React.useState<string | undefined>()
  const arrowRef = React.useRef(null)

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      flip({
        mainAxis: false,
        crossAxis: false,
        fallbackAxisSideDirection: 'end',
        padding: { left: 22.5, right: 21.5 }, // collisionPadding for smaller screen devices
      }),
      shift({
        padding: { left: 22.5, right: 21.5 }, // collisionPadding for smaller screen devices
      }),
      arrow({
        element: arrowRef,
      }), // Middleware function for FloatingArrow component
    ],
  })

  const context = data.context

  const hover = useHover(context, {
    mouseOnly: true,
    handleClose: safePolygon(),
  })
  const click = useClick(context, {
    enabled: controlledOpen == null,
  })
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const interactions = useInteractions([click, dismiss, role, hover])

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      modal,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
      arrowRef,
    }),
    [open, setOpen, interactions, data, modal, labelId, descriptionId, arrowRef]
  )
}
