import React, { ReactNode } from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'

export type BlogContainProps = {
  block?: boolean
  center?: boolean
  className?: string
  children: ReactNode
  testId?: string
}

function BlogContain({
  block,
  center,
  className = '',
  testId = 'BlogContain',
  children,
}: BlogContainProps) {
  const rootClasses = clsx(
    styles.contain,
    block && styles.block,
    center && styles.center,
    className
  )
  return (
    <div data-test-id={testId} className={rootClasses}>
      {children}
    </div>
  )
}

export default BlogContain
