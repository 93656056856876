import React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import clsx from 'clsx'

import styles from './styles.module.scss'
import './styles.scss'

type BlogBottomCTAProps = {
  heading: any
  children: JSX.Element
}

const BlogBottomCTA = ({
  heading,
  children,
}: BlogBottomCTAProps) => {
  return (
    <div
      className={clsx(
        'block',
        styles.base,
        styles.centerAlign,
        'BlogBottomCTABanner'
      )}
      data-test-context="CTABanner"
    >
      <div className={clsx(styles.title, styles.centerAlignTitle)}>
        <Text field={heading} tag="h3" />
      </div>
      {children}
    </div>
  )
}

export default BlogBottomCTA
