export enum EventName {
  InputClick = 'InputClick',
  ButtonClick = 'ButtonClick',
  FirstLetter = 'FirstLetter',
  NoResults = 'NoResults',
  EscapeLink = 'EscapeLink',
  Drop = 'Drop',
  Quote = 'Quote',
  SelectProfession = 'SelectProfession',
  RejectSelection = 'RejectSelection'
}

export type LabeledEvent =
  | EventName.NoResults
  | EventName.Drop
  | EventName.Quote
  | EventName.ButtonClick
  | EventName.SelectProfession
  | EventName.RejectSelection

export type AppStartSearchEvent =
  | Event_InputClick
  | Event_ButtonClick
  | Event_FirstLetter
  | Event_NoResults
  | Event_EscapeLink
  | Event_Quote
  | Event_Drop
  | Event_SelectProfession
  | Event_RejectSelection

type AppStartSearchEventBase = {
  event: 'TrackEvent'
  category: 'AppStartSearch'
  action: EventName
  eventCallback?: () => void
}

type Event_InputClick = AppStartSearchEventBase & {
  action: EventName.InputClick
}

type Event_ButtonClick = AppStartSearchEventBase & {
  action: EventName.ButtonClick
  label: string
}

type Event_FirstLetter = AppStartSearchEventBase & {
  action: EventName.FirstLetter
}

type Event_NoResults = AppStartSearchEventBase & {
  action: EventName.NoResults
  label: string
}

type Event_Quote = AppStartSearchEventBase & {
  action: EventName.Quote
  label: string
}

type Event_EscapeLink = AppStartSearchEventBase & {
  action: EventName.EscapeLink
}

type Event_Drop = AppStartSearchEventBase & {
  action: EventName.Drop
}

type Event_SelectProfession = AppStartSearchEventBase & {
  action: EventName.SelectProfession
  label: string
}

type Event_RejectSelection = AppStartSearchEventBase & {
  action: EventName.RejectSelection
  label: string
}
