import React, { useState } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import ModalCTA from '../Modal-CTA/ModalCTA'

type ModalButtonProps = {
  getQuotesLabel: React.ReactElement
  testId?: string
}

const ModalButton = ({ getQuotesLabel, testId }: ModalButtonProps) => {
  return (
    <div
      className={styles.button}
      // Prevents `Policy-Card` custom click implementation from firing
      // See `Policy-Card/useCardClick.ts`
      onMouseDown={e => e.stopPropagation()}
    >
      <ModalCTA
        buttonClassName={clsx('button', styles.modalButton)}
        wrapClassName={styles.modalButtonWrap}
        alignment={'Left'}
        primary={true}
        size="medium"
        testId={testId}
      >
        {getQuotesLabel}
      </ModalCTA>
    </div>
  )
}

export default React.memo(ModalButton)
