import React from 'react'
import clsx from 'clsx'

import Media from '../Media'
import Desktop from './Desktop'
import Mobile from './Mobile'
import BlogCard from '../Blog-Card'
import BlogContain from '../Blog-Card/Contain'

import styles from './styles.module.scss'

//slick slider for the customer testimonial component
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { FeaturedPostsFields } from './types'
import { addQueryParamsToUrl } from '../../util/imageUrlUtil'

// props
type Props = FeaturedPostsFields & { editing: boolean; pageType: string }
//  featuredPosts - ReactElement[]
const FeaturedPosts = ({ cards, heading, editing, pageType }: Props) => {
  const title = heading && <h2 className={styles.title}>{heading}</h2>

  const imageModString =
    pageType === 'Blog-Home-Page-Type' ||
    pageType === 'Category-Landing-Page-Type'
      ? ``
      : `mh=190`

  const posts = cards.map(card => (
    <BlogCard
      feature
      key={card.title}
      image={card.image && addQueryParamsToUrl(card.image, imageModString)}
      imageAlt={card.imageAlt}
      imageWidth={390}
      imageHeight={190}
      title={card.title}
      href={card.href}
      tag={card.tag}
    >
      {card.description}
    </BlogCard>
  ))

  if (editing === true || posts.length === 1) {
    return (
      <div data-test-id="FeaturedPosts" className={styles.singleCardWrap}>
        <BlogContain center>
          {title}
          {posts}
        </BlogContain>
      </div>
    )
  }

  return (
    <div data-test-id="FeaturedPosts" className={styles.indexWrap}>
      <div className={clsx('contain', styles.titleWrap)}>{title}</div>
      <Media
        mobile={() => <Mobile posts={posts} />}
        desktop={() => <Desktop posts={posts} />}
      />
    </div>
  )
}

export default FeaturedPosts
