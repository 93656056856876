import { TypeaheadReducer } from './reducer'
import { AppSearch_Events, Event } from './events'
import { EventName } from '../analytics/events'
import { AppSearch_States, Status } from './states'
import { getQuoteLabel } from '../analytics/search-app-analytics'

// middlewear that listens to events, rather than state
// to help with accurately triggering analytics
export function analyticsReducer(
  childReducer: TypeaheadReducer,
  searchInput: string | false
) {
  return (
    state: AppSearch_States,
    event: AppSearch_Events
  ): AppSearch_States => {
    if (
      state.status === Status.Searching &&
      event.type === Event.SelectProfession
    ) {
      const label = getQuoteLabel({
        professionLabel: event.selection.label,
        webBusClassId: event.selection.value,
        searchInput: searchInput,
        direct: state.direct || false,
      })
      return {
        ...childReducer(state, event),
        analyticsEvent: { type: EventName.SelectProfession, label: label },
      }
    }

    if (
      state.status === Status.Searching &&
      event.type === Event.RejectSelection &&
      state.selection !== undefined
    ) {
      const label = getQuoteLabel({
        professionLabel: state.selection.label,
        webBusClassId: state.selection.value,
        searchInput: searchInput,
        direct: state.direct || false,
      })
      return {
        ...childReducer(state, event),
        analyticsEvent: { type: EventName.RejectSelection, label: label },
      }
    }

    return { ...childReducer(state, event), analyticsEvent: undefined }
  }
}
