import React from 'react'
import SearchAppStart from '../../quotes/experiences/search-app-start/embeds/cta'
import SearchAppStartContext from '../../quotes/experiences/search-app-start/context'
import styles from './styles.module.scss'
import { TextField } from '../../types/SitecoreAdapter'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type QuoteLandingProps = {
  fields: {
    heading: TextField
    descriptor: TextField
  }
}

function QuoteLanding({ fields }: QuoteLandingProps) {
  const { heading, descriptor } = fields

  return (
    <div
      data-test-id={'QuoteLanding-Wrap'}
      className={styles.container}
      data-test-context="QuoteLanding"
    >
      <h1 className={styles.heading}>{heading.value}</h1>
      <div className={styles.descriptor}>{descriptor.value}</div>
      <div className={styles.ctaRoot}>
        <SearchAppStartContext>
          <SearchAppStart appStartKey="QuoteLandingTypehead" />
        </SearchAppStartContext>
      </div>
    </div>
  )
}

export default withErrorBoundary(QuoteLanding, { component: 'Quote-Landing' })
