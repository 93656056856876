import React from 'react'
import { Image, Link } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import {
  ImageField,
  Item,
  RouteFields,
  TextField,
} from '../../types/SitecoreAdapter'

type SocialSharingProps = {
  className?: string
  size: string
} & RouteFields<{
  canonical: TextField
  pageTitle: TextField
  socialSharing: Item<{
    facebook: ImageField
    twitter: ImageField
    linkedIn: ImageField
    email: ImageField
  }>
}>

const SocialSharing = ({
  className,
  size,
  sitecoreContext: {
    route: { fields },
  },
}: SocialSharingProps) => {
  const { canonical, socialSharing, pageTitle } = fields

  const url = canonical.value

  const { facebook, twitter, linkedIn, email } = socialSharing.fields

  const links = {
    facebook: {
      href: `https://www.facebook.com/dialog/share?app_id=2473951522635875&display=popup&href=${encodeURI(
        url
      )}`,
      title: 'Share page to Facebook',
      target: '_blank',
    },
    twitter: {
      href: `https://twitter.com/intent/tweet?text=${encodeURI(
        pageTitle.value
      )}&url=${encodeURI(url)}&via=Insureon`,
      title: 'Tweet this page',
      target: '_blank',
    },
    linkedIn: {
      href: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(
        url
      )}`,
      title: 'Share on LinkedIn',
      target: '_blank',
    },
    email: {
      href: `mailto:?body=${encodeURI(url)}&subject=${encodeURI(
        pageTitle.value
      )}`,
      title: 'Share page by email',
      target: '_blank',
    },
  }

  return (
    <div className={clsx('SocialSharing', className)}>
      <Link className={styles.link} field={links.facebook}>
        <Image
          className={clsx(styles.socialshare, styles[`socialshare-${size}`])}
          field={facebook}
        />
      </Link>
      <Link className={styles.link} field={links.twitter}>
        <Image
          className={clsx(styles.socialshare, styles[`socialshare-${size}`])}
          field={twitter}
        />
      </Link>
      <Link className={styles.link} field={links.linkedIn}>
        <Image
          className={clsx(styles.socialshare, styles[`socialshare-${size}`])}
          field={linkedIn}
        />
      </Link>
      <Link className={styles.link} field={links.email}>
        <Image
          className={clsx(styles.socialshare, styles[`socialshare-${size}`])}
          field={email}
        />
      </Link>
    </div>
  )
}

export default withSitecoreContext()(SocialSharing)
