import React, { useState } from 'react'
import useSafariBackPageLoad from '../../../util/useSafariPageLoadPersist'
import {
  iPhoneScrollFix,
  iPhoneScrollRestore,
} from '../../../quotes/experiences/search-app-start/iPhoneScrollFix'
import { RecommendedPolicyType } from '../components/RecommendedPolicies/types'
import { FC } from '../../../types/common'

type ViewPolicyInfoState = {
  isVisible: boolean
  data?: Pick<RecommendedPolicyType, 'title' | 'icon' | 'description'>
}

type ViewPolicyInfoContextSettings = ViewPolicyInfoState & {
  showModal: () => void
  hideModal: () => void
  setViewInfo: React.Dispatch<React.SetStateAction<ViewPolicyInfoState>>
}

const initialSettings = {
  isVisible: false,
  showModal: () => {},
  hideModal: () => {},
} as ViewPolicyInfoContextSettings

export const ViewPolicyInfoModalContext = React.createContext(initialSettings)

export const ViewPolicyInfoModalProvider: FC = ({ children }) => {
  const [viewInfo, setViewInfo] = useState<ViewPolicyInfoState>({
    isVisible: false,
  })

  const setState = (d: Partial<ViewPolicyInfoState>) =>
    setViewInfo((p) => ({ ...p, ...d }))

  useSafariBackPageLoad(
    () => setState({ isVisible: false }),
    'Search Modal state'
  )

  const contextObj = {
    ...viewInfo,
    setViewInfo,
    showModal: () => {
      iPhoneScrollFix()
      setState({ isVisible: true })
    },
    hideModal: () => {
      iPhoneScrollRestore()
      setState({ isVisible: false })
    },
  }

  return (
    <ViewPolicyInfoModalContext.Provider value={contextObj}>
      {children}
    </ViewPolicyInfoModalContext.Provider>
  )
}
