import * as React from 'react'
import * as CMS from '../../types/SitecoreAdapter'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import Helmet from 'react-helmet'

export const DescriptionMeta = ({ children }: { children: string }) => {
  return (
    <Helmet>
      <meta name="description" content={children} />
    </Helmet>
  )
}

type DescriptionAdapterProps = CMS.RouteFields<{
  description: CMS.TextField
}>

export const DescriptionAdapter = ({
  sitecoreContext: {
    route: {
      fields: { description },
    },
  },
}: DescriptionAdapterProps) => {
  return description.value ? (
    <DescriptionMeta>{description.value}</DescriptionMeta>
  ) : null
}

export const DescriptionWired = withSitecoreContext()(DescriptionAdapter)
