import React, { FC } from 'react'
import styles from './../styles.module.scss'
import backArrowIcon from './../../../assets/back-arrow-icon.svg'

interface BackButtonProps {
  onClick: () => void
}

const BackButton: FC<BackButtonProps> = ({ onClick }) => {
  return (
    <div
      data-test-id={'prt-question-back-button'}
      className={styles.backButton}
      onClick={onClick}
    >
      <img src={backArrowIcon} alt="Go Back" />
      <span>Back</span>
    </div>
  )
}

export default BackButton
