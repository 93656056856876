export const DEFAULT_VALUES = {
  callCTA: 'Have a question?  Schedule a call.',
  getQuoteCTA: 'Get Quotes',
  startOverCTA: 'Start Over',
  footerDesc: '',
  recommendedSectionTitle: "Given what you told us, here's your recommendation",
  additionalSectionTitle: 'Additional policy options',
}

export const PRT_CTA_URL = '/small-business-insurance/policy-finder'
