import React, { FC, useContext } from 'react'
import styles from './styles.module.scss'
import { RecommendedPolicyType } from '../../../types'
import { ViewPolicyInfoModalContext } from '../../../../../context/ViewPolicyInfo'

export interface ViewMorePolicyInfoProps
  extends Pick<RecommendedPolicyType, 'title' | 'icon' | 'description'> {}

const ViewMorePolicyInfo: FC<ViewMorePolicyInfoProps> = ({
  icon,
  title,
  description,
}) => {
  const { showModal, setViewInfo } = useContext(ViewPolicyInfoModalContext)

  const openModal = () => {
    setViewInfo(p => ({ ...p, data: { icon, title, description } }))
    showModal()
  }

  return (
    <div className={styles.viewMoreInfoWrap}>
      <div>
        <div
          data-test-id={'prt-recommendation-policy-details-view-more'}
          className={styles.viewMoreInfoLink}
          onClick={openModal}
        >
          View more policy info
        </div>
      </div>
    </div>
  )
}

export default ViewMorePolicyInfo
