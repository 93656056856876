import React from 'react'
import Button from '../Button'
import LayoutWrapper, { ILayoutWrapperProps } from './LayoutWrapper'
import { ICMSButtonBase } from './CMSButton'

// CallbackButton uses the same Sitecore fields as the CMSButton,
// without the title/href, but has custom onClick behavior
type CallbackButtonProps = {
  onClick: () => void
} & ICMSButtonBase &
  ILayoutWrapperProps

const CallbackButton = ({
  onClick,
  alignment,
  size,
  primary,
  wrapClassName,
  buttonClassName,
  children,
  testId
}: CallbackButtonProps) => {
  return (
    <LayoutWrapper
      alignment={alignment}
      size={size}
      wrapClassName={wrapClassName}
    >
      <Button
        className={buttonClassName}
        primary={primary}
        secondary={!primary}
        size={size}
        onClick={onClick}
        testId={testId}
      >
        {children}
      </Button>
    </LayoutWrapper>
  )
}

export default React.memo(CallbackButton)
export type { ICMSButtonBase, ILayoutWrapperProps }