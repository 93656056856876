import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { FC } from '../../types/common'

// SearchBot is a utility for serving content to either bots or to customers
// Valid values for show -- bots, customers

// When does content show or not show?
// Bot and show bots -- show content
// Not bot and show bots -- hide content
// Bot and show customers -- hide content
// Not bot and show customers -- show content

type Props = {
  sitecoreContext: { seo: { isCrawler: boolean } }
  show: 'bots' | 'customers'
}

const SearchBot: FC<Props> = ({
  sitecoreContext: {
    seo: { isCrawler },
  },
  children,
  show = 'bots',
}) => {
  const isBot = isCrawler

  if (isBot && show === 'bots') {
    return <>{children}</>
  }

  if (!isBot && show === 'customers') {
    return <>{children}</>
  }

  return null
}

export default withSitecoreContext()(SearchBot)
