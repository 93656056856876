import React from 'react'
import { RichText, Text, Image } from '@sitecore-jss/sitecore-jss-react'

import Media from '../Media'
import styles from './styles.module.scss'
import { TextField } from '../../types/SitecoreAdapter'
import { ImageField } from '@sitecore-jss/sitecore-jss-react/types/components/Image'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

export type ValuePropProps = {
  icon: ImageField
  title: TextField
  body: TextField
}

function ValueProp(props: ValuePropProps, index: number) {
  const { icon, title, body } = props

  return (
    <div className={styles.base} key={index}>
      <Media
        mobile={() => (
          <Image
            field={icon}
            className={styles.img}
            srcSet={[{ w: 32, h: 32 }]}
            height="32"
            width="32"
          />
        )}
        desktop={() => (
          <Image
            field={icon}
            className={styles.img}
            srcSet={[{ w: 48, h: 48 }]}
            height="48"
            width="48"
          />
        )}
      />
      <div className={styles.bodyWrap}>
        <Text field={title} tag="h4" className={styles.heading} />
        <RichText field={body} className={styles.body} />
      </div>
    </div>
  )
}

export default withErrorBoundary(ValueProp, { component: 'ValueProp' })
