import React from 'react'
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'
import Heading from './Heading'
import { Item, TextField } from '../../types/SitecoreAdapter'
import { HeadingClassOverrides, HeadingTagField } from './types'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type HeadingAdapterProps = Item<{
  title: TextField
  mobileTitle?: TextField
  tag: HeadingTagField
  descriptor?: TextField
}> & {
  addStyle?: HeadingClassOverrides
  embed?: boolean
  testId?: string
}

const HeadingAdapter = ({
  fields: { title, mobileTitle, tag, descriptor },
  addStyle = {},
  embed = false,
  testId = 'Heading',
}: HeadingAdapterProps) => {
  const headingTag = tag.fields.tag.value
  const mobileHeading = mobileTitle && mobileTitle.value ? mobileTitle : title
  const body = descriptor ? <RichText field={descriptor} /> : null
  return (
    <Heading
      testId={testId}
      title={<Text field={title} tag={headingTag} />}
      mobileTitle={<Text field={mobileHeading} tag={headingTag} />}
      body={body}
      addStyle={addStyle}
      embed={embed}
      headingTag={headingTag}
    />
  )
}

export default withErrorBoundary(HeadingAdapter, { component: 'Heading' })
