import React from 'react'
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { RouteFields, TextField } from '../../types/SitecoreAdapter'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type LegalDescProps = RouteFields<{
  legalDescriptor: TextField
}>

const LegalDescriptor = ({
  sitecoreContext: {
    route: {
      fields: { legalDescriptor },
    },
  },
}: LegalDescProps) => {
  return (
    <div
      data-test-id={'LegalDescriptor'}
      className={clsx(styles.legaldescriptor, 'contain')}
    >
      <RichText field={legalDescriptor} />
    </div>
  )
}

export default withErrorBoundary(withSitecoreContext()(LegalDescriptor), {
  component: 'LegalDescriptor',
})
